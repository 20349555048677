/* eslint-disable max-len */
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import { unitSelector } from "../../state/scene/atoms"
import {
    Box3,
    Box3Helper,
    Color,
    Group,
    InstancedMesh,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Quaternion,
    Sphere,
    Vector3
} from "three"
import { CameraControls, CAMERA_ACTIONS } from "./CameraControls"
import { usePointerRotation } from "./usePointerRotation"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { sceneAtom, sizeStringAtom, boundingBoxAtom, emptyPositionForNewPartAtom } from "../../state/scene/atoms"
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { showCameraControlsState } from "../../state/scene/atoms"
import { selectedItemID } from "../../state/atoms"
import DimensionLabels from "../../components/main/DesignScreen/scene/DimensionLabels"
import { showDimensions } from "../../components/main/DesignScreen/scene/part/parts/connector/utils/DesignDimensions"
import { undoRedoInProgressSelector } from "../../state/scene/selectors"
import useCamera from "./useCamera"
import { MultiSelectContext } from "../multiselectProvider/MultiSelectProvider"



interface Props {
    cameraControls: React.MutableRefObject<CameraControls | null>;
    partCount: number;
    designId: string | undefined;
    children: React.ReactNode;
}

type CameraValues = {
    getRef: () => React.MutableRefObject<CameraControls | null>,
    getInAutofocusMode: () => boolean,
    setInAutofocusMode: (value: boolean) => void,
    addMeshToSceneBounds: () => void,
}

type SceneBounds = {
    loadedCount: number,
}

export type CameraContextType = CameraValues | undefined

export const cameraContext = createContext<CameraContextType>(undefined)

const CameraProvider: React.FC<Props> = ({ children, cameraControls, partCount, designId, }) => {
    const sceneData = useRecoilValue(sceneAtom)
    const selectedItem = useRecoilValue(selectedItemID)
    const { scene, gl, } = useThree()
    const setSceneAtom = useSetRecoilState(sceneAtom)
    const inAutofocusModeRef = useRef(true)
    const [sizeInfo, setSize,] = useRecoilState(sizeStringAtom)
    const [inAutofocusMode, setInAutofocusModeValue,] = useState(true)
    const pointerRotation = usePointerRotation(cameraControls.current ?? undefined)
    const { removeCameraLock, azimuthRotateSpeed, polarRotateSpeed, } = useLevaControls()
    const [finishedLoading, setFinishedLoading,] = useState("")
    const [, setShowCameraControls,] = useRecoilState(showCameraControlsState)
    const unit = useRecoilValue(unitSelector)
    const undoRedoInProgress = useRecoilValue(undoRedoInProgressSelector)
    const multiSelectContext = useContext(MultiSelectContext)

    const [partsLength, setPartsLength,] = useState(0)

    const getPartsLength = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                const sceneData = snapshot.getLoadable(sceneAtom).contents
                return Object.keys(sceneData.parts).length
            },
        []
    )

    useEffect(() => {
        const length = getPartsLength()
        setPartsLength(length)
    }, [sceneData, getPartsLength,])

    useEffect(() => {
        //console.log(partsLength, "partsLength")
    }, [partsLength,])


    const sceneBounds = useRef<SceneBounds>({
        loadedCount: 0,
    })

    const [boundingBox, setBoundingBox,] = useRecoilState(boundingBoxAtom)
    const [emptyPositionForNewPart, setEmptyPositionForNewPart,] = useRecoilState(emptyPositionForNewPartAtom)
    const box = new Box3()

    const { findEmptyPositionForBox, drawVector3Point, } = useCamera()

    const isInfiniteBox = (box: Box3) => {
        return box.min.toArray().some(v => v === Infinity
            || v === -Infinity)
            || box.max.toArray().some(v => v === Infinity
                || v === -Infinity)
    }

    useEffect(() => {
        if (finishedLoading !== "camera-adjusted") {
            let attemptCount = 0
            const interval = setInterval(() => {
                attemptCount++
                if (partsLength === sceneBounds.current.loadedCount && partsLength > 0) {
                    setFinishedLoading("done")
                    clearInterval(interval)
                } else if (attemptCount >= 5) {
                    setFinishedLoading("done")
                    clearInterval(interval)
                }
            }, 200)

            // Cleanup interval on effect cleanup
            return () => clearInterval(interval)
        }
    }, [sceneBounds.current.loadedCount, finishedLoading, partsLength,])

    const isValidMesh = (mesh: any) => {
        return !(isNaN(mesh.position.x) || isNaN(mesh.position.y) || isNaN(mesh.position.z)
            || isNaN(mesh.quaternion.x) || isNaN(mesh.quaternion.y) || isNaN(mesh.quaternion.z) || isNaN(mesh.quaternion.w)
            || isNaN(mesh.scale.x) || isNaN(mesh.scale.y) || isNaN(mesh.scale.z))
    }
    const runThroughSceneAndMakeGroupFocusCamera = (distance: number, firstLoad: boolean, cameraUpdate = true,) => {
        let group: Group | null = new Group()
        const mat = new MeshBasicMaterial({ color: "red", })
        scene.traverse((child) => {
            if (child.userData && child.userData.type && child.userData.type === "COLLISION_TUBE") {
                const p = new Vector3()
                const r = new Quaternion()
                const msh = child as Mesh
                msh.getWorldPosition(p)
                msh.getWorldQuaternion(r)
                const mesh = new Mesh(msh.geometry, mat)
                mesh.position.set(p.x, p.y, p.z)
                mesh.applyQuaternion(r)
                group?.attach(mesh)
            }
            if (child.userData && child.userData.type && child.userData.type === "InstancedMesh") {
                const instancedMesh = child as InstancedMesh
                const count = instancedMesh.count
                const geometry = instancedMesh.geometry
                const material = instancedMesh.material
                if (!geometry || !material) {
                    console.error("Invalid geometry or material")
                    return
                }
                for (let i = 0; i < count; i++) {
                    const mesh = new Mesh(geometry, material)
                    instancedMesh.getMatrixAt(i, mesh.matrix)
                    mesh.matrix.decompose(mesh.position, mesh.quaternion, mesh.scale)
                    if (isValidMesh(mesh)) {
                        group?.attach(mesh)
                    } else {
                        console.error("NaN detected in mesh properties", mesh)
                    }
                }
            }
        })

        const isValidBox = (box: Box3) => {
            return !box.min.toArray().some(isFinite) && !box.max.toArray().some(isFinite)
        }

        if (box && isValidBox(box)) {
            box.setFromObject(group)
            setBoundingBox((prevState: Record<string, any>) => ({
                ...prevState,
                box: new Box3().copy(box),
            }))
        }
        const createBox3FromDimensions = (width: number, height: number, depth: number) => {
            const halfWidth = width / 2
            const halfHeight = height / 2
            const halfDepth = depth / 2

            return new Box3(
                new Vector3(-halfWidth, -halfHeight, -halfDepth),
                new Vector3(halfWidth, halfHeight, halfDepth)
            )
        }

        let boxToUse = boundingBox.box

        //when the user has not clicked out of any part,
        //boundingbox is not calculated yet and returns w infinty values

        if (boundingBox.box === null || isInfiniteBox(boundingBox.box)) {
            boxToUse = createBox3FromDimensions(0.07, 0.1, 0.07)
        }

        const emptyPosition = findEmptyPositionForBox(scene, createBox3FromDimensions(0.07, 0.1, 0.07), boxToUse, false)
        //console.log(emptyPosition, "emptyPosition")
        setEmptyPositionForNewPart(emptyPosition || new Vector3(0, 0, 0))
        //const helper = new Box3Helper(box, new Color("blue"))
        //scene.add(helper)

        const sphere = new Sphere()
        box.getBoundingSphere(sphere)

        if (cameraUpdate) {
            cameraControls.current?.fitToSphere(sphere, true)
            if (firstLoad) {
                cameraControls.current?.camera.updateProjectionMatrix()
                if (inAutofocusModeRef.current) {
                    setInAutofocusModeValue(false)
                }
            }
            else if (cameraControls.current) {
                cameraControls.current.distance = distance
                cameraControls.current.camera.updateProjectionMatrix()
            }
            group.traverse((child) => {
                let mesh = child as Mesh | null
                if (mesh) {
                    mesh.geometry?.dispose();
                    (mesh.material as MeshStandardMaterial)?.dispose()
                    mesh = null
                }
            });
            (group as Group | null) = null
        }
    }


    useEffect(() => {
        if (partCount === 0) {
            setShowCameraControls(false)
            gl.autoClear = true
        } else {
            setShowCameraControls(true)
        }
    }, [partCount,])
    const prevDesignIdRef = useRef<string | undefined>()


    useEffect(() => {
        if (designId !== prevDesignIdRef.current) {
            setFinishedLoading("loading")
        }
    }, [designId,])

    useEffect(() => {
        //console.log(multiSelectContext?.updateCounter, "update counter camera provider", partsLength)
        const previousDistance = cameraControls.current?.distance ?? 0
        if (selectedItem === null && partsLength > 0) {
            if (designId !== prevDesignIdRef.current && finishedLoading === "done") {
                setTimeout(() => {
                    runThroughSceneAndMakeGroupFocusCamera(previousDistance, true)
                }, 1000)
                setFinishedLoading("camera-adjusted")
            }
            else {
                runThroughSceneAndMakeGroupFocusCamera(previousDistance, false, true)
            }
            showDimensions(box, false, unit, setSize)
            setBoundingBox((prevState: Record<string, any>) => ({ ...prevState, show: false, }))
        }
        else {
            showDimensions(box, true, unit, setSize)
        }
    }, [undoRedoInProgress, selectedItem, partsLength, finishedLoading, designId,])

    useEffect(() => {
        if (partsLength > 0) {
            const previousDistance = cameraControls.current?.distance ?? 0
            runThroughSceneAndMakeGroupFocusCamera(previousDistance, false, false)
        }
    }, [partsLength, multiSelectContext?.updateCounter,])


    useEffect(() => {
        if (boundingBox.show) {
            const helper = new Box3Helper(boundingBox.box, new Color("blue"))
            scene.add(helper)
        }
        if (boundingBox.show === false) {
            scene.children.forEach((child) => {
                if (child instanceof Box3Helper) {
                    scene.remove(child)
                }
            })

        }
    }, [boundingBox.show,])


    useEffect(() => {
        inAutofocusModeRef.current = inAutofocusMode
    }, [inAutofocusMode,])

    useEffect(() => {
        if (cameraControls.current) {
            cameraControls.current.touches.one = CAMERA_ACTIONS.TOUCH_OFFSET
            cameraControls.current.mouseButtons.left = CAMERA_ACTIONS.OFFSET

            if (removeCameraLock) {
                cameraControls.current.minAzimuthAngle = -Infinity
                cameraControls.current.maxAzimuthAngle = Infinity
                cameraControls.current.minPolarAngle = 0
                cameraControls.current.maxPolarAngle = Math.PI
            } else {
                cameraControls.current.minAzimuthAngle = MathUtils.degToRad(0)
                // cameraControls.current.minAzimuthAngle = MathUtils.degToRad(0.2)
                cameraControls.current.maxAzimuthAngle = Infinity
                cameraControls.current.minPolarAngle = MathUtils.degToRad(0)
                // cameraControls.current.minPolarAngle = MathUtils.degToRad(0.2)
                cameraControls.current.maxPolarAngle = 2 * Math.PI
            }
            cameraControls.current.polarRotateSpeed = polarRotateSpeed
            cameraControls.current.azimuthRotateSpeed = azimuthRotateSpeed

            cameraControls.current.addEventListener("control", () => {
                if (inAutofocusModeRef.current) {
                    setInAutofocusModeValue(false)
                }
            })
        }
    }, [cameraControls.current, removeCameraLock, azimuthRotateSpeed, polarRotateSpeed,])

    const getRef = () => {
        return cameraControls
    }

    const getInAutofocusMode = () => {
        return inAutofocusMode
    }

    const setInAutofocusMode = (value: boolean) => {
        setInAutofocusModeValue(value)
    }

    const addMeshToSceneBounds = () => {
        sceneBounds.current.loadedCount = sceneBounds.current.loadedCount + 1
    }

    const init = () => {
        return {
            getRef,
            getInAutofocusMode,
            setInAutofocusMode,
            addMeshToSceneBounds,
        }
    }

    return (
        <>
            <cameraContext.Provider value={init()}>
                {children}
                {boundingBox.box && <DimensionLabels />}
            </cameraContext.Provider>
        </>
    )
}

export default CameraProvider