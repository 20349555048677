import React, { useCallback, useContext, useEffect, useRef } from "react"
import ActionButton from "../../../../../../../../../common/components/ActionButton"
import {
    ActionButtonContainer
} from "./ActionPanelStyles"
import { SEGMENTED_TUBE_UI } from "../types/types"
import { ReactComponent as TrashIcon }
    from "../../../../../../../../../common/assets/icons/Trash.svg"

interface Props {
    tubeUI: SEGMENTED_TUBE_UI;
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void;
    onRemove: () => void;
    canRotate: boolean;
    canSlide: boolean;
    partId?: string;
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[],
        control: "selection" | "translate" | "rotate") => void;
    setIsSelected: (isSelected: boolean) => void;
    transformMode: string;

}

const ActionsUI = (props: Props) => {

    const handleDuplicate = () => {
        if (props.partId) {
            props.onDuplicate(false, [props.partId,])
        }
    }

    const handleRemoveModal = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.NONE)
    }

    const handleShowSlider = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.SLIDER)
    }

    const handleShowRotation = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.ROTATION)
    }

    const handleShowSliderInST = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.SEGMENTED_SLIDER)
    }

    const handleSingleItemMove = () => {
        if (props.partId) {
            //props.setIsSelected(false)
            //need timeout for the colors not to go over each other
            props.setIdsAsHighlightedAndTurnOnControl([props.partId!,], "translate")
            props.setTubeUI(SEGMENTED_TUBE_UI.NONE)
        }
    }

    return (
        <ActionButtonContainer>
            <ActionButton
                selected={props.tubeUI === SEGMENTED_TUBE_UI.SLIDER}
                onClick={handleShowSlider}
            >Edit Length
            </ActionButton>
            <ActionButton
                selected={props.tubeUI === SEGMENTED_TUBE_UI.ROTATION}
                onClick={handleShowRotation}
            >Rotate
            </ActionButton>
            {props.canSlide
                && <ActionButton
                    selected={props.tubeUI === SEGMENTED_TUBE_UI.SEGMENTED_SLIDER}
                    onClick={handleShowSliderInST}
                >Move
                </ActionButton>
            }
            {props.partId && !props.canSlide && (
                <ActionButton
                    onClick={handleSingleItemMove}
                    selected={props.transformMode === "translate"}
                >
                    Move
                </ActionButton>
            )}
            {props.partId && <ActionButton
                    onClick={handleDuplicate}
                >2x
                </ActionButton>
            }
            <ActionButton
                onClick={props.onRemove}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: 0,
                    paddingRight: 0,
                }}>
                <TrashIcon />
            </ActionButton>
        </ActionButtonContainer>
    )
}

export default ActionsUI