import { useState, useCallback, useRef } from "react"
import { useThree } from "@react-three/fiber"
import { Vector3, ArrowHelper } from "three"
import { useDebugTools } from "../debugProvider/useDebugTools"

const PHASE_DISTANCES = [0.03, 0.05, 0.07, 0.1,] // Distances for each phase

const useUnsnap = ({ unsnapHandler, }: { unsnapHandler: (value: Vector3) => void, }) => {
    const [isUnsnapped, setIsUnsnapped,] = useState(false)
    const [isUnsnapping, setIsUnsnapping,] = useState(false)
    const [currentPhase, setCurrentPhase,] = useState(0)

    const startPointRef = useRef<Vector3 | null>(null)
    const directionRef = useRef<Vector3 | null>(null)
    const { drawVector3Point, } = useDebugTools()
    const hasUnsnappedRef = useRef(false)

    const unsnap = useCallback((point: Vector3) => {
        if (!hasUnsnappedRef.current) {
            setIsUnsnapped(true)
            hasUnsnappedRef.current = true
            unsnapHandler(point)
        }
    }, [unsnapHandler,])

    const onPointerUp = useCallback(() => {
        setIsUnsnapping(false)
        setCurrentPhase(0)
    }, [])

    const onPointerMove = (point: Vector3, isIntersecting: boolean) => {
        if (hasUnsnappedRef.current) {
            return
        }
        if (isIntersecting) {
            // Reset everything when intersecting again
            setIsUnsnapping(false)
            setCurrentPhase(0)
            startPointRef.current = null
            directionRef.current = null
            return
        }

        setIsUnsnapping(true)

        if (!startPointRef.current) {
            startPointRef.current = point.clone()
            return
        }

        const direction = point.clone().sub(startPointRef.current)
            .normalize()

        const distance = point.distanceTo(startPointRef.current)
        directionRef.current = direction

        const newPhase = PHASE_DISTANCES.findIndex(d => distance < d)
        setCurrentPhase(newPhase === -1 ? PHASE_DISTANCES.length : newPhase)

        if (newPhase === -1) {
            unsnap(point)
        }
    }

    return {
        isUnsnapped: hasUnsnappedRef.current,
        isUnsnapping,
        unsnapDirection: directionRef.current,
        currentPhase,
        onPointerMove,
        onPointerUp,
    }
}

export default useUnsnap