import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import Button from "../Button"
import { Player as LottiePlayer } from "@lottiefiles/react-lottie-player"
import isMobile from "ismobilejs"
import { Title } from "../DrawerShared"

type Props = {
    onConfirm: () => void,
    animationData: any,
}

const GenericOnboardingContent: React.FC<Props>
    = ({ onConfirm, animationData, }: Props) => {
        const [hasPlayed, setHasPlayed,] = useState(false)
        const playerRef = useRef<LottiePlayer>(null)

        const handleReplay = () => {
            playerRef.current?.setSeeker(0, true)
            playerRef.current?.play()
        }

        useEffect(() => {
            const timer = setTimeout(() => {
                playerRef.current?.play()
            }, 3000)
            return () => clearTimeout(timer)
        }, [])

        return (
            <>
                <Container>
                    <CloseButton onClick={onConfirm}>
                        <CloseIcon />
                    </CloseButton>
                    <Title style={{ textAlign: "center", }}>Please Watch Carefully</Title>
                    <Player
                        ref={playerRef}
                        src={animationData}
                        onEvent={e => {
                            if (e === "complete") {
                                setHasPlayed(true)
                            }
                        }}
                        keepLastFrame={true}
                    >
                    </Player>
                    <ButtonsWrapper style={{ visibility: hasPlayed ? "visible" : "hidden", }}>
                        <Button
                            $borderColor="#0088ff"
                            $labelColor="#0088ff"
                            action="primary"
                            onClick={handleReplay}
                            $full={true}>Replay</Button>
                        <Button
                            color="#0088ff"
                            disabled={!hasPlayed}
                            $loadingColor="#fff"
                            action="primary"
                            $full={true}
                            onClick={onConfirm}
                            type="submit">Got it!
                        </Button>
                    </ButtonsWrapper>
                </Container>
            </>
        )
    }

const CloseIcon = styled.div`
    position: relative;
    color: #000;
    transform: translateX(10px);
    &:before, &:after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 18px;
        background-color: #000;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        color: rgb(59, 134, 247);
        &:before, &:after {
            background-color: rgb(59, 134, 247);
        }
    }
`

const CloseButton = styled.button`
    position: absolute;
    top: -10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;

    &:hover {
        color: rgb(59, 134, 247);

        ${CloseIcon} {
            &:before, &:after {
                background-color: rgb(59, 134, 247);
            }
        }
    }
`

const Container = styled.div`
    position: relative;
`

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    gap: 8px;
`

const Player = styled(LottiePlayer)`
  width: 100%;
  max-width: 80vw;
  height: ${isMobile(window.navigator).phone ? "40vh" : "60vh"}
`

export default GenericOnboardingContent