import { Box3, Group, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"
import { DragMesh, FreePositions } from "./types"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { getFirstAndLastSides } from "./meshHelpers"

function getMeshCorners(sides: any, threshold = 0) {
    const points: any = []
    const firstAndLastSides = getFirstAndLastSides(sides)

    firstAndLastSides.forEach(key => {
        const outerMesh = sides[key].outer
        let realBoundary = convertInToCm(outerMesh.userData.boundary) / 100
        if (!realBoundary) {
            realBoundary = 0.1
        }

        // Add threshold to the boundary
        realBoundary += threshold

        const localPositivePosition = new Vector3(0, realBoundary, 0)
        const globalPositivePosition = outerMesh.localToWorld(localPositivePosition.clone())
        points.push(globalPositivePosition)

        // Negative side of the boundary
        const localNegativePosition = new Vector3(0, -realBoundary, 0)
        const globalNegativePosition = outerMesh.localToWorld(localNegativePosition.clone())
        points.push(globalNegativePosition)
    })

    return points
}

function getScaledPointsArray(points: Vector3[]) {
    const scale = 50
    const center = new Vector3()
    points.forEach((p: Vector3) => center.add(p))
    center.divideScalar(points.length)

    const scaledPoints = points.map((p: Vector3) => {
        const scaledPoint = p.clone().sub(center)
        scaledPoint.multiplyScalar(scale)
        return scaledPoint.add(center)
    })
    return scaledPoints
}

function getDragMeshForMiddle(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    const points = getMeshCorners(sides)
    const scaledPoints = getScaledPointsArray(points)
    const scaledGeometry = new ConvexGeometry(scaledPoints)
    const geometry = new ConvexGeometry(points)

    const meshMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: viewSurfaceMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })

    // const scaledMeshMaterial = meshMaterial.clone()
    // scaledMeshMaterial.opacity = viewDragMesh ? 0.4 : 0

    const scaledMeshMaterial = new MeshBasicMaterial({
        color: 0xff0000,
        opacity: viewDragMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })


    const scaledMesh = new Mesh(scaledGeometry, scaledMeshMaterial)
    const mesh = new Mesh(geometry, meshMaterial)
    scaledMesh.geometry.computeBoundingBox()
    const boundingBox = new Box3().setFromObject(mesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    return {
        dragMesh: scaledMesh,
        surfaceMesh: mesh,
        boundingBox,
        center,
    } as DragMesh
}

function getPointsArrayForMiddle(
    sides: any,
    segmentLength = 0.025,
    extendSurface = false,
    pieceLength?: number
) {
    const points: FreePositions[] = []

    const step = convertInToCm(segmentLength) / 100
    const keys = Object.keys(sides)
    let extendedPoints: FreePositions[] = []
    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100

    keys.forEach(key => {
        const outerMesh = sides[key].outer
        let realBoundary = convertInToCm(outerMesh.userData.boundary) / 100


        if (!realBoundary) {
            realBoundary = step - 0.0001 // single line mesh
        }

        const pointsForMarker = getPointsForMarker(realBoundary, step, key, sides)
        points.push(...pointsForMarker)
        if (extendSurface && realBoundary) {
            realBoundary += (pieceLengthInCm * tolerance)
        }
        const extendedPointsForMarker = getPointsForMarker(realBoundary, step, key, sides)
        extendedPoints.push(...extendedPointsForMarker)
    })

    const firstMeshKey = keys[0]
    const lastMeshKey = keys[keys.length - 1]
    const firstMesh = sides[firstMeshKey].outer
    const lastMesh = sides[lastMeshKey].outer

    if (extendSurface) {
        extendedPoints = extendPoints(extendedPoints, firstMesh, lastMesh, step, pieceLength)
    } else {
        extendedPoints = points
    }

    return { freePositions: extendedPoints, boundingBoxPoints: points, }
}

function getPointsForMarker(
    boundary: number,
    step: number,
    key: string,
    sides: any
) {
    const scaleFactor = 100000
    const outerMesh = sides[key].outer
    const meshName = outerMesh.name
    const scaledStep = step * scaleFactor
    const scaledLimit = boundary * scaleFactor
    const points = []

    for (let i = 0; i <= scaledLimit; i += scaledStep) {
        const unscaledStep = i / scaleFactor
        const localPositivePosition = new Vector3(0, unscaledStep, 0)
        const globalPositivePosition = outerMesh.localToWorld(localPositivePosition.clone())
        const point = {
            meshName: meshName,
            position: globalPositivePosition,
            withinMesh: true,
            marker: sides[key].outer,
            key: key,
        }
        points.push(point)
    }

    for (let i = 0; i >= -scaledLimit; i -= scaledStep) {
        const unscaledStep = i / scaleFactor
        const localNegativePosition = new Vector3(0, unscaledStep, 0)
        const globalNegativePosition = outerMesh.localToWorld(localNegativePosition.clone())
        const point = {
            meshName: meshName,
            position: globalNegativePosition,
            withinMesh: true,
            marker: sides[key].outer,
            key: key,
        }
        points.push(point)
    }

    return points
}



function extendPoints(
    points: FreePositions[],
    firstMesh: Mesh,
    lastMesh: Mesh,
    step: number,
    pieceLength: number | undefined
) {
    const newPoints = [...points,]

    const group = new Group()
    const helper = new Object3D()
    group.add(helper)

    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100
    const pieceLengthInSteps = pieceLengthInCm / step
    const repetitionsWithTolerance = Math.ceil(pieceLengthInSteps * tolerance)

    const realBoundary = convertInToCm(lastMesh.userData.boundary) / 100
    const extendedBoundary = realBoundary + (pieceLengthInCm * tolerance)
    const scaleFactor = 100000
    const scaledStep = step * scaleFactor
    const scaledLimit = extendedBoundary * scaleFactor

    function addPoints(
        mesh: Mesh,
        direction: number,
    ) {
        mesh.getWorldPosition(group.position)
        mesh.getWorldQuaternion(group.quaternion)
        helper.position.y = 0

        for (let x = 1; x < repetitionsWithTolerance; x++) {
            helper.position.x = direction * step * x
            const worldPosition = new Vector3()
            helper.getWorldPosition(worldPosition)
            newPoints.push({
                meshName: mesh.name,
                position: worldPosition,
                withinMesh: false,
                marker: mesh,
            })

            const addVerticalPoints = (i: number) => {
                const unscaledStep = i / scaleFactor
                helper.position.y = unscaledStep
                const globalPosition = new Vector3()
                helper.getWorldPosition(globalPosition)
                newPoints.push({
                    meshName: mesh.name,
                    position: globalPosition,
                    withinMesh: false,
                    marker: mesh,
                })
            }

            for (let i = 0; i <= scaledLimit; i += scaledStep) {
                addVerticalPoints(i)
            }

            for (let i = 0; i >= -scaledLimit; i -= scaledStep) {
                addVerticalPoints(i)
            }
        }
    }

    addPoints(
        lastMesh, -1,
    )
    addPoints(
        firstMesh, 1,
    )

    return newPoints
}

export { getDragMeshForMiddle, getPointsArrayForMiddle, }
