import { query, Timestamp, where } from "firebase/firestore"
import { EnvHelper } from "../../utils/EnvHelper"
import { DesignTypeAPI } from "../Types"
import {
    getCollection,
    GetDataFromFirebase,
    getDatumFromFirebase,
    getUser,
    saveDataToFirebase,
    updateInFirebase
} from "./firebaseUtils"

const PREPATH = "Users"
const PATH = "Designs"

const getDesignPath = (userId: string) => {
    return `${PREPATH}/${userId}/${PATH}`
}

const saveDesign = async (
    designAsString: string, screenshot: string, name?: string, id?: string, unit?: "cm" | "in",
) => {
    const user = getUser()!
    const actualTimestamp = Timestamp.now()
    const data: Omit<DesignTypeAPI,
        "id" | "creationDate" | "creationBuildId" | "name" | "currentSessionId"
    > = {
        state: designAsString,
        imageBase64: screenshot,
        modificationDate: actualTimestamp,
        modificationBuildId: EnvHelper.buildName,
        userId: user.uid,
        deleted: false,
        unit: unit || "in",
    }
    if (id) {
        await updateInFirebase(getDesignPath(user.uid), id, data,)
        return id
    } else {
        const savedData = await saveDataToFirebase(
            getDesignPath(user.uid),
            {
                ...data,
                creationDate: actualTimestamp, creationBuildId: EnvHelper.buildName, name: name,
            }
        )
        return savedData.id
    }
}

const fetchUserDesigns = async () => {
    const user = getUser()!
    const query_ = query(
        getCollection(getDesignPath(user.uid)),
        where("deleted", "==", false))
    const designs = (await GetDataFromFirebase<DesignTypeAPI>(getDesignPath(user.uid), query_))
        .sort((a, b) =>
        b.modificationDate.toDate().getTime() - a.modificationDate.toDate().getTime())
    return designs
}

const getDesignById = async (userId: string, designId: string,) => {
    const design = await getDatumFromFirebase(getDesignPath(userId),
        designId)
    return design.data() as DesignTypeAPI
}

const renameDesign = async (id: string, newName: string) => {
    const user = getUser()!
    const data = {
        name: newName,
    }
    await updateInFirebase(getDesignPath(user.uid), id, data,)
    return newName
}

const duplicateDesign = async (id: string) => {
    const user = getUser()!
    const design = (await getDatumFromFirebase(getDesignPath(user.uid), id)).data() as DesignTypeAPI
    const newName = `${design.name} - copy`
    const copyId = await saveDesign(design.state, design.imageBase64, newName)
    return { ...design, id: copyId, name: newName, }
}

const deleteDesign = async (id: string) => {
    const user = getUser()!
    const data = {
        deleted: true,
    }
    await updateInFirebase(getDesignPath(user.uid), id, data,)
}

export const DesignsApi = {
    getDesignPath,
    saveDesign,
    fetchUserDesigns,
    getDesignById,
    renameDesign,
    duplicateDesign,
    deleteDesign,
}