/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { FC, useState, useEffect } from "react"
import {
  createUser,
  signInEmailPassword,
  forgotPassword,
  signInWithGoogle
} from "../../api/firebase/firebaseUtils"
import { User } from "firebase/auth"
import styled from "styled-components"
import { useFormInputValidation } from "react-form-input-validation"
import Button from "../Button"
import { GoogleIcon } from "../icons/Icons"
import { events, useEventsData } from "../../utils/rudderAnalyticsUtils"

interface Props {
  debug: boolean;
}

type LoginState = {
  error: Error | undefined,
  isSignUp: boolean,
}

const Input = ({ label, name, fields, form, errors, type, }:
  { label: string, name: string, fields: any, form: any, errors: any, type?: string, }) => {

  const convertRequiredFieldToMessage = (input: string) => {
    if (input.toLowerCase().includes("password") && input.toLowerCase().includes("required")) {
      return "Please enter a password"
    }
    if (input.toLowerCase().includes("email") && input.toLowerCase().includes("required")) {
      return "Please enter an email"
    }
    return input
  }

  return (
    <InputContainer>
      <InputContent>
        <Label>{label}</Label>
        <input
          key={name}
          type={type ? type : "text"}
          name={name}
          onBlur={form.handleBlurEvent}
          onChange={form.handleChangeEvent}
          value={(fields as any)[name]}
        />
      </InputContent>
      <ErrorLabel className="error">
        {(errors as any)[name] ? convertRequiredFieldToMessage((errors as any)[name]) : ""}
      </ErrorLabel>
    </InputContainer>
  )
}

const LoginForm: FC<Props> = ({ debug, }) => {
  const [forgot_fields, forgot_errors, forgot_form,] = useFormInputValidation(
    {
      email: "",
    },
    {
      email: "required|email",
    }
  )
  const [SU_fields, SU_errors, SU_form,] = useFormInputValidation(
    {
      email: "",
      password: "",
    },
    {
      email: "required|email",
      password: "required",
    }
  )
  const [fields, errors, form,] = useFormInputValidation(
    {
      email: "",
      password: "",
    },
    {
      email: "required|email",
      password: "required",
    }
  )
  const [login, setLogin,] = useState<LoginState>({
    error: undefined,
    isSignUp: false,
  })

  const [forgotPasswordState, setForgotPassword,] = useState({
    email: "",
    show: false,
    isLoading: false,
    success: false,
  })

  const eventsData = useEventsData()

  useEffect(() => {
    if (debug) {
      handleSignWithMail({ username: "test2@zeppelinlabs.io", password: "123456", })
    }
  }, [debug,])


  const handleSignWithMail = async (event: any) => {
    try {
      const isValid = await form.validate(event)
      if (isValid) {
        const user = await signInEmailPassword((fields as any).email, (fields as any).password)
        events.login(eventsData())
        handleSuccess(user.user)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleSignWithGoogle = async () => {
    try {
      const user = await signInWithGoogle()
      events.login(eventsData())
      handleSuccess(user.user)
    } catch (error) {
      handleError(error)
    }
  }

  const handleSignUpWithMail = async (event: any) => {
    try {
      const isValid = await SU_form.validate(event)
      if (isValid) {
        const user = await createUser((SU_fields as any).email, (SU_fields as any).password)
        events.signup(eventsData())
        handleSuccess(user.user)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleResetPassword = async (event: any) => {
    setForgotPassword({
      ...forgotPasswordState,
      isLoading: true,
    })
    try {
      const isValid = await forgot_form.validate(event)
      if (isValid) {
        await forgotPassword((forgot_fields as any).email)
        setForgotPassword({
          ...forgotPasswordState,
          isLoading: false,
          success: true,
        })
      }
    } catch (error) {
      setForgotPassword({
        ...forgotPasswordState,
        isLoading: false,
      })
      handleError(error)
    }
  }

  const handleSuccess = (user: User) => {
    setLogin({
      ...login, error: undefined,
    })
  }

  const handleError = (error: any) => {
    if (error instanceof Error) {
      setLogin({
        ...login, error,
      })
    } else {
      setLogin({
        ...login, error: new Error(error as string),
      })
    }
  }

  const handleGoBack = () => {
    setLogin({ error: undefined, isSignUp: false, })
  }

  const handleCreateAccount = () => {
    setLogin({ error: undefined, isSignUp: true, })
  }

  const getLogin = () => {
    return (
      <FormContainer>
        {login.error && <ErrorAlert>{login.error.message}</ErrorAlert>}
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSignWithMail}
        >
          <Input
            key="name-input"
            label="Your email"
            name="email"
            fields={fields}
            form={form}
            errors={errors} />
          <Input
            key="password-input"
            label="Your password"
            name="password"
            type="password"
            fields={fields}
            form={form}
            errors={errors} />
          <Button $full={true} action="primary" color="#0088ff" type="submit">Log in</Button>
          <Button
            $full={true}
            $labelColor="#0088ff"
            $loadingColor="#0088ff"
            loading={forgotPasswordState.isLoading}
            onClick={() => {
              setForgotPassword({
                email: "",
                show: true,
                isLoading: false,
                success: false,
              })
            }}>Forgot password</Button>
        </Form>
        <Divider><span>OR</span></Divider>
        <Social>
          <Button
            $full={true}
            $borderColor="#d6d6d6"
            onClick={handleSignWithGoogle}><GoogleIcon /> Login with Google</Button>
        </Social>
      </FormContainer>
    )
  }

  const getSignup = () => {
    return (
      <FormContainer>
        {login.error && <ErrorAlert>{login.error.message}</ErrorAlert>}
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSignUpWithMail}
        >
          <Input
            key="su-name-input"
            label="Your email"
            name="email"
            fields={SU_fields}
            form={SU_form}
            errors={SU_errors} />
          <Input
            key="su-password-input"
            label="Your password"
            type="password"
            name="password"
            fields={SU_fields}
            form={SU_form}
            errors={SU_errors} />
          <Social>
            <Button $full={true}
              action="primary" color="#0088ff" type="submit">Create my account</Button>
          </Social>
        </Form>
      </FormContainer>)
  }

  const forgotPasswordModal = () => {
    return (
      <FormContainer>
        {login.error && <ErrorAlert>{login.error.message}</ErrorAlert>}
        {!forgotPasswordState.success && <Form onSubmit={handleResetPassword}>
          <Input
            key="forgot-name-input"
            label="Your email"
            name="email"
            fields={forgot_fields}
            form={forgot_form}
            errors={forgot_errors} />
          <Button $full={true} action="primary" color="#0088ff"
            type="submit">
            Send email to reset password
          </Button>
        </Form>}
        {
          // eslint-disable-next-line max-len
          forgotPasswordState.success && <p>An email was sent to reset your password. If you don't see it in your inbox, please check your spam folder</p>
        }
      </FormContainer>
    )
  }

  const getContent = () => {
    if (forgotPasswordState.show) {
      return forgotPasswordModal()
    } else {
      return (
        <React.Fragment>
          <Header>
            <Switch on={!login.isSignUp} onClick={handleGoBack}>Login</Switch>
            <Switch on={login.isSignUp} onClick={handleCreateAccount}>Sign Up</Switch>
          </Header>
          {login.isSignUp ? getSignup() : getLogin()}
        </React.Fragment>
      )
    }
  }

  return (
    <Container>
      {getContent()}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const Form = styled.form`
  width: 100%;
  padding-top: 22px;
  flex-direction: column;
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
`

const ErrorAlert = styled.div`
  width: 100%;
  margin-top: 16px;
  border: 1px solid #fe5b5a;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: #fe5b5a;
  font-size: 12px;
`

const Label = styled.div`
  width: 100%;
  margin-bottom: 5px;
`

const InputContent = styled.label`
  width: 100%;

  input {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: solid 1px #00000057;
  }
`

const Divider = styled.div`
      width: 100%;
    display: flex;
    justify-content: center;
    height: 1px;
    background: gainsboro;
    overflow: visible;
    align-items: center;
    z-index: 3;

    margin-top: 30px;
    margin-bottom: 30px;

    span {
      background-color: white;
      padding: 4px;
    }
`

const ErrorLabel = styled.label`
  width: 100%;
  font-size: 12px;
    color: red;
`

const Header = styled.div`
  display: flex;
    background: #dfdfdf;
    padding: 4px;
    border-radius: 10px;
    gap: 3px;
`

const Switch = styled.div<{ on: boolean, }>`
    background: ${(props) => (props.on ? "#0088ff" : "transparent")};
    padding: 5px;
    border-radius: 7px;
    color: ${(props) => (props.on ? "white" : "#686868")};
    min-width: 60px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
`

const Social = styled.div`
  width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const InputContainer = styled.div`
  
`

export default LoginForm