/* eslint-disable max-len */
import React from "react"
import { MutableRefObject } from "react"
import { useRecoilValue } from "recoil"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { ConnectionOfPart } from "../../../../../../../../state/scene/types"
import { getSideAndPosition } from "../../../../../../../../utils/MarkerUtil"
import { SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import NewPartButton from "./NewPartButton"
import segmentedTubeUtils from "../utils/SegmentedTubeUtils"
import { Group, Scene } from "three"

type NewPartButtonsInfo = {
    partId: string,
    info: MutableRefObject<SegmentedTubeInfo>,
    length: number,
    partConnectionsValue: ConnectionOfPart[],
    setIsSelected: (selected: boolean) => void,
    scene?: Scene,
}

const NewPartButtonsGroup = (props: NewPartButtonsInfo) => {
    const connections = useRecoilValue(allConnections)

    const getMarkers = () => {
        const markers: (SegmentedTubeMarker & { isMiddleSection: boolean, })[] = []

        Object.keys(props.info.current.startSection).forEach(key => {
            if (props.partConnectionsValue.every(c => {
                const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
                const firstKey = Object.keys(props.info.current.startSection)[0]
                if (props.info.current.startSection[firstKey] && "mesh" in props.info.current.startSection[firstKey]) {
                    return true
                } else {
                    return markerNumber !== key
                }
            })) {
                markers.push({ ...props.info.current.startSection[key], isMiddleSection: false, })
            }
        })

        Object.keys(props.info.current.endSection).forEach(key => {
            if (props.partConnectionsValue.every(c => {
                const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
                const firstKey = Object.keys(props.info.current.endSection)[0]
                if (props.info.current.endSection[firstKey] && "mesh" in props.info.current.endSection[firstKey]) {
                    return true
                } else {
                    return markerNumber !== key
                }
            })) {
                markers.push({ ...props.info.current.endSection[key], isMiddleSection: false, })
            }
        })

        if (Object.keys(props.info.current.middleSection).length) {
            const markerByFreePositions = segmentedTubeUtils.getMarkerByFreePositions(
                props.partId,
                connections,
                props.info.current.middleSection,
                props.length
            )

            Object.keys(props.info.current.middleSection).forEach(key => {
                const section = props.info.current.middleSection[key]
                markers.push({ ...section[markerByFreePositions], isMiddleSection: true, })
            })
        }
        return markers
    }

    return <>
        {getMarkers()
            .map(marker => ((marker && marker.outer) && <NewPartButton
                partId={props.partId}
                info={props.info}
                marker={marker}
                key={marker.outer!.name}
                isMiddleSection={marker.isMiddleSection}
                setIsSelected={props.setIsSelected}
                scene={props.scene}
                partConnectionsValue={props.partConnectionsValue}
            />)
            )}
    </>
}

export default NewPartButtonsGroup