/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react"
import Button from "../../../../../../../common/components/Button"
import { roundLength } from "../../../../../../utils/utils"
import {
    ButtonContainer,
    NumberSliderContainer,
} from "./ActionPanelStyles"
import { SliderContainer, SliderText } from "./segmentedTube/ui/ActionPanelStyles"
import NumberSlider from "./sliders/Slider"
import { PartConnectionType } from "../../../../../../state/scene/types"
import RelativeMove from "./tube/ui/RelativeMove"

type SegmentedSliderProps = {
    freePositions: number[],
    positions: number[],
    currentValue: number,
    onNewPosition: (newPosition: number) => void,
    onMouseDown?: (newPosition: number) => void,
    onMouseUp: (value: number) => void,
    handleFinishEditing: () => void,
    startLength: number,
    segmentLength: number,
    unit: string,
    updateUnit: (unit: string) => void,
    useDragSlide: boolean,
    connectedToSegmentedParts:PartConnectionType[] | undefined,
    connectionIndex: number,
    setConnectionIndex: (index: number) => void,
}


const SegmentedSlider = (props: SegmentedSliderProps) => {
    enum SegmentedSliderFrom {
        change = "change",
        mouseUp = "mouseUp"
    }

    const roundedSegmentLength = roundLength(props.segmentLength)
    const roundedStartLength = roundLength(props.startLength)
    const disabled = props.useDragSlide

    const positions = props.positions.sort((a, b) => a - b).map((v, index) => {
        return {
            value: v,
            displayValue: ((index + 1) * roundedSegmentLength) + roundedStartLength,
        }
    })

    const freePositions = props.freePositions.sort((a, b) => a - b).map((v, index) => {
        const position = positions.find(({ value, }) => v === value)
        return {
            value: v,
            displayValue: roundLength(position!.displayValue),
        }
    })

    const [slider, setSlider,] = useState<{
        value: number,
        from: SegmentedSliderFrom | undefined,
    }>({
        value: disabled
            ? 0
            : freePositions.find(v => v.value === props.currentValue)!.displayValue,
        from: undefined,
    })

    const min = roundedStartLength + roundedSegmentLength
    const max = freePositions[freePositions.length - 1].displayValue


    const handleMouseUp = (e: number) => {
        setSlider({ value: e, from: SegmentedSliderFrom.mouseUp, })
    }

    const handleMouseDown = (num: number) => {
        if (props.onMouseDown) {
            const value = freePositions.find(v => v.displayValue === num)!.value
            props.onMouseDown(value)
        }
    }

    const getNextFreePositon = (position: number) => {
        const directionAux = roundedSegmentLength * (position > slider.value ? 1 : -1)
        let auxPosition = position + directionAux
        let foundNewPosition = false
        while (!foundNewPosition) {
            if (freePositions.some(v => v.displayValue === auxPosition)) {
                foundNewPosition = true
            } else if (freePositions[freePositions.length - 1].displayValue < auxPosition
                || auxPosition < 0) {
                foundNewPosition = true
                auxPosition = position
            } else {
                auxPosition = auxPosition + directionAux
            }
        }
        return auxPosition
    }

    const handleSlider = (e: any) => {
        let newPosition = Number(e)
        if (!freePositions.some(v => v.displayValue === newPosition)) {
            newPosition = getNextFreePositon(newPosition)
        }
        if (newPosition >= min && newPosition <= max) {
            setSlider({ value: newPosition, from: SegmentedSliderFrom.change, })
        }
    }

    const onFinishEditing = () => {
        props.handleFinishEditing()
    }

    const getValue = () => {
        return slider.value
    }

    useEffect(() => {
        if (
            slider.from === SegmentedSliderFrom.change
            && freePositions.find(v => v.displayValue === slider.value)
        ) {
            props.onNewPosition(freePositions.find(v => v.displayValue === slider.value)!.value)
        } else if (slider.from === SegmentedSliderFrom.mouseUp) {
            props.onMouseUp(freePositions.find(v => v.displayValue === slider.value)!.value)
        }
    }, [slider,])

    const getSlider = () => {
        if (props.useDragSlide) {
            if(props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1){
                return <SliderText>
                    Moving selected relative to:
                    <RelativeMove
                        connectedToSegmentedParts={props.connectedToSegmentedParts}
                        connectionIndex={props.connectionIndex}
                        setConnectionIndex={props.setConnectionIndex}
                    />
                </SliderText>
            }
        } else {
            return (<NumberSliderContainer>
                <NumberSlider
                    unit={props.unit}
                    updateUnit={props.updateUnit}
                    min={min}
                    max={max}
                    step={roundedSegmentLength}
                    handleMouseUp={handleMouseUp}
                    onChange={handleSlider}
                    getValue={getValue}
                    handleMouseDown={handleMouseDown}
                    marks={false}
                    inputNumber={false}
                    disabledTyping={true}
                />
            </NumberSliderContainer>)
        }
    }
    return <SliderContainer>
        {getSlider()}
    </SliderContainer>
}

export default SegmentedSlider