import hotkeys from "hotkeys-js"
import produce, { applyPatches, Patch } from "immer"
import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import {
    atom, useRecoilCallback, useRecoilState, useRecoilTransactionObserver_UNSTABLE,
    useRecoilValue, useResetRecoilState, useSetRecoilState
} from "recoil"
import { sceneAtom } from "../../../state/scene/atoms"
import Button from "../../../../common/components/Button"
import { RedoIcon, UndoIcon } from "../../../../common/components/icons/Icons"
import { layoutState } from "../../../state/layoutState"
import { pendingChangesAtom, saveDesignAtom, selectedItemID } from "../../../state/atoms"
import { breadcrumb } from "../../../../common/utils/sentrySetup"

type Props = {
    loaded: boolean,
}

type HistoryPart = {
    undoPatches: Patch[],
    redoPatches: Patch[],
}

export type HistoryType = {
    resetRedo: boolean,
    history: HistoryPart[],
    undoRedoInProgress: boolean,
    changeInProgress: any,
}

export const historyAtom = atom<HistoryType>({
    key: "historyAtom",
    default: {
        resetRedo: false,
        history: [],
        undoRedoInProgress: false,
        changeInProgress: "",
    },
})

export const HistoryLogger = () => {
    useRecoilTransactionObserver_UNSTABLE(({ snapshot, }) => {
        const pendingChanges = snapshot.getLoadable(pendingChangesAtom).contents
        const history = snapshot.getLoadable(historyAtom).contents
        const scene = snapshot.getLoadable(sceneAtom).contents
        console.log("pendingChangesAtom:", pendingChanges)
        console.log("historyAtom:", history)
        console.log("sceneAtom:", scene)
    })

    return null
}


export const useAddToHistoryPrivate = (resetRedo?: boolean) => {
    const setPendingChanges = useSetRecoilState(pendingChangesAtom)
    const setHistory = useSetRecoilState(historyAtom)
    const history = useRecoilValue(historyAtom)
    return (p: Patch[], ip: Patch[]) => {
        // Filter out patches with an empty 'op' key
        const filteredRedoPatches = p.filter(patch => (patch.op as string) !== "")
        const filteredUndoPatches = ip.filter(patch => (patch.op as string) !== "")

        if (filteredRedoPatches.length > 0 || filteredUndoPatches.length > 0) {
            setPendingChanges(true)
            setHistory(
                produce((h) => {
                    if (resetRedo) {
                        h.resetRedo = resetRedo
                    }
                    h.history.push({
                        redoPatches: filteredRedoPatches,
                        undoPatches: filteredUndoPatches,
                    })
                    breadcrumb({
                        message: "After adding changes to history",
                        level: "info",
                    })
                })
            )
        }
    }
}

export const useGoBack = () => {
    return useRecoilCallback(
        ({ set, }) => {
            return (patch: Patch[]) => {
                set(sceneAtom, (atom) => applyPatches(atom, patch))
            }
        }
    )
}

const History: React.FC<Props> = (props: Props) => {
    const [historyState, setHistoryState,] = useRecoilState(historyAtom)
    const historyUndoRef = useRef<HistoryPart[]>(historyState.history)
    const historyRedoRef = useRef<HistoryPart[]>([])
    const layoutValue = useRecoilValue(layoutState)
    const saveDesign = useRecoilValue(saveDesignAtom)
    const goBack = useGoBack()
    const header = useRef(document.getElementById("header-content"))
    const [forceUpdate, setForceUpdate,] = useState(0)
    const resetSelectedItem = useResetRecoilState(selectedItemID)

    const undo = useCallback(() => {
        if (historyUndoRef.current.length > 0) {
            const historyPatch = historyUndoRef.current[historyUndoRef.current.length - 1]
            goBack(historyPatch.undoPatches)
            historyRedoRef.current.push(historyPatch)
            setHistoryState(produce(historyDraft => {
                historyDraft.undoRedoInProgress = true
                if (historyPatch.undoPatches.length > 0
                    && historyPatch.redoPatches[0].path.length > 2) {
                    historyDraft.changeInProgress = historyPatch.redoPatches[0].path[2]
                }
                historyDraft.resetRedo = false
                historyDraft.history.pop()
                setTimeout(() => {
                    setHistoryState(produce(draft => {
                        draft.undoRedoInProgress = false
                    }))
                }, 300)
            }))
            resetSelectedItem()
        }
    }, [setHistoryState,])

    const redo = useCallback(() => {
        if (historyRedoRef.current.length > 0) {
            const historyPatch = historyRedoRef.current[historyRedoRef.current.length - 1]
            goBack(historyPatch.redoPatches)
            historyRedoRef.current.pop()
            setHistoryState(produce(historyDraft => {
                historyDraft.undoRedoInProgress = true
                if (historyPatch.redoPatches.length > 0
                    && historyPatch.redoPatches[0].path.length > 2) {
                    historyDraft.changeInProgress = historyPatch.redoPatches[0].path[2]
                }
                historyDraft.resetRedo = false
                historyDraft.history.push(historyPatch)
                setTimeout(() => {
                    setHistoryState(produce(draft => {
                        draft.undoRedoInProgress = false
                    }))
                }, 300)
            }))
            resetSelectedItem()
        }
    }, [setHistoryState,])

    useEffect(() => {
        if (historyState.resetRedo) {
            historyRedoRef.current = []
        }
        historyUndoRef.current = historyState.history
    }, [historyState,])

    useEffect(() => {
        hotkeys("command+z,command+shift+z", { keyup: true, }, (event, handler) => {
            event.preventDefault()
            switch (handler.key) {
                case "command+z":
                    undo()
                    break
                case "command+shift+z":
                    redo()
                    break
                default:
                    break
            }
        })
    }, [redo, undo,])

    useEffect(() => {
        if (props.loaded) {
            header.current = document.getElementById("header-content")
            setForceUpdate(forceUpdate + 1)
        }
    }, [props.loaded,])

    const portal = props.loaded && header.current ? (
        ReactDOM.createPortal(
            <React.Fragment>
                {/*<HistoryLogger />*/}
                <Button
                    disabled={
                        layoutValue.isDrawerOpen
                        || saveDesign || historyUndoRef.current.length === 0}
                    onClick={undo}><UndoIcon color="black" /></Button>
                <Button
                    disabled={
                        layoutValue.isDrawerOpen
                        || saveDesign || historyRedoRef.current.length === 0}
                    onClick={redo}><RedoIcon color="black" /></Button>
            </React.Fragment>,
            header.current
        )
    ) : null

    return portal as React.ReactElement | null
}

export default History