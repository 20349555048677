/* eslint-disable max-len */
import { useEffect } from "react"
import { useThree } from "@react-three/fiber"
import hotkeys from "hotkeys-js"
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter"
import { Group, InstancedMesh, Mesh, MeshStandardMaterial, Quaternion, Vector3 } from "three"

const GLTFExportManager = () => {
    const { scene, } = useThree()

    useEffect(() => {
        hotkeys("command+shift+d,ctrl+shift+d", (event, handler) => {
            // Prevent the default refresh event under WINDOWS system
            event.preventDefault()

            let group: Group | null = new Group()

            const mat = new MeshStandardMaterial({ color: "white", })

            scene.traverse((child) => {
                if (child.userData && child.userData.type && child.userData.type === "COLLISION_TUBE") {
                    const p = new Vector3()
                    const r = new Quaternion()
                    const msh = child as Mesh
                    msh.getWorldPosition(p)
                    msh.getWorldQuaternion(r)
                    const mesh = new Mesh(msh.geometry, mat)
                    mesh.position.set(p.x, p.y, p.z)
                    mesh.applyQuaternion(r)
                    group?.attach(mesh)
                }
                if (child.userData && child.userData.type && child.userData.type === "InstancedMesh") {
                    const instancedMesh = child as InstancedMesh
                    const count = instancedMesh.count
                    const geometry = instancedMesh.geometry
                    const material = instancedMesh.material
                    for (let i = 0; i < count; i++) {

                        const mesh = new Mesh(geometry, material)

                        instancedMesh.getMatrixAt(i, mesh.matrix)
                        mesh.matrix.decompose(mesh.position, mesh.quaternion, mesh.scale)

                        group?.add(mesh)
                    }
                }
            })

            const gltfExporter = new GLTFExporter()

            const options = {
                trs: true,
                onlyVisible: false,
                binary: false,
            }

            gltfExporter.parse(
                group,
                (result) => {
                    const output = JSON.stringify(result, null, 2)
                    const link = document.createElement("a")
                    link.style.display = "none"
                    document.body.appendChild(link)
                    link.href = URL.createObjectURL(new Blob([output,], { type: "text/plain", }))
                    link.download = "scene.gltf"
                    link.click()
                    link.remove()
                    group?.traverse((child) => {
                        let mesh = child as Mesh | null
                        if (mesh) {
                            mesh.geometry?.dispose();
                            (mesh.material as MeshStandardMaterial)?.dispose()
                            mesh = null
                        }
                    })
                    group = null
                },
                (error) => {
                    console.error("An error occurred during export:", error)
                },
                options
            )
        })
    }, [scene,])

    return null
}

export default GLTFExportManager