import React, { useState } from "react"
import produce from "immer"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"
import { logOut } from "../../../../../common/api/firebase/firebaseUtils"
import {
    pendingChangesAtom,
    saveDesignAtom,
    selectedItemID,
    layoutState,
    designNameSelector,
    useUnmountDesign
} from "../../../../exportables"
import Button from "../../../../../common/components/Button"
import {
    HambIcon, PartListIcon, SaveIcon
} from "../../../../../common/components/icons/Icons"
import {
    authState
} from "../../../../../common/state/LoginState/RecoilAuthState"
import {
    LoginButton
} from "../../../../../common/components/LoginPopup/LoginPopUp"
import Logo from "../../../../../common/components/Logo"
import { ShareButton } from "../../../../../manager/components/ShareModal/ShareModal"
import { useNavigate, useParams } from "react-router-dom"
import { getUrlWithQueriesToPreserve } from "../../../../../common/utils/utils"
import { UnsavedChangesModal } from "../../../../../manager/components/Designs/unsavedChangesModal"
import RadixDropdown from "./Dropdown/RadixDropdown"
import ButtonLinks from "../../../../../common/components/ButtonLinks/ButtonLinks"
import helpLinks from "../../../../../common/utils/HelpLinks"
import { RoutesHelper } from "../../../../../common/utils/routesHelper"

interface Props {
    isMobile?: boolean;
}

const Header = (props: Props) => {
    const [layoutValue, setLayout,] = useRecoilState(layoutState)
    const designName = useRecoilValue(designNameSelector)
    const auth = useRecoilValue(authState)
    const [pendingChanges, setPendingChanges,] = useRecoilState(pendingChangesAtom)
    const [showUnsavedChanges, setShowUnsavedChanges,] = useState(false)
    const setSelectedItemID = useSetRecoilState(selectedItemID)

    const { userId, designId, } = useParams()
    const [saveDesign, setSaveDesign,] = useRecoilState(saveDesignAtom)
    const navigate = useNavigate()
    const unmount = useUnmountDesign()

    const handleSaveProject = () => {
        setSelectedItemID(null)
        setTimeout(() => {
            setSaveDesign(true)
        }, 10)
    }

    const handleLogOut = () => {
        logOut()
    }

    const handlePartList = () => {
        setLayout(produce((draft) => {
            draft.showPartList = true
            draft.showDesignsList = false
            draft.isDrawerOpen = true
        }))
    }

    const handleMyDesigns = () => {
        setLayout(produce((draft) => {
            draft.showDesignsList = true
            draft.showPartList = false
            draft.isDrawerOpen = true
        }))
    }

    const goToNewDesign = () => {
        setShowUnsavedChanges(false)
        setPendingChanges(false)
        unmount()
        navigate(getUrlWithQueriesToPreserve(RoutesHelper.main))
    }

    const handleNewDesign = () => {
        if (pendingChanges) {
            setShowUnsavedChanges(true)
        } else {
            goToNewDesign()
        }
    }

    const handleNotContinueOnDesignChange = () => {
        setShowUnsavedChanges(false)
    }

    return (
        <Container isMobile={props.isMobile}>
            <LeftContent>
                <Logo isMobile={props.isMobile} />
                {designName && <ProjectName isMobile={props.isMobile}>{designName}</ProjectName>}
            </LeftContent>
            <RightContent isMobile={props.isMobile}>
                <div style={{ display: "flex", gap: "16px", }} id="header-content" />
                <Button
                    $ifMobile={<SaveIcon color="black" />}
                    loading={saveDesign}
                    disabled={layoutValue.isDrawerOpen || saveDesign}
                    onClick={handleSaveProject}>Save</Button>
                {userId && designId && <ShareButton
                    userId={userId}
                    designId={designId}
                    disabled={layoutValue.isDrawerOpen}
                />
                }
                {!props.isMobile
                    && <Button
                        $ifMobile={<PartListIcon color="black" />}
                        disabled={layoutValue.isDrawerOpen}
                        onClick={handlePartList}>Parts List</Button>}
                {!props.isMobile
                    && (auth.user
                        ? <Button
                            $ifMobile={<HambIcon color="black" />}
                            disabled={layoutValue.isDrawerOpen}
                            onClick={handleMyDesigns}>My Designs</Button>
                        : <Button
                            disabled={layoutValue.isDrawerOpen}
                            onClick={handleNewDesign}>+ New Design</Button>)
                }
                {props.isMobile
                    && <Button
                        $ifMobile={<HambIcon color="black" />}
                        disabled={layoutValue.isDrawerOpen}
                        onClick={handleMyDesigns}>My Designs</Button>}
                {!props.isMobile
                    && <RadixDropdown
                        disabled={layoutValue.isDrawerOpen}
                        trigger={
                            <ButtonTrigger disabled={layoutValue.isDrawerOpen}>
                                Help
                            </ButtonTrigger>
                        }
                        children={
                            <ButtonLinks
                                listOfLinks={helpLinks}
                                isMobile={props.isMobile}
                            />}
                        customStyles={{
                            maxHeight: "50vh",
                        }}
                    />
                }
                {
                    !props.isMobile
                    && (
                        auth.user
                            //Log Out
                            ? <Button
                                disabled={layoutValue.isDrawerOpen}
                                onClick={handleLogOut}>Logout</Button>

                            //Log in
                            : <LoginButton
                                renderButton={(handleLogIn) =>
                                    <Button
                                        disabled={layoutValue.isDrawerOpen}
                                        onClick={handleLogIn}>Sign up / Login</Button>
                                }
                            />
                    )
                }
            </RightContent>
            {showUnsavedChanges
                && <UnsavedChangesModal
                    onConfirm={goToNewDesign} onCancel={handleNotContinueOnDesignChange} />
            }
        </Container>
    )
}

const Container = styled.div<Props>`
    height: 44px;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
    position: ${(props) => (props.isMobile ? "absolute" : "relative")};
    top: 0;
    left: 0;
    width: 100%;
`

const ProjectName = styled.div<Props>`
    white-space: nowrap;
    color: #aeaeae;
    line-height: 30px;
    max-width: ${(props) => (props.isMobile ? "100px" : "150px")};;
    text-overflow: ellipsis;
    overflow: hidden;
`

const LeftContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
`

const RightContent = styled.div<Props>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
`

const ButtonTrigger = styled.button<{ disabled: boolean, }>`
    background-color: white;
    border: none;
    transition: all 0.16s ease-in-out;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 6px;
    padding: 8px;
    color: black;
    width: auto;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    &:hover {
        background-color: ${(props) => (props.disabled ? "white" : "#eaeaea")};
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
`

export default Header
