/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { MutableRefObject, useEffect, useState } from "react"
import { MathUtils, Mesh } from "three"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { ConnectorValues, XYZ, XYZW } from "../../../../../../../../utils/Types"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { ConnectorInternalsType, CONNECTOR_UI } from "../types/types"
import { getInitialMarker, } from "./ConnectorUtils"
import { useSlide } from "../../../../../../../../providers/slideProvider/useSlide"
import {
    areSameConnection,
    resetMarkerValues,
    setMarkerDirAndPos
} from "./ConnectorUtils"
import { connectionIndexSelector } from "../../../../../../../../state/scene/selectors"

type UseHandleSlideProps = {
    connector: ConnectorValues,
    connectorUI: CONNECTOR_UI,
    attachedMarker: MutableRefObject<Mesh | undefined>,
    connectorInternalsRef: MutableRefObject<ConnectorInternalsType>,
    attachEverythingTo: (marker: Mesh) => void,
    detachEverythingFrom: (marker: Mesh) => void,
    buildCollider: () => void,
    updateCollider: (removePart?: (() => void), newConnectedParts?: string[]) => void,
    savePositionRotationChanges: (newConnectorValues: {
        posAndRot: {
            pos: XYZ,
            rot: XYZW,
        },
        rotationMarkerName: string,
    }, partIds: string[], newConnections: PartConnectionType[]) => void,
    canEditSlide: (sliderPartId?: string,) => {
        sliderMarker: string,
        partsToMove: string[],
    } | undefined,
    handleConnectorState: () => void,
    checkExactSnap: () => PartConnectionType[],
    setConnectorUI: (ui: CONNECTOR_UI) => void,
    saveSegmentedTubeSliderChanges: (partIds: string[], newConnections: PartConnectionType[]) => void,
    sliderPartId?: string,
    attachToMove: (partsIds: string[], marker: Mesh) => void,
    detachMarkers: (partsIds: string[], marker: Mesh, getExactSnaps?: boolean, checkCollisions?: boolean, resetGuidelines?: boolean) => PartConnectionType[],
    connectionIndex: number,
}

const useHandleSlide = (props: UseHandleSlideProps) => {
    const [multipleMove, setMultipleMove,] = useState<{
        sliderMarker: string,
        partsToMove: string[],
        toSave: boolean,
        newConnections: PartConnectionType[],
    } | undefined>(undefined)

    useEffect(() => {
        return () => setMultipleMove(undefined)
    }, [])

    const resetMultipleMove = () => {
        setMultipleMove(undefined)
    }

    const setNewChangesToSave = (newConnections: PartConnectionType[]) => {
        setMultipleMove({ ...multipleMove!, toSave: true, newConnections, })
    }

    const saveMultipleMovePositionRotation = (newConnections: PartConnectionType[]) => {
        const initialMarker
            = getInitialMarker(props.connector, props.connectorInternalsRef.current.markers)
        initialMarker.rotateY(MathUtils.degToRad(-180))
        const pos = MeshUtils.copyWorldPosition(initialMarker)
        const rot = props.connector.rotation
        initialMarker.rotateY(MathUtils.degToRad(180))
        props.savePositionRotationChanges(
            {
                posAndRot: {
                    pos: { x: pos.x, y: pos.y, z: pos.z, },
                    rot: { x: rot.x, y: rot.y, z: rot.z, w: rot.w, },
                },
                rotationMarkerName: initialMarker.name,
            },
            multipleMove!.partsToMove,
            newConnections,
        )
        setMultipleMove(undefined)
    }

    const getNewConnectionsToSave = () => {
        let newConnections: PartConnectionType[] = []
        if(multipleMove?.partsToMove) {
            props.attachToMove(multipleMove?.partsToMove, props.attachedMarker.current!)
            newConnections = props.detachMarkers(multipleMove?.partsToMove, props.attachedMarker.current!, true, false, true)
            if(multipleMove && newConnections.length > 0) {
                SoundHelper.playUnsnap()
            }
        }
        return newConnections
    }

    const slide = useSlide(props.connector.id, props.connectionIndex)
    const canSlide = slide.canSlide()

    const changeAttachedMarker = (newAttachedMarker: Mesh) => {
        const posAux = MeshUtils.copyWorldPosition(newAttachedMarker)
        const rotAux = MeshUtils.copyWorldQuaternion(newAttachedMarker)
        resetMarkerValues(props.attachedMarker.current!)
        props.detachEverythingFrom(props.attachedMarker.current!)
        props.attachEverythingTo(newAttachedMarker)
        setMarkerDirAndPos(newAttachedMarker, rotAux, posAux)
    }

    let canEdit = props.canEditSlide(props.sliderPartId)

    useEffect(() => {
        canEdit = props.canEditSlide(props.sliderPartId)
    }, [props.sliderPartId, props.connectionIndex,])

    useEffect(() => {
        if(props.sliderPartId) {
            if (props.connectorUI === "SEGMENTED_SLIDER") {
            if(canSlide.slidedMarkerName && props.attachedMarker.current!) {
            changeAttachedMarker(props.connectorInternalsRef.current.markers[canSlide.slidedMarkerName])
            }
        }

        setMultipleMove(canEdit ? { ...canEdit, toSave: false, newConnections: [], } : undefined)

        const newConnections = getNewConnectionsToSave()
        if (multipleMove?.toSave) {
            saveMultipleMovePositionRotation(newConnections)

        } else if (props.connectorUI === CONNECTOR_UI.CLOSE) {
            props.setConnectorUI(CONNECTOR_UI.NONE)
            breadcrumb({
                message: "start handle connector state",
                level: "info",
            })
            props.handleConnectorState()
        }}

    }, [props.connectorUI, props.connectionIndex, props.sliderPartId,])

    return {
        multipleMove,
        resetMultipleMove,
        setNewChangesToSave,
    }
}

export default useHandleSlide