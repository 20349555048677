/* eslint-disable max-lines */
/* eslint-disable max-statements */
import {
  ExtrudeGeometry,
  MathUtils,
  Mesh,
  Scene,
  Shape,
  Line,
  BufferGeometry,
  LineBasicMaterial,
  Vector3,
  Raycaster,
  Object3D,
  Intersection,
  Material,
  Matrix4,
  MeshMatcapMaterial,
} from "three"
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader"
import {
  Marker,
  MarkerType,
  TubeMarkerEnum,
  TubePart,
  TubeValues
} from "../../../../../../../../utils/Types"
import paper from "paper"
import { DragStateType, SnapStateType, TubeInternalsType } from "../types/types"
import {
  scale,
  isParallel,
  isCollineal,
  metersToInch,
  isSameDirection
} from "../../../../../utils/utilsThree"
import {
  filterWithValue,
  ObjDictionary,
} from "../../../../../../../../../common/utils/utils"
import {
  roundLength,
  roundNumber
} from "../../../../../../../../utils/utils"
import { ConnectionTypeAPI, SizeAPI } from "../../../../../../../../../common/api/Types"
import { ConnectionOfPart, PartConnectionType } from "../../../../../../../../state/scene/types"
import { SetterOrUpdater } from "recoil"
import { AddPartState } from "../../../../../../../../state/types"
import { notification } from "antd"
import { getSourceData } from "../../../../../../../../utils/swapUtils"
import {
  getMarkerUserData,
  isMarkerUserData,
  MAX_POSSIBLE_LENGTH_REDUCTION
} from "../../../../../../../../utils/MarkerUtil"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { ConnectorMarkerType } from "../../connector/types/types"

// de 0 a 80 inches son de 0 a 2.032metros (Unidad en threejs)
export const convertFromInchToMeterFunction = (value: number) => scale(value, 0, 80, 0, 2.032)
export const convertFromMeterToInchFunction = (value: number) => scale(value, 0, 2.032, 0, 80)

// Valor en inches
export const DRAG_VALUE_OFFSET = 1

// Tiene que ser mayor al DRAG_VALUE_OFFSET. Valor en inches
export const SNAP_VALUE_OFFSET = 2

// eslint-disable-next-line max-statements
export const createShape = (tubeValue: TubeValues) => {
  let shapes: Shape[] = []
  const inch = 2.54

  const outerDiameterTube = scale(tubeValue.diameter.outer, 0, 100, 0, inch)
  const innerDiameterTube = scale(tubeValue.diameter.inner, 0, 100, 0, inch)

  const rectangle = new paper.Path.Rectangle(new paper.Point(0, 100))
  rectangle.fillColor = new paper.Color("#000000")
  const pathOut = new paper.Path.Circle(new paper.Point(30, 30), outerDiameterTube / 2)
  const pathIn = new paper.Path.Circle(new paper.Point(30, 30), innerDiameterTube / 2)
  const out = pathOut.subtract(pathIn)
  out.strokeColor = new paper.Color("#000000")
  out.fillColor = new paper.Color("#000")
  out.position = new paper.Point(0, 0)

  const path = out.exportSVG({ asString: true, }).toString()

  const loader = new SVGLoader()
  const svgData = loader.parse(path)
  svgData.paths.forEach((path: { toShapes: (arg0: boolean) => any[], }, i: any) => {
    shapes = path.toShapes(true)
  })

  const shape = new Shape()
  shape.moveTo(0.25, 0)
  shape.absarc(0, 0, 0.25, 0, 2 * Math.PI, false)

  return shapes
}

export const setMarkerAngleRotation = (mesh: Mesh | null, angle: number) => {
  if (mesh) {
    mesh.rotation.x = MathUtils.degToRad(angle)
  }
}

export const setMaxPossibleLength = (value: number, snapState: SnapStateType) => {
  snapState.maxPossibleLength = value
}

export const attachTubeTrackerTo = (tubeMesh: Mesh, markerToAttach: Mesh, marker: Mesh) => {
  if (tubeMesh && markerToAttach && marker) {
    marker.attach(tubeMesh)
    marker.attach(markerToAttach)
  }
}

export const setPosition = (mesh: Mesh | null, tube: TubeValues) => {
  if (mesh && tube.position) {
    mesh.position.x = tube.position.x
    mesh.position.y = tube.position.y
    mesh.position.z = tube.position.z
  }
}

export const setTubeRotation = (mesh: Mesh | null, tube: TubeValues) => {
  if (mesh) {
    mesh.quaternion.x = tube.rotation.x
    mesh.quaternion.y = tube.rotation.y
    mesh.quaternion.z = tube.rotation.z
    mesh.quaternion.w = tube.rotation.w
  }
}

export const generateExtrudeGeometry = (length: number, shape: Shape[], tubeMesh: Mesh) => {
  const newGeometry = new ExtrudeGeometry(shape, {
    steps: 1,
    depth: convertFromInchToMeterFunction(length),
    bevelEnabled: false,
    bevelThickness: 0,
    bevelSize: 0,
    bevelOffset: 0,
    bevelSegments: 0,
  })
  return newGeometry
}

export const updateTopTrackerPosition = (length: number, movableMarker: Mesh) => {
  if (movableMarker) {
    movableMarker.position.z = -convertFromInchToMeterFunction(length)
  }
}

export const updateTubeMesh = (
  length: number,
  tubeInternals: TubeInternalsType,
  tubeMesh: Mesh<BufferGeometry, Material | Material[]> | undefined,
  updateCamera?: (mesh?: Mesh, matrixWorld?: Matrix4) => void) => {
  const { shape, } = tubeInternals
  if (tubeMesh && tubeInternals.movableMarkerMesh) {
    tubeMesh.geometry = generateExtrudeGeometry(length, shape, tubeMesh)
    if (updateCamera) {
      tubeMesh.updateWorldMatrix(true, true)
      updateCamera(tubeMesh, tubeMesh.matrixWorld)
    }
    updateTopTrackerPosition(length, tubeInternals.movableMarkerMesh)
  }
}

export const getDrag = (dragState: DragStateType) => {
  return dragState
}

export const getSnap = (snapState: SnapStateType) => {
  return snapState
}

export const getLengthOnRelease = (
  length: number,
  snapState: SnapStateType,
  dragState: DragStateType
) => {
  if (snapState.isSnapped) {
    return snapState.maxPossibleLength
  }
  if (snapState.hasCollied) {
    return snapState.maxPossibleLength
  }
  if (length > snapState.maxPossibleLength) {
    return snapState.maxPossibleLength + MAX_POSSIBLE_LENGTH_REDUCTION
  }
  if (dragState.drag) {
    return dragState.dragValue
  }
  return roundLength(length)
}

export const proyectRay = (
  scene: Scene,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  intersectableMeshes?: Object3D[]
) => {
  const { movableMarkerMesh, rayState, } = tubeInternalsRef.current
  if (movableMarkerMesh) {

    removeGuidelines(tubeInternalsRef, scene, setInternals)

    movableMarkerMesh.getWorldPosition(rayState.worldMovableMarkerPosition)
    movableMarkerMesh.getWorldDirection(rayState.worldMovableMarkerDirection)
    rayState.ray.set(rayState.worldMovableMarkerPosition, rayState.worldMovableMarkerDirection)

    if (tubeInternalsRef.current.arrowHelper) {
      scene.remove(tubeInternalsRef.current.arrowHelper)
    }

    // TO DEBUG RAYCAST
    // const arrow = new ArrowHelper(
    //   rayState.worldMovableMarkerDirection,
    //   rayState.worldMovableMarkerPosition, 0.1, new Color("red"))
    // scene.add(arrow)

    const objects = rayState.ray.intersectObjects(
      intersectableMeshes
        ? intersectableMeshes
        : scene.children)

    setInternals({
      ...tubeInternalsRef.current,
      // arrowHelper: arrow,
      guidelines: [],
      rayState: {
        ...rayState,
        objects,
      },
    })
  }
}

export const checkGuidelines = (
  length: number,
  tube: TubeValues,
  scene: Scene,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  tubeMesh: Mesh<BufferGeometry, Material | Material[]> | undefined | undefined,
  setInternals: (newTubeInternals: TubeInternalsType) => void
) => {
  const { rayState, movableMarkerMesh, dragState, guidelines, } = tubeInternalsRef.current
  rayState.objects.forEach((o) => {
    if (((o.face) && (o.object.userData.tubeID !== tube.id))
      && tubeMesh && movableMarkerMesh && (o.object.userData.type === MarkerType.COLLISION_PLANE)
    ) {

      movableMarkerMesh.getWorldDirection(rayState.movableMarkerDirection)

      o.object.getWorldDirection(rayState.collisionPlaneDirection)

      const areParallel = isParallel(
        rayState.movableMarkerDirection, rayState.collisionPlaneDirection)

      // Este numero esta en metros. 1 metro es 1 unidad en threejs
      const DISTANCE_TO_ACTIVATE_GUIDELINE = 0.013

      if (o.distance < DISTANCE_TO_ACTIVATE_GUIDELINE && areParallel) {
        o.object.getWorldPosition(rayState.colliderWorldPosition)
        movableMarkerMesh.getWorldPosition(rayState.worldMovableMarkerPosition)
        const points = [rayState.worldMovableMarkerPosition, rayState.colliderWorldPosition,]
        const geometry = new BufferGeometry().setFromPoints(points)
        const lineMesh = new Line(
          geometry,
          new LineBasicMaterial({ color: "purple", linewidth: 1, })
        )
        guidelines.push(lineMesh)

        setInternals({
          ...tubeInternalsRef.current,
          guidelines,
        })
        scene.add(lineMesh)
      }
    }
  })

}

const checkSnapGuideline = (
  tubeMesh: Mesh<BufferGeometry, Material | Material[]> | undefined | undefined,
  tube: TubeValues,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  scene: Scene,) => {
  const { rayState, movableMarkerMesh, guidelines, } = tubeInternalsRef.current
  rayState.objects.forEach((o) => {
    if (((o.face) && (o.object.userData.tubeID !== tube.id))
      && tubeMesh && movableMarkerMesh && (o.object.userData.type === MarkerType.COLLISION_PLANE)
    ) {

      movableMarkerMesh.getWorldDirection(rayState.movableMarkerDirection)

      o.object.getWorldDirection(rayState.collisionPlaneDirection)

      const areParallel = isParallel(
        rayState.movableMarkerDirection, rayState.collisionPlaneDirection)

      // Este numero esta en metros. 1 metro es 1 unidad en threejs
      const DISTANCE_TO_ACTIVATE_GUIDELINE = 0.03

      if (o.distance < DISTANCE_TO_ACTIVATE_GUIDELINE && areParallel) {
        o.object.getWorldPosition(rayState.colliderWorldPosition)
        movableMarkerMesh.getWorldPosition(rayState.worldMovableMarkerPosition)
        const points = [rayState.worldMovableMarkerPosition, rayState.colliderWorldPosition,]
        const geometry = new BufferGeometry().setFromPoints(points)
        const lineMesh = new Line(
          geometry,
          new LineBasicMaterial({ color: "red", linewidth: 1, })
        )
        guidelines.push(lineMesh)
        scene.add(lineMesh)
        return guidelines
      }
    }
  })

  return undefined
}

export const isCompatibleWith = (
  tube: TubeValues,
  collidingObject: Intersection<Object3D>,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>
) => {
  return (
    compatibilityList[tube.marker.id].compatibleWithMulti.find(
      compatible => compatible === collidingObject.object.userData.id
    ) && tube.marker.sizeId === collidingObject.object.userData.sizeId
  )
}

export const sort = (
  a: Intersection<Object3D>,
  b: Intersection<Object3D>
) => {
  return a.distance - b.distance
}

export const checkSnap = (
  length: number,
  tubeValue: TubeValues,
  tubeInternals: React.MutableRefObject<TubeInternalsType>,
  tubeMesh: Mesh<BufferGeometry, Material | Material[]> | undefined,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  setSnap: (length: number, connection: PartConnectionType) => void,
  scene: Scene,
  compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
) => {
  const { rayState, movableMarkerMesh, snapState, } = tubeInternals.current

  if (compatibilityList) {
    const filteredObjects = rayState.objects.filter((o) => {
      if (isMarkerUserData(o.object)) {
        const userData = getMarkerUserData(o.object)
        if (o.face
          && (userData.id !== tubeValue.id) && tubeMesh && movableMarkerMesh
          && (userData.type === "COLLISION_MARKER")
          && isCompatibleWith(tubeValue, o, compatibilityList)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })

    filteredObjects.sort(sort)

    if (tubeMesh && movableMarkerMesh) {
      const markerTopDirection = MeshUtils.copyWorldDirection(movableMarkerMesh)
      const markerTopPosition = MeshUtils.copyWorldPosition(movableMarkerMesh)

      const selectedObject = filteredObjects.filter((o) => {
        const objectDirection = MeshUtils.copyWorldDirection(o.object)
        const objectPosition = MeshUtils.copyWorldPosition(o.object)
        const areCollineal = isCollineal(
          markerTopDirection, markerTopPosition, objectDirection, objectPosition
        )
        const areSameDirection = isSameDirection(
          markerTopDirection, objectDirection
        )
        return o.object.name.includes(ConnectorMarkerType.inner)
          && areCollineal
          && !areSameDirection
      })[0]

      if (selectedObject
        && (length > snapState.maxPossibleLength - DRAG_VALUE_OFFSET
          || length >= snapState.maxPossibleLength)
        && !snapState.isSnapped
      ) {
        SoundHelper.playUnsnap()
        updateTubeMesh(snapState.maxPossibleLength, tubeInternals.current, tubeMesh)
        const guidelines = checkSnapGuideline(tubeMesh, tubeValue, tubeInternals, scene)
        setInternals({
          ...tubeInternals.current,
          guidelines: guidelines ? guidelines : tubeInternals.current.guidelines,
          snapState: {
            ...tubeInternals.current.snapState,
            isSnapped: true,
          },
        })
        setSnap(
          snapState.maxPossibleLength,
          {
            partA: {
              partId: tubeValue.id,
              markerName: movableMarkerMesh.name,
            },
            partB: {
              partId: selectedObject.object.userData.partId,
              markerName: selectedObject.object.name,
            },
          }
        )
      }
    }
  }
}

export const checkCollision = (
  length: number,
  tube: TubeValues,
  tubeInternals: TubeInternalsType,
  tubeMesh: Mesh<BufferGeometry, Material | Material[]> | undefined,
  setInternals: (newTubeInternals: TubeInternalsType) => void
) => {
  const { snapState, rayState, movableMarkerMesh, } = tubeInternals
  if (!snapState.isSnapped) {
    // Check collision
    const a = rayState.objects.filter((o) => {
      if ((o.face && (o.object.userData.id !== tube.id))
        && ((o.object.userData.type === "COLLISION_TUBE")
          || (o.object.userData.type === "COLLISION_CONNECTOR"))
      ) {
        return true
      }
      return false
    })

    if (!snapState.maxLengthSetted && a.length > 0 && movableMarkerMesh) {
      const distAux = convertFromMeterToInchFunction(a[0].distance - 0.01)
      setMaxPossibleLength(length + distAux - MAX_POSSIBLE_LENGTH_REDUCTION, snapState)
      setInternals({
        ...tubeInternals,
        snapState: {
          ...snapState,
          maxLengthSetted: true,
        },
      })
    }

    if (a.length === 0) {
      setMaxPossibleLength(tube.maxLength, snapState)
    }

    if (tubeMesh) {
      if (length >= snapState.maxPossibleLength && a.length > 0) {
        setInternals({
          ...tubeInternals,
          snapState: {
            ...snapState,
            hasCollied: true,
          },
        })
      } else {
        setInternals({
          ...tubeInternals,
          snapState: {
            ...snapState,
            hasCollied: false,
          },
        })
      }
    }
  }
}

export const setTubeLength = (
  props: {
    length: number,
    tube: TubeValues,
    scene: Scene,
    tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
    tubeMeshRef: React.MutableRefObject<Mesh<BufferGeometry, Material | Material[]> | undefined>,
    setInternals: (newTubeInternals: TubeInternalsType) => void,
    setSnap: (length: number, connection: PartConnectionType) => void,
    updateCamera: (mesh?: Mesh, matrixWorld?: Matrix4) => void,
    buildCollider?: () => void,
    updateCollider?: () => void,
    compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
    ignoreGuidelines?: boolean,
  }
) => {
  const {
    length,
    tube,
    scene,
    tubeInternalsRef,
    tubeMeshRef,
    setInternals,
    setSnap,
    updateCamera,
    compatibilityList,
    ignoreGuidelines,
  } = props
  updateTubeMesh(length, tubeInternalsRef.current, tubeMeshRef.current, updateCamera)
  proyectRay(scene, tubeInternalsRef, setInternals)
  if (!ignoreGuidelines) {
    checkGuidelines(length, tube, scene, tubeInternalsRef, tubeMeshRef.current, setInternals)
  }
  checkSnap(length, tube, tubeInternalsRef,
    tubeMeshRef.current, setInternals, setSnap, scene, compatibilityList)
}

export const setTubeLengthSimple = (
  length: number,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  tubeMeshRef: React.MutableRefObject<Mesh<BufferGeometry, Material | Material[]> | undefined>,
  updateCamera: (mesh?: Mesh, matrixWorld?: Matrix4) => void
) => {
  updateTubeMesh(length, tubeInternalsRef.current, tubeMeshRef.current, updateCamera)
}

export const getTubeInternals = () => {
  const def = {
    arrowHelper: null,
    tubeMesh: null,
    markerTopMesh: null,
    markerBottomMesh: null,
    movableMarkerMesh: null,
    shape: [],
    dragState: {
      drag: false,
      dragValue: 0,
    },
    snapState: {
      isSnapped: false,
      hasCollied: false,
      maxPossibleLength: 0,
      maxLengthSetted: false,
      targetMarker: null,
    },
    guidelines: [],
    rayState: {
      worldMovableMarkerPosition: new Vector3(),
      worldMovableMarkerDirection: new Vector3(),
      ray: new Raycaster(new Vector3(), new Vector3()),
      movableMarkerDirection: new Vector3(),
      collisionPlaneDirection: new Vector3(),
      colliderWorldPosition: new Vector3(),
      objects: [],
    },
    mmSnapState: {
      isSnapped: false,
      hasCollied: false,
      maxPossibleLength: 0,
      maxLengthSetted: false,
      targetMarker: null,
      newConnection: undefined,
    },
  }

  return def
}

export const getMarkers = (
  tubeValues: TubeValues,
  tubeInternalRef: React.MutableRefObject<TubeInternalsType>
) => {
  if (tubeValues.originMarkerName === TubeMarkerEnum.BOTTOM) {
    return {
      origin: tubeInternalRef.current.markerBottomMesh,
      movable: tubeInternalRef.current.markerTopMesh,
    }
  } else {
    return {
      origin: tubeInternalRef.current.markerTopMesh,
      movable: tubeInternalRef.current.markerBottomMesh,
    }
  }
}

export const getDistancePercentage = (
  frontTrackerPos: Vector3, backTrackerPos: Vector3, connectionLength: number
) => {
  const distance = frontTrackerPos.distanceTo(backTrackerPos)
  return connectionLength / convertFromMeterToInchFunction(distance)
}

export const invertTube = (
  toMarker: TubeMarkerEnum,
  tubeMeshRef: React.MutableRefObject<Mesh<BufferGeometry, Material | Material[]> | undefined>,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  scene: Scene,) => {
  const tubeMesh = tubeMeshRef.current
  const markerTop = tubeInternalsRef.current.markerTopMesh
  const markerBottom = tubeInternalsRef.current.markerBottomMesh
  if (tubeMesh && markerTop && markerBottom) {
    if (toMarker === TubeMarkerEnum.BOTTOM) {
      scene.attach(markerTop)
      markerTop.attach(tubeMesh)
      markerTop.attach(markerBottom)
      tubeMesh.position.set(0, 0, 0)
      tubeMesh.rotation.set(0, MathUtils.degToRad(180), 0)
      tubeInternalsRef.current.movableMarkerMesh = markerBottom
    } else {
      scene.attach(markerBottom)
      markerBottom.attach(tubeMesh)
      markerBottom.attach(markerTop)
      tubeMesh.position.set(0, 0, 0)
      tubeMesh.rotation.set(0, MathUtils.degToRad(180), 0)
      tubeInternalsRef.current.movableMarkerMesh = markerTop
    }
  }
}

export const handleTubeSwap = (
  tube: TubePart,
  partConnectionsValue: ConnectionOfPart[],
  connectionTypes: ObjDictionary<ConnectionTypeAPI>,
  sizes: ObjDictionary<SizeAPI>,
  getMarkerData: (partId: string, markerName: string) => Marker | undefined,
  setNewConnectionData: SetterOrUpdater<AddPartState | null>,
  scene: Scene,
  tubeRef: Mesh<BufferGeometry, Material | Material[]>
) => {
  const oldMaterial = tubeRef.material
  const makeInvisible = () => {
    tubeRef.material
      = new MeshMatcapMaterial({ alphaTest: 0, visible: false, transparent: true, })
    tubeRef.visible = false
  }
  const makeVisible = () => {
    tubeRef.visible = true
    tubeRef.material = oldMaterial
  }
  switch (partConnectionsValue.length) {
    case 1:
      if (partConnectionsValue[0]) {
        const markerA = getMarkerData(
          partConnectionsValue[0].destinationPartId,
          partConnectionsValue[0].destinationMarkerName
        )!
        setNewConnectionData({
          step1: {
            source: {
              ...getSourceData(
                partConnectionsValue[0].destinationPartId,
                partConnectionsValue[0].destinationMarkerName,
                scene
              ),
              expandReduceToFitInfo: { isApplicable: false, },
              swap: {
                partToSwapId: tube.id,
                partToSwapApiId: tube.apiTypeId,
                connectionTypesDictionary: connectionTypes,
                sizes,
                connectionATypeId: markerA.id,
                connectionASizeId: markerA.sizeId,
                actualLength: tube.length,
                makeInvisible,
                makeVisible,
              },
            },
          },
        })
      }
      break
    case 2:
      if (partConnectionsValue[0] && partConnectionsValue[1]) {
        const markerA = getMarkerData(
          partConnectionsValue[0].destinationPartId,
          partConnectionsValue[0].destinationMarkerName
        )!
        const markerB = getMarkerData(
          partConnectionsValue[1].destinationPartId,
          partConnectionsValue[1].destinationMarkerName
        )!
        setNewConnectionData({
          step1: {
            source: {
              ...getSourceData(partConnectionsValue[0].destinationPartId,
                partConnectionsValue[0].destinationMarkerName, scene),
              expandReduceToFitInfo: { isApplicable: false, },
              swap: {
                partToSwapId: tube.id,
                partToSwapApiId: tube.apiTypeId,
                connectionTypesDictionary: connectionTypes,
                sizes,
                connectionATypeId: markerA.id,
                connectionASizeId: markerA.sizeId,
                connectionBTypeId: markerB.id,
                connectionBSizeId: markerB.sizeId,
                maxLength: tube.marker.fullLeg,
                actualLength: tube.length,
                makeInvisible,
                makeVisible,
              },
            },
          },
        })
      }
      break
    default:
      notification.error({
        duration: 3,
        message: "Cant't swap. Part has no connections.",
      })
      break
  }
}

export const removeGuidelines = (
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  scene: Scene,
  setInternals: (newTubeInternals: TubeInternalsType) => void
) => {
  tubeInternalsRef.current.guidelines.forEach((line) => scene.remove(line))
  setInternals({
    ...tubeInternalsRef.current,
    guidelines: [],
  })
}

const checkExactSnapOnMarker = (
  scene: Scene,
  marker: Mesh,
  tube: TubePart,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
) => {
  const markerDirection = MeshUtils.copyWorldDirection(marker)
  const markerPosition = MeshUtils.copyWorldPosition(marker)
  const ray = new Raycaster(markerPosition, markerDirection.negate())
  const objects = ray.intersectObjects(scene.children, true)
  const intersection = objects.find(o => {
    return o.object.userData.partId !== marker.userData.partId
      && o.object.userData.type === MarkerType.COLLISION
      && roundNumber(o.distance * metersToInch, 3) >= o.object.userData.iELength - 0.05
      && roundNumber(o.distance * metersToInch, 3) <= (o.object.userData.iELength as number) + 0.05
      && isCompatibleWith(tube, o, compatibilityList)
  }
  )
  if (intersection) {
    return {
      partA: {
        partId: marker.userData.partId as string,
        markerName: marker.name,
      },
      partB: {
        partId: intersection.object.userData.partId as string,
        markerName: intersection.object.userData.markerName,
      },
    }
  }
  return undefined
}

export const checkExactSnap = (
  scene: Scene,
  tubeInternals: TubeInternalsType,
  freeMarkers: string[],
  tube: TubePart,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
) => {
  const { markerTopMesh, markerBottomMesh, } = tubeInternals
  const snaps = freeMarkers
    .map(m => {
      let marker
      if (m === TubeMarkerEnum.BOTTOM) {
        marker = markerBottomMesh!
      } else {
        marker = markerTopMesh!
      }
      return checkExactSnapOnMarker(scene, marker, tube, compatibilityList)
    })
  return filterWithValue(snaps)
}

export const checkMaxLength = (
  scene: Scene,
  length: number,
  tubeValue: TubeValues,
  tubeInternals: React.MutableRefObject<TubeInternalsType>,
  setInternals: (newTubeInternals: TubeInternalsType) => void,
  compatibilityList?: ObjDictionary<ConnectionTypeAPI>,
) => {
  if (compatibilityList) {
    proyectRay(scene, tubeInternals, setInternals)
    const { rayState, movableMarkerMesh, } = tubeInternals.current
    const filteredObjects = rayState.objects.filter((o) => {
      if (isMarkerUserData(o.object)) {
        const userData = getMarkerUserData(o.object)
        if (o.face
          && (userData.id !== tubeValue.id) && movableMarkerMesh
          && (userData.type === "COLLISION_MARKER")
          && isCompatibleWith(tubeValue, o, compatibilityList)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })

    filteredObjects.sort(sort)

    if (movableMarkerMesh) {
      const markerTopDirection = MeshUtils.copyWorldDirection(movableMarkerMesh)
      const markerTopPosition = MeshUtils.copyWorldPosition(movableMarkerMesh)

      const selectedObject = filteredObjects.filter((o) => {
        const objectDirection = MeshUtils.copyWorldDirection(o.object)
        const objectPosition = MeshUtils.copyWorldPosition(o.object)
        const areCollineal = isCollineal(
          markerTopDirection, markerTopPosition, objectDirection, objectPosition
        )
        const areSameDirection = isSameDirection(markerTopDirection, objectDirection)
        return o.object.name.includes(ConnectorMarkerType.inner)
          && areCollineal
          && !areSameDirection
      })[0]

      if (selectedObject) {
        const markerPosition = MeshUtils.copyWorldPosition(movableMarkerMesh)
        const objectPosition = MeshUtils.copyWorldPosition(selectedObject.object)
        const distanceToCompleate = markerPosition.distanceTo(objectPosition)
        const distAux = roundLength(convertFromMeterToInchFunction(distanceToCompleate))
        setInternals({
          ...tubeInternals.current,
          snapState: {
            ...tubeInternals.current.snapState,
            maxLengthSetted: true,
            targetMarker: selectedObject,
            maxPossibleLength: length + distAux,
          },
        })
      } else {
        setInternals({
          ...tubeInternals.current,
          snapState: {
            ...tubeInternals.current.snapState,
            maxLengthSetted: false,
            targetMarker: null,
            maxPossibleLength: tubeValue.maxLength,
          },
        })
      }
    }
  }
}

export const getOriginMarker = (
  tubeInternals: React.MutableRefObject<TubeInternalsType>
) => {
  return tubeInternals.current.movableMarkerMesh?.name === TubeMarkerEnum.BOTTOM
    ? tubeInternals.current.markerTopMesh!
    : tubeInternals.current.markerBottomMesh!
}