import React, { useEffect, useMemo, useRef, useState } from "react"
import { Vector3, Mesh, MeshBasicMaterial, Color, DoubleSide, PlaneGeometry } from "three"
import { useThree, useFrame } from "@react-three/fiber"
import { Text } from "@react-three/drei"
import { useDebugTools } from "../debugProvider/useDebugTools"

interface UnsnapIndicatorProps {
    isUnsnapping: boolean;
    currentPhase: number;
    position: Vector3;
    direction: Vector3 | null;
}

const UnsnapIndicator: React.FC<UnsnapIndicatorProps>
    = ({ isUnsnapping, currentPhase, position, direction, }) => {
        const backgroundRef = useRef<Mesh>(null)
        const progressRef = useRef<Mesh>(null)
        const textRef = useRef<Mesh>(null)
        const { scene, camera, } = useThree()
        const initialPositionRef = useRef<Vector3 | null>(null)
        const initialDirectionRef = useRef<Vector3 | null>(null)
        const [showUI, setShowUI,] = useState(false)
        const [displayPosition, setDisplayPosition,] = useState<Vector3 | null>(null)
        const hasCalculatedPosition = useRef(false)

        const { drawVector3Point, } = useDebugTools()

        useEffect(() => {
            if (isUnsnapping && direction && !hasCalculatedPosition.current) {
                const basePosition = position.clone()
                const distanceAlongDirection = 0.1
                const newDisplayPosition
                    = basePosition.add(direction.clone().multiplyScalar(distanceAlongDirection))
                setDisplayPosition(newDisplayPosition)
                setShowUI(true)
                hasCalculatedPosition.current = true
            } else if (!isUnsnapping) {
                setShowUI(false)
                setDisplayPosition(null)
                hasCalculatedPosition.current = false
            }
        }, [isUnsnapping, position, direction,])

        useEffect(() => {
            if (isUnsnapping && !initialPositionRef.current) {
                initialPositionRef.current = position.clone()
            } else if (!isUnsnapping) {
                initialPositionRef.current = null
            }
        }, [isUnsnapping, position,])

        useEffect(() => {
            if (isUnsnapping && !initialDirectionRef.current) {
                initialDirectionRef.current = direction ? direction.clone() : null
            } else if (!isUnsnapping) {
                initialDirectionRef.current = null
            }
        }, [isUnsnapping, direction,])

        // Create a plane geometry for background and progress
        const planeGeometry = useMemo(() => new PlaneGeometry(1, 0.1), [])

        const backgroundMaterial = useMemo(() => {
            return new MeshBasicMaterial({
                color: new Color(0x333333),
                transparent: true,
                opacity: 0.8,
                side: DoubleSide,
            })
        }, [])

        const progressMaterial = useMemo(() => {
            return new MeshBasicMaterial({
                color: new Color(0x2abfee),  // RGB: 42, 191, 238
                transparent: true,
                opacity: 1,
                side: DoubleSide,
            })
        }, [])

        const baseScale = 0.3
        const scale = isUnsnapping ? baseScale : 0

        useFrame(() => {
            if (backgroundRef.current
                && progressRef.current
                && textRef.current
                && displayPosition
            ) {
                // Update positions
                backgroundRef.current.position.copy(displayPosition)
                progressRef.current.position.copy(displayPosition)

                // Update scales
                backgroundRef.current.scale.set(baseScale, baseScale, 1)
                progressRef.current.scale.set(baseScale * (currentPhase / 10) * 2.5, baseScale, 1)

                // Make UI face the camera
                backgroundRef.current.lookAt(camera.position)
                progressRef.current.lookAt(camera.position)

                // Update text position and rotation
                textRef.current.position.set(
                    displayPosition.x,
                    displayPosition.y + baseScale * 0.05,
                    displayPosition.z
                )
                textRef.current.lookAt(camera.position)
            }
        })

        return (
            <>
                {showUI && displayPosition && (
                    <>
                        <mesh ref={backgroundRef} renderOrder={999}>
                            <planeGeometry args={[0.3, 0.01,]} />
                            <meshBasicMaterial {...backgroundMaterial} depthTest={false} />
                        </mesh>
                        <mesh ref={progressRef} renderOrder={999}>
                            <planeGeometry args={[0.3, 0.01,]} />
                            <meshBasicMaterial {...progressMaterial} depthTest={false} />
                        </mesh>
                        <Text
                            ref={textRef}
                            color="black"
                            anchorX="center"
                            anchorY="middle"
                            fontSize={baseScale * 0.05}
                            renderOrder={100}
                            material-depthTest={false}
                            material-depthWrite={false}
                        >
                            Pull to unsnap
                        </Text>
                    </>
                )}
            </>
        )
    }

export default UnsnapIndicator