import { Box3Helper, Color, Vector3 } from "three"
import { Box3 } from "three"

export const showDimensions = (box: Box3, hide:boolean,
    unit:string, setSize:any) => {
    if(hide === false) {
        // Calculate dimensions
        const sizeVector = new Vector3()
        box.getSize(sizeVector)

        if(sizeVector.x > 0) {
        // Get the current unit
            let width, height, depth, unitLabel

            if (unit === "in") {
                width = sizeVector.x * 39.3701
                height = sizeVector.y * 39.3701
                depth = sizeVector.z * 39.3701
                unitLabel = '"'
                // Convert dimensions to string with unit label after each dimension
                const sizeString = `${width.toFixed(2)}${unitLabel} x
                 ${height.toFixed(2)}${unitLabel} x ${depth.toFixed(2)}${unitLabel}`
                setSize({
                    readout: sizeString,
                    width: width,
                    height: height,
                    depth: depth,
                })
            } else {
                width = sizeVector.x * 100
                height = sizeVector.y * 100
                depth = sizeVector.z * 100
                // Convert dimensions to string with unit label only at the end
                const sizeString = `${width.toFixed(2)} x ${height.toFixed(2)} x
                 ${depth.toFixed(2)} cm`
                 setSize({
                    readout: sizeString,
                    width: width,
                    height: height,
                    depth: depth,
                })
            }
    }
    }
    else {
        setSize({readout: "",})
    }
}