import { Quaternion, Vector3 } from "three"
import { getDragMeshForEnds, getPointsArrayForEnds } from "./meshBuilderEnds"
import { getDragMeshForMiddle, getPointsArrayForMiddle } from "./meshBuilderMiddle"

enum MeshStrategy {
    Ends = "ends",
    Middle = "middle",
}

function getMeshStrategy(sides: any): MeshStrategy {
    const firstAndLastSides = getFirstAndLastSides(sides)
    if (sides[firstAndLastSides[0]].hasOwnProperty("mesh")) {
        return MeshStrategy.Ends
    }
    return MeshStrategy.Middle
}

function getFirstAndLastSides(sides: any) {
    const keys = Object.keys(sides).map(Number)
        .sort((a, b) => a - b)
    const firstKey = keys[0]
    const lastKey = keys[keys.length - 1]

    return [firstKey, lastKey,]
}

function getMarkerQuaternion(sides: any) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const marker = firstAndLastSides.map(key => sides[key].outer)[0]
    return marker.getWorldQuaternion(new Quaternion())
}

function getPointsArray(sides: any, segmentLength = 0.025, extend = false, pieceLength?: number) {
    const strategy = getMeshStrategy(sides)
    const getPointsArrayFunction
        = strategy === MeshStrategy.Ends ? getPointsArrayForEnds : getPointsArrayForMiddle
    return getPointsArrayFunction(sides, segmentLength, extend, pieceLength)
}

function getDragMesh(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    const strategy = getMeshStrategy(sides)
    if (strategy === MeshStrategy.Ends) {
        return { strategy, ...getDragMeshForEnds(sides, viewSurfaceMesh, viewDragMesh), }
    }
    return {
        strategy, ...getDragMeshForMiddle(sides, viewSurfaceMesh, viewDragMesh),
    }
}

export {
    getDragMesh,
    getPointsArray,
    getFirstAndLastSides,
    MeshStrategy,
    getMarkerQuaternion,
}