/* eslint-disable max-lines-per-function */
import React, { MutableRefObject, useEffect, useRef, useState, } from "react"
import { Html } from "@react-three/drei"
import { ConnectorInternalsType, CONNECTOR_UI } from "../types/types"
import { AnimatePresence } from "framer-motion"
import ReactDOM from "react-dom"
import {
    Box3, BufferGeometry, InstancedMesh, Material, MathUtils,
    Mesh, Object3D, Vector3
} from "three"
import {
    ActionsContainer,
} from "../../ActionPanelStyles"
import {
    useMultipleMovement
} from "../../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { ConnectorValues } from "../../../../../../../../utils/Types"
import { radToDeg } from "three/src/math/MathUtils"
import useHandleRotation from "../utils/useHandleRotation"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import { useSlide } from "../../../../../../../../providers/slideProvider/useSlide"
import SegmentedSlider from "../../SegmentedSlider"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import { EnvHelper } from "../../../../../../../../../common/utils/EnvHelper"
import useHandleSlide from "../utils/useHandleSlide"
import { getInitialMarker } from "../utils/ConnectorUtils"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { useThree } from "@react-three/fiber"
import { MultipleMovementUtils } from "../utils/MultipleMovementUtils"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { useRecoilState, useRecoilValue } from "recoil"
import RotationSlider from "../../sliders/RotationSlider"
import ActionsUI from "./ActionsUI"
import CancelUI from "./CancelUI"
import useUnitConversion from "../../../../../utils/UnitUtils"
import type { UnitType } from "../../../../../../../../state/scene/types"
import DragSlide from "../../../../../../../../providers/moveProvider/DragSlider"
import { DragPoint, FreePositions } from "../../../../../../../../providers/moveProvider/types"
import { connectionIndexSelector } from "../../../../../../../../state/scene/selectors"
import { useUnsnap } from "../../../../../../../../state/scene/setters"
import { ReactNode, ReactPortal } from "react"
import hotkeys from "hotkeys-js"

type RotationProps = {
    handleRotation: (value: number, rotationMarker?: Mesh) => void,
    handleRotationStart: () => void,
}

type SwapProps = {
    showSwap: false,
} | {
    showSwap: true,
    handleConnectorSwap: () => void,
}

type Props = {
    connector: ConnectorValues,
    connectorInternalsRef: MutableRefObject<ConnectorInternalsType>,
    attachedMarker: MutableRefObject<Mesh | undefined>,
    connectorUI: CONNECTOR_UI,
    setConnectorUI: (ui: CONNECTOR_UI) => void,
    handleConnectorDelete: () => void,
    handleConnectorState: () => void,
    handleMouseUp: () => void,
    attachEverythingTo: (marker: Mesh) => void,
    detachEverythingFrom: (marker: Mesh) => void,
    buildCollider: () => void,
    updateCollider: (removePart?: (() => void), newConnectedParts?: string[]) => void,
    checkExactSnap: () => PartConnectionType[],
    updateTransforms: () => void,
    instancedMesh: InstancedMesh<BufferGeometry, Material | Material[]> | undefined,
    onUserRotationApplied: () => void,
    setIsSelected: (isSelected: boolean) => void,
    setIdsAsHighlightedAndTurnOnControl: (ids: string[],
        control: "selection" | "translate" | "rotate") => void,
    boundingBox: Box3 | null,
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void,
    transformMode: string,
} & RotationProps & SwapProps


const action_variants = {
    "visible": {
        y: "0%",
    },
    "hidden": {
        y: "130%",
    },
}

const transition = {
    duration: 0.5,
    ease: [0.54, 0.01, 0.61, 1,],
}


const ConnectorUI = ({ connector, instancedMesh, ...props }: Props) => {
    const {
        canEditRotation,
        canEditSlide,
        attachToMove,
        detachMarkers,
        handleRotationMovement,
        handleSlideMovement,
        saveRotationChanges,
        saveSegmentedTubeSliderChanges,
    } = useMultipleMovement(connector.id)
    const actions_child = document.getElementById("actions-container")!
    const { scene, } = useThree()
    const connections = useRecoilValue(allConnections)
    const markerMeshesWithoutMiddleSegments = useRef<Object3D[]>([])
    const { unit, updateUnit, } = useUnitConversion()
    const [connectionIndex, setConnectionIndex,]
        = useRecoilState(connectionIndexSelector(connector.id,))
    const slide = useSlide(connector.id, connectionIndex)
    const canSlide = slide.canSlide()
    const [slidePartId, setSlidePartId,] = useState(canSlide.sliderPartId)
    const unsnap = useUnsnap()


    const getSlider = () => {
        if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER) {
            return (
                <SegmentedSlider
                    onMouseUp={handleSegmentedMouseUp}
                    onNewPosition={handleNewSegmentPosition}
                    handleFinishEditing={handleSegmentedFinishEditing}
                    startLength={canSlide.sliderStartLength!}
                    segmentLength={canSlide.sliderSectionLength!}
                    {...slide.getPositionsToSlide(canSlide.markerName!)}
                    unit={unit}
                    updateUnit={(value: string) => updateUnit(value as UnitType)}
                    useDragSlide={true}
                    connectedToSegmentedParts={canSlide.connectedToSegmentedParts}
                    connectionIndex={connectionIndex}
                    setConnectionIndex={setConnectionIndex}
                />)
        }
        if (props.connectorUI === CONNECTOR_UI.SLIDER
            && !!multipleMove?.partsToMove && !connector.rotationDisabled) {
            return <RotationSlider
                onFinishEditing={removeModal}
                handleMouseUp={props.handleMouseUp}
                handleMouseDown={props.handleRotationStart}
                onRotationChange={handleConnectorRotation}
                onRotationSave={handleSaveRotation}
                getRotation={getRotation}
                steps={connector.rotationSteps || EnvHelper.rotationStep}
            />
        }

        return <CancelUI onFinishEditing={() => props.setConnectorUI(CONNECTOR_UI.CLOSE)} />
    }

    const onPositionChange = (value: FreePositions) => {
        handleSlideMovementAndUpdatePosition(value.position)
    }

    const onUnsnap = (value: FreePositions) => {
        handleSlideMovementAndUpdatePosition(value.position)
        props.setConnectorUI(CONNECTOR_UI.NONE)
        unsnap(connector.id, [canSlide.markerName!,])
        setTimeout(() => {
            props.setIdsAsHighlightedAndTurnOnControl([connector.id,], "translate")
            // props.setConnectorUI(CONN ECTOR_UI.SLIDER)
        }, 350)
    }

    const onDragEnd = (point: DragPoint) => {
        const meshNameParts = point.meshName.split("_")
        if (meshNameParts[1]) {
            const numericPart = meshNameParts[1].replace(/[^-\d]/g, "")
            const number = Number(numericPart)
            handleSegmentedMouseUp(number)
        } else {
            handleSegmentedMouseUp(null)
        }
    }

    const { initialRotation,
        actualRotation,
        updateActualRotation,
        multipleMove,
        resetMultipleMove,
        setNewChangesToSave,
        setUserRotation,
        isInitialRotationSet,
    } = useHandleRotation({
        connector: connector,
        connectorUI: props.connectorUI,
        attachedMarker: props.attachedMarker,
        connectorInternalsRef: props.connectorInternalsRef,
        attachEverythingTo: props.attachEverythingTo,
        detachEverythingFrom: props.detachEverythingFrom,
        buildCollider: props.buildCollider,
        updateCollider: props.updateCollider,
        canEditRotation,
        saveRotationChanges,
        handleConnectorState: props.handleConnectorState,
        checkExactSnap: props.checkExactSnap,
        setConnectorUI: props.setConnectorUI,
        slideEnabled: canSlide.slideEnabled,
        attachToMove,
        detachMarkers,
    })


    const handleSlide = useHandleSlide({
        connector: connector,
        connectorUI: props.connectorUI,
        attachedMarker: props.attachedMarker,
        connectorInternalsRef: props.connectorInternalsRef,
        attachEverythingTo: props.attachEverythingTo,
        detachEverythingFrom: props.detachEverythingFrom,
        buildCollider: props.buildCollider,
        updateCollider: props.updateCollider,
        savePositionRotationChanges: saveRotationChanges,
        handleConnectorState: props.handleConnectorState,
        checkExactSnap: props.checkExactSnap,
        setConnectorUI: props.setConnectorUI,
        canEditSlide,
        saveSegmentedTubeSliderChanges,
        sliderPartId: slidePartId,
        attachToMove,
        detachMarkers,
        connectionIndex,
    })

    const handleSlideRef = useRef(handleSlide)

    useEffect(() => {
        handleSlideRef.current = handleSlide
    }, [handleSlide,])

    const removeModal = () => {
        props.setConnectorUI(CONNECTOR_UI.CLOSE)
    }

    useEffect(() => {
        setSlidePartId(canSlide.sliderPartId)
    }, [canSlide.sliderPartId, connectionIndex,])

    useEffect(() => {
        hotkeys("delete,backspace", (event, handler) => {
          event.preventDefault()
          props.handleConnectorDelete()
        })

        // Cleanup function
        return () => {
          hotkeys.unbind("delete,backspace")
        }
      }, [])


    const handleConnectorRotation = (value: number) => {
        if (multipleMove?.partsToMove && multipleMove.partsToMove.length > 0) {
            attachToMove(multipleMove.partsToMove, props.attachedMarker.current!)
            props.handleRotation(value + radToDeg(initialRotation),
                props.attachedMarker.current!)
            handleRotationMovement(
                multipleMove?.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
            detachMarkers(multipleMove!.partsToMove, props.attachedMarker.current!)
        } else {
            props.handleRotation(value + radToDeg(initialRotation))
        }
        updateActualRotation(value)
    }

    const handleSaveRotation = (value: number) => {
        if (multipleMove?.partsToMove && multipleMove.partsToMove.length > 0) {
            attachToMove(multipleMove.partsToMove, props.attachedMarker.current!)
            const newConnections
                = detachMarkers(
                    multipleMove!.partsToMove,
                    props.attachedMarker.current!,
                    true,
                    true,
                    false
                )
            setNewChangesToSave(newConnections)
            props.updateCollider()
        }
        updateActualRotation(value)
    }

    const handleSwap = () => {
        if (props.showSwap) {
            breadcrumb({
                message: "Click on swap",
                level: "info",
                data: {
                    partId: connector.id,
                    partName: connector.name,
                },
            })
            resetMultipleMove()
            props.handleConnectorSwap()
        }
    }

    const handleDelete = () => {
        resetMultipleMove()
        props.handleConnectorDelete()
    }

    const getRotation = () => {
        return actualRotation.rotation
    }

    const handleSlideMovementAndUpdatePosition = (newWorldPosition: Vector3) => {
        if (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0) {
            attachToMove(handleSlideRef.current.multipleMove.partsToMove,
                props.attachedMarker.current!)
            props.attachedMarker.current!.position.copy(newWorldPosition)
            props.updateTransforms()
            handleSlideMovement(
                handleSlideRef.current.multipleMove.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
            detachMarkers(handleSlideRef.current.multipleMove!.partsToMove,
                props.attachedMarker.current!)
        } else {
            props.attachedMarker.current!.position.copy(newWorldPosition)
            props.updateTransforms()
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef: props.connectorInternalsRef,
                connections,
                setInternals: (newConnectorInternals: ConnectorInternalsType) => {
                    props.connectorInternalsRef.current = newConnectorInternals
                },
                intersectableMeshes: markerMeshesWithoutMiddleSegments.current,
            })
        }
    }

    const handleNewSegmentPosition = (newPosition: number) => {
        const newWorldPosition
            = slide.getNewWorldPosition(
                newPosition,
                canSlide.sliderPartId!,
                canSlide.sliderMarkerName!
            )

        handleSlideMovementAndUpdatePosition(newWorldPosition)
    }

    const handleSegmentedFinishEditing = () => {
        if (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove.partsToMove.length > 0) {
            attachToMove(handleSlideRef.current.multipleMove.partsToMove,
                props.attachedMarker.current!)
            const newConnections = detachMarkers(
                handleSlideRef.current.multipleMove!.partsToMove,
                props.attachedMarker.current!,
                true,
                false,
                true
            )
            handleSlideRef.current.setNewChangesToSave(newConnections)
        }
        props.setConnectorUI(CONNECTOR_UI.CLOSE)
    }

    const handleSegmentedMouseUp = (value: number | null) => {
        if (canSlide.slideEnabled) {
            const initialMarker = getInitialMarker(
                connector, props.connectorInternalsRef.current.markers)
            const newWorldPosition = MeshUtils.copyWorldPosition(initialMarker)
            initialMarker.rotateY(MathUtils.degToRad(-180))
            const newWorldRotation = MeshUtils.copyWorldQuaternion(initialMarker)
            initialMarker.rotateY(MathUtils.degToRad(180))
            slide.saveNewPosition(canSlide.sliderPartId!, value, newWorldPosition, newWorldRotation)

            if (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove.partsToMove.length > 0) {
                handleSlideRef.current.setNewChangesToSave([])
            }
        }
    }

    useEffect(() => {
        if (props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER
            || props.connectorUI === CONNECTOR_UI.SLIDER) {
            markerMeshesWithoutMiddleSegments.current
                = MeshUtils.getMarkerMeshesWithoutMiddleSegments(scene)
        }
    }, [props.connectorUI, connectionIndex,])

    useEffect(() => {
        if (props.connectorUI === CONNECTOR_UI.ACTIONS) {

            if (!!slideEnabled) {
                props.setConnectorUI(CONNECTOR_UI.SEGMENTED_SLIDER)
            } else if (!!multipleMove?.partsToMove) {
                props.setConnectorUI(CONNECTOR_UI.SLIDER)
            }
        }
    }, [props.connectorUI, connectionIndex,])

    useEffect(() => {
        if (connector.userRotation && isInitialRotationSet) {
            setUserRotation(connector.userRotation)
            handleConnectorRotation(connector.userRotation)
            handleSaveRotation(connector.userRotation)
            props.onUserRotationApplied()
        }
    }, [isInitialRotationSet,])

    let slideEnabled = canSlide.slideEnabled
        && (handleSlideRef.current.multipleMove?.partsToMove
            && handleSlideRef.current.multipleMove?.partsToMove.length
            <= EnvHelper.maxSegmentedTubeSlideSteps)

    useEffect(() => {
        slideEnabled = canSlide.slideEnabled
            && (handleSlideRef.current.multipleMove?.partsToMove
                && handleSlideRef.current.multipleMove?.partsToMove.length
                <= EnvHelper.maxSegmentedTubeSlideSteps)
    }, [handleSlideRef.current.multipleMove?.partsToMove,])

    return (
        <>
            {
                slideEnabled && props.connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER
                && <DragSlide
                    mesh={[instancedMesh!,]}
                    onPositionChange={onPositionChange}
                    onDragEnd={onDragEnd}
                    slideSide={slide.getSlideSide(canSlide.markerName!)}
                    segmentLength={canSlide.sliderSectionLength!}
                    draggableObjectCenter={props.attachedMarker.current!}
                    startLength={canSlide.sliderStartLength!}
                    endLength={canSlide.sliderEndLength!}
                    boundingBox={props.boundingBox}
                    onUnsnap={onUnsnap}
                />
            }
            <Html wrapperClass={"neutralHTML"}>
                {
                    ReactDOM.createPortal(
                        <AnimatePresence>
                            {
                                (props.connectorUI !== CONNECTOR_UI.NONE
                                    && props.connectorUI !== CONNECTOR_UI.CLOSE)
                                && <ActionsContainer
                                    transition={transition}
                                    variants={action_variants}
                                    initial={"hidden"}
                                    animate={"visible"}
                                    exit={"hidden"}
                                    $partUI={props.connectorUI}                                >
                                    <ActionsUI
                                        connectorUI={props.connectorUI}
                                        canSlide={!!slideEnabled}
                                        onRemove={handleDelete}
                                        setConnectorUI={props.setConnectorUI}
                                        canRotate={!!multipleMove?.partsToMove
                                            && !connector.rotationDisabled}
                                        canSwap={props.showSwap && canSlide.canSwap}
                                        onSwap={handleSwap}
                                        onDuplicate={props.onDuplicate}
                                        partId={connector.id}
                                        setIdsAsHighlightedAndTurnOnControl
                                        ={props.setIdsAsHighlightedAndTurnOnControl}
                                        setIsSelected={props.setIsSelected}
                                        transformMode={props.transformMode}
                                    />
                                    {getSlider()}
                                </ActionsContainer>
                            }
                        </AnimatePresence>,
                        actions_child
                    ) as ReactPortal
                }
            </Html>
        </>
    )
}

export default ConnectorUI

