/* eslint-disable array-callback-return */
/* eslint-disable max-statements */
import { useContext, useRef } from "react"
import {
    Object3D,
    Mesh, Scene,
    MeshMatcapMaterial,
    Color,
    InstancedMesh,
    DynamicDrawUsage,
} from "three"
import {
    InstancedMeshSegmentedTubesContext,
    InstancedMeshPool
} from "./InstancedMeshSegmentedTubesProvider"
import { SectionType }
    from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import useMoonTexture from
    "../../components/main/DesignScreen/scene/part/parts/hooks/useMoonTexture"

export type UseInstancedMeshSegmentedTubeExport = {
    getPool: () => InstancedMeshPool[],
    createPool: (
        id: string,
        name: string,
        mesh: Mesh,
        scene: Scene,
        selectionCallback?: () => void) => void,
    updateInstancedMeshesTranforms: (origins: Object3D[], skipBoundingSphere?: boolean) => void,
    updateColor: (origins: Object3D[], color: string) => void,
}

/* eslint-disable max-lines-per-function */
const useInstancedMeshSegmentedTubes = () => {
    const instancedMeshesPool = useContext(InstancedMeshSegmentedTubesContext)
    const lastOrigins = useRef<Object3D[]>([])
    const meshName = useRef("")
    const partId = useRef("")
    const colorRef = useRef("white")
    const { moonTexture, } = useMoonTexture(8, 8)
    const getPool = () => {
        if (!instancedMeshesPool) {
            throw new Error("no origins")
        }
        return instancedMeshesPool.current
    }

    const updateIndexes = (skipBoundingSphere = false) => {
        if (!lastOrigins.current || !instancedMeshesPool) {
            throw new Error("no origins")
        }

        const poolExists = instancedMeshesPool.current.find(
            (p) => p.name === meshName.current
        )

        if (poolExists) {
            const iMesh = poolExists.mesh

            let baseIndex = 0
            const instanceIndex = poolExists.instances.findIndex(
                (i) => i.id === partId.current
            )

            poolExists.instances.forEach((i, index) => {
                if (index < instanceIndex) {
                    baseIndex = baseIndex + i.count
                }
            })

            lastOrigins.current.forEach((o, index) => {
                o.updateWorldMatrix(true, true)
                iMesh.setMatrixAt(index + baseIndex, o.matrixWorld)
                iMesh.setColorAt(index + baseIndex, new Color(colorRef.current))
                iMesh.instanceMatrix.needsUpdate = true
                iMesh.instanceColor!.needsUpdate = true
                if (!skipBoundingSphere) {
                    iMesh.computeBoundingSphere()
                }
            })
        }
    }

    const createPool = (
        id: string,
        name: string,
        mesh: Mesh,
        scene: Scene,
        selectionCallback?: () => void,
    ) => {
        if (!instancedMeshesPool) {
            throw new Error("pool undefined")
        }

        const poolExists = instancedMeshesPool.current.find((p) => p.name === name)

        if (poolExists) {
            partId.current = id
            meshName.current = name
            poolExists.instances.push({
                id,
                count: 0,
                clickCallback: selectionCallback,
                updateIndexes,
            })
        } else {
            const matcapMaterial = new MeshMatcapMaterial({
                color: "white",
                matcap: moonTexture,
            })
            // const matcapMaterial = new MeshMatcapMaterial({
            //     color: "white",
            //     alphaToCoverage: true,
            // })
            const iMesh = new InstancedMesh(mesh.geometry, matcapMaterial, 50)
            iMesh.userData = { type: "InstancedMesh", partType: "SECTIONED_TUBE", }
            iMesh.count = 1
            iMesh.name = name
            iMesh.instanceMatrix.setUsage(DynamicDrawUsage)
            scene.add(iMesh)


            partId.current = id
            meshName.current = name

            instancedMeshesPool.current.push({
                name,
                initialCount: 50,
                mesh: iMesh,
                instances: [
                    {
                        id,
                        count: 0,
                        clickCallback: selectionCallback,
                        updateIndexes,
                    },
                ],
            })
        }
    }

    const createNewPoolWithDoubleInstances = (pool: InstancedMeshPool,
        skipBoundingSphere?: boolean) => {
        // This function cleans the instancedMesh and creates a new one with double space
        const iMesh = pool.mesh
        const geometry = iMesh.geometry
        const material = iMesh.material
        const count = pool.initialCount + 100
        iMesh.count = 0
        iMesh.dispose()
        const scene = iMesh.parent
        iMesh.removeFromParent()
        const newIMesh = new InstancedMesh(geometry, material, count)
        newIMesh.userData = { type: "InstancedMesh", partType: "SECTIONED_TUBE", }
        newIMesh.name = meshName.current
        newIMesh.instanceMatrix.setUsage(DynamicDrawUsage)
        scene?.add(newIMesh)
        pool.initialCount = count
        pool.mesh = newIMesh
        pool.instances.forEach((i) => {
            i.updateIndexes(skipBoundingSphere)
        })
    }

    const updateInstancedMeshesTranforms = (origins: Object3D[], skipBoundingSphere = false) => {
        lastOrigins.current = origins
        if (!instancedMeshesPool) {
            throw new Error("pool undefined")
        }

        const poolExists = instancedMeshesPool.current.find(
            (p) => p.name === meshName.current
        )

        let totalInstancesUsed = 0
        poolExists?.instances.forEach((i) => {
            if (i.id !== partId.current) {
                totalInstancesUsed = totalInstancesUsed + i.count
            }
        })

        if (poolExists) {
            if (origins.length + totalInstancesUsed > poolExists?.initialCount) {
                createNewPoolWithDoubleInstances(poolExists, skipBoundingSphere)
                updateInstancedMeshesTranforms(origins, skipBoundingSphere)
            }

            const instance = poolExists.instances.find(
                (i) => i.id === partId.current
            )
            if (instance) { instance.count = origins.length }

            const instanceIndex = poolExists.instances.findIndex(
                (i) => i.id === partId.current
            )

            const filteredInstances = poolExists.instances.filter(
                (i) => i.id !== partId.current
            )

            if (instanceIndex !== poolExists.instances.length - 1) {
                const element = poolExists.instances.splice(instanceIndex, 1)[0]
                poolExists.instances.push(element)
                filteredInstances.forEach((i) => i.updateIndexes(skipBoundingSphere))
            }

            let baseIndex = 0
            filteredInstances.forEach((i) => (baseIndex = baseIndex + i.count))

            const iMesh = poolExists.mesh
            iMesh.count = origins.length + totalInstancesUsed
            origins.forEach((o, index) => {
                o.updateWorldMatrix(true, true)
                iMesh.setMatrixAt(index + baseIndex, o.matrixWorld)
                iMesh.setColorAt(index + baseIndex, new Color(colorRef.current))
                iMesh.instanceMatrix.needsUpdate = true
                iMesh.instanceColor!.needsUpdate = true
                if (!skipBoundingSphere) {
                    iMesh.computeBoundingSphere()
                }
            })
        }
    }

    const getSelectedInstance = (meshName: string, instanceId: number) => {
        const pool = getPool()
        const meshInstance = pool.find((mI) => mI.name === meshName)
        if (meshInstance) {
            const instances = meshInstance.instances
            let acum = 0
            const selectedInstance = instances.find((i, index) => {
                if (instanceId < i.count + acum) {
                    return i
                }
                acum = acum + i.count
            })
            return selectedInstance
        }
    }

    const handleTubeSelection = (meshName: string, instanceId: number) => {
        const selectedInstance = getSelectedInstance(meshName, instanceId)
        if (selectedInstance && selectedInstance.clickCallback) {
            selectedInstance.clickCallback()
        }
    }

    const getTubeId = (meshName: string, instanceId: number) => {
        const selectedInstance = getSelectedInstance(meshName, instanceId)
        if (selectedInstance) {
            return selectedInstance.id
        }
    }

    const updateColor = (origins: Object3D[], color: string) => {
        colorRef.current = color
        updateInstancedMeshesTranforms(origins, true)
    }

    const deleteInstancedMesh = () => {
        updateInstancedMeshesTranforms([])

        if (!instancedMeshesPool) {
            throw new Error("pool undefined")
        }

        const poolExists = instancedMeshesPool.current.find(
            (p) => p.name === meshName.current
        )

        if (poolExists) {
            poolExists.instances = poolExists.instances.filter((i) => i.id !== partId.current)

            if (poolExists.instances.length === 0 && meshName.current) {
                instancedMeshesPool.current = instancedMeshesPool
                    .current.filter((i) => i.name !== meshName.current)

                poolExists.mesh.count = 0
                poolExists.mesh.removeFromParent()
                poolExists.mesh.geometry.dispose()
            }
        }

    }

    const getMiddleMesh = (tubeName: string) => {
        const pool = getPool()
        const middleMeshName = `${tubeName}_${SectionType.MIDDLE}`
        const meshInstance = pool.find((mI) => mI.name === middleMeshName)
        if (meshInstance) {
            return meshInstance.mesh
        }
    }

    const getStartMesh = (tubeName: string) => {
        const pool = getPool()
        const startMeshName = `${tubeName}_${SectionType.START}`
        const meshInstance = pool.find((mI) => mI.name === startMeshName)
        if (meshInstance) {
            return meshInstance.mesh
        }
    }

    const getEndMesh = (tubeName: string) => {
        const pool = getPool()
        const endMeshName = `${tubeName}_${SectionType.END}`
        const meshInstance = pool.find((mI) => mI.name === endMeshName)
        if (meshInstance) {
            return meshInstance.mesh
        }
    }

    return {
        handleTubeSelection,
        getPool,
        createPool,
        updateInstancedMeshesTranforms,
        updateColor,
        deleteInstancedMesh,
        getTubeId,
        getMiddleMesh,
        getStartMesh,
        getEndMesh,
    }
}

export default useInstancedMeshSegmentedTubes