/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import CameraControls from "camera-controls"
import { useContext, useEffect, useState } from "react"
import { AxesHelper, Box3, Box3Helper, BoxHelper, BufferAttribute, BufferGeometry, CameraHelper, Color, DoubleSide, Float32BufferAttribute, Line, Line3, LineBasicMaterial, LineSegments, MathUtils, Matrix4, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, Quaternion, Raycaster, Scene, Sphere, SphereGeometry, Triangle, Vector3 } from "three"
import { cameraContext } from "./CameraProvider"
import { EnvHelper } from "../../../common/utils/EnvHelper"
import { CAMERA_ACTIONS } from "./CameraControls"
import { partsIdsSelector } from "../../state/scene/atoms"
import { useRecoilValue, useRecoilState } from "recoil"
import { showCameraControlsState } from "../../state/scene/atoms"
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry"
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial"
import { Line2 } from "three/examples/jsm/lines/Line2"
import { boundingBoxAtom } from "../../state/scene/atoms"

const useCamera = () => {
    const context = useContext(cameraContext)
    const [downPolarRotation, setDownPolarRotation,] = useState(true,)
    const partIdsList = useRecoilValue(partsIdsSelector)
    const [showCameraControls, setShowCameraControls,] = useRecoilState(showCameraControlsState)
    const [boundingBox, setBoundingBox,] = useRecoilState(boundingBoxAtom)


    useEffect(() => {
        if (partIdsList.length > 0) {
            setShowCameraControls(true)
        } else {
            setShowCameraControls(false)
        }
    }, [partIdsList,])

    const horizontalRotation45Deg = (cameraControls: CameraControls) => {
        cameraControls.rotate(MathUtils.degToRad(45), 0, true)
        // cameraControls.rotate(MathUtils.degToRad(45.01), MathUtils.degToRad(0.1), true)
        // cameraControls.camera.updateProjectionMatrix()
    }

    const verticalRotation45Deg = (cameraControls: CameraControls) => {
        const cameraPolarAngle = MathUtils.radToDeg(cameraControls.polarAngle)
        let newDirectionAngle = downPolarRotation

        if (cameraPolarAngle <= 30) {
            setDownPolarRotation(true)
            newDirectionAngle = true
        }
        else if ((cameraPolarAngle >= 170)) {
            setDownPolarRotation(false)
            newDirectionAngle = false
        }
        const polarDirection = newDirectionAngle ? 1 : -1
        cameraControls.rotate(0, polarDirection * MathUtils.degToRad(45), true)
        // cameraControls.rotate(MathUtils.degToRad(0.1), polarDirection * MathUtils.degToRad(45.01), true)
        // cameraControls.camera.updateProjectionMatrix()
    }

    const fit = (mesh: Mesh | Object3D, matrixWorld?: Matrix4, cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        const box = new Box3()
        if (mesh.type === "Mesh" && matrixWorld) {
            (mesh as Mesh).geometry.computeBoundingBox()
            box
                .copy((mesh as Mesh).geometry.boundingBox!)
                .applyMatrix4(matrixWorld)
        } else {
            box.setFromObject(mesh)
        }
        const defaultZoom = EnvHelper.isBrickdesigner ? 0.02 : 0.1
        box.expandByScalar(defaultZoom)
        const sp = new Sphere()
        box.getBoundingSphere(sp)
        cam?.fitToSphere(sp, true)
    }

    const getBoundingBoxForConnector = (mesh: Mesh | Object3D, matrixWorld?: Matrix4, cameraControls?: CameraControls) => {
        const box = new Box3()
        if (mesh.type === "Mesh" && matrixWorld) {
            (mesh as Mesh).geometry.computeBoundingBox()
            box
                .copy((mesh as Mesh).geometry.boundingBox!)
                .applyMatrix4(matrixWorld)
        } else {
            box.setFromObject(mesh)
        }
        return box
    }

    function drawVector3Point(vector3Point: Vector3, scene: Scene, color: number | string = 0xffff00, size = 0.002, duration?: number) {
        const sphereGeometry = new SphereGeometry(size, 32, 32)
        sphereGeometry.translate(vector3Point.x, vector3Point.y, vector3Point.z)
        const sphereMaterial = new MeshBasicMaterial({ color, })
        sphereMaterial.depthTest = false
        const sphere = new Mesh(sphereGeometry, sphereMaterial)
        sphere.name = "helper"
        scene.add(sphere)
        if (duration) {
            setTimeout(() => {
                scene.remove(sphere)
            }, duration)
        }
    }

    const calculateNewBoxSize = (boxSize: Vector3, longestAxis: number, scaleFactor: number) => {
        const newBoxSize = new Vector3(boxSize.x, boxSize.y, boxSize.z)
        if (boxSize.x === longestAxis) {
            newBoxSize.x *= scaleFactor
        } else if (boxSize.y === longestAxis) {
            newBoxSize.y *= scaleFactor
        } else if (boxSize.z === longestAxis) {
            newBoxSize.z *= scaleFactor
        }
        return newBoxSize
    }

    const generateBoxPoints = (newBoxMin: Vector3, newBoxMax: Vector3) => {
        return [
            new Vector3(newBoxMin.x, newBoxMin.y, newBoxMin.z),
            new Vector3(newBoxMin.x, newBoxMin.y, newBoxMax.z),
            new Vector3(newBoxMin.x, newBoxMax.y, newBoxMin.z),
            new Vector3(newBoxMin.x, newBoxMax.y, newBoxMax.z),
            new Vector3(newBoxMax.x, newBoxMin.y, newBoxMin.z),
            new Vector3(newBoxMax.x, newBoxMin.y, newBoxMax.z),
            new Vector3(newBoxMax.x, newBoxMax.y, newBoxMin.z),
            new Vector3(newBoxMax.x, newBoxMax.y, newBoxMax.z),
        ]
    }

    const drawTriangle = (scene: Scene, point1: Vector3, point2: Vector3, point3: Vector3, addToScene?: boolean) => {
        const triangle = new Triangle(point1, point2, point3)
        const vertices = new Float32Array([
            triangle.a.x, triangle.a.y, triangle.a.z,
            triangle.b.x, triangle.b.y, triangle.b.z,
            triangle.b.x, triangle.b.y, triangle.b.z,
            triangle.c.x, triangle.c.y, triangle.c.z,
            triangle.c.x, triangle.c.y, triangle.c.z,
            triangle.a.x, triangle.a.y, triangle.a.z,
        ])

        const geometry = new BufferGeometry()
        geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3))

        const material = new LineBasicMaterial({ color: 0x964B00, })
        const lineSegments = new LineSegments(geometry, material,)
        if (addToScene) {
            scene.add(lineSegments)
        }
        return { lineSegments, triangle, }
    }
    const fitAndLookAtBox = (box: Box3, center: Vector3, mesh: Mesh, scene: Scene, viewHelpers = false, offsets: { x: number, y: number, z: number, }, draggableObjectCenter: Object3D, boundingBoxSelected: Box3,) => {
        const cam = context ? context.getRef().current : null
        if (!cam || !boundingBoxSelected) {
            return
        }
        mesh.updateMatrixWorld(true)
        const quaternion = new Quaternion()
        mesh.updateMatrixWorld(true)
        mesh.matrixWorld.decompose(new Vector3(), quaternion, new Vector3())

        const offsetCombinations = [
            { x: offsets.x, y: offsets.y, z: offsets.z, },
            { x: -offsets.x, y: offsets.y, z: offsets.z, },
            { x: offsets.x, y: -offsets.y, z: offsets.z, },
            { x: offsets.x, y: offsets.y, z: -offsets.z, },
            { x: -offsets.x, y: -offsets.y, z: offsets.z, },
            { x: -offsets.x, y: offsets.y, z: -offsets.z, },
            { x: offsets.x, y: -offsets.y, z: -offsets.z, },
            { x: -offsets.x, y: -offsets.y, z: -offsets.z, },
        ]

        const boxSize = new Vector3()
        box.getSize(boxSize)

        const boxCenter = new Vector3()
        box.getCenter(boxCenter)

        const boundingBoxCenter = new Vector3()
        if (boundingBoxSelected) {
            boundingBoxSelected.getCenter(boundingBoxCenter)
        }

        const tempCam = cam.camera.clone()
        tempCam.position.copy(cam.camera.position)
        tempCam.quaternion.copy(cam.camera.quaternion)
        tempCam.updateProjectionMatrix()
        const offsetData = []
        const currentCameraDirection = new Vector3()
        cam.camera.getWorldDirection(currentCameraDirection)

        for (let i = 0; i < offsetCombinations.length; i++) {
            const offset = offsetCombinations[i]
            const worldLineEndPosition = new Vector3(offset.x, offset.y, offset.z).applyQuaternion(quaternion)
                .add(center)
            tempCam.position.set(worldLineEndPosition.x, worldLineEndPosition.y, worldLineEndPosition.z)
            tempCam.lookAt(center)
            const { triangle, } = drawTriangle(scene, boxCenter, boundingBoxCenter, tempCam.position, false,)
            let intersectionCount = 0
            scene.traverse((object) => {
                if (object instanceof Mesh && object.name.includes("inner") && !object.name.includes("Boundary") && !object.name.includes("alignment")) {
                    const objectBox = new Box3().setFromObject(object)
                    if (triangle.intersectsBox(objectBox)) {
                        intersectionCount++
                    }
                }
            })
            const localCameraPosition = mesh.worldToLocal(tempCam.position.clone())
            const worldCameraPosition = tempCam.position.clone()
            offsetData.push({ offset, localCameraPosition, worldCameraPosition, intersectionCount, })
        }
        const filteredOffsetData = offsetData.filter(data => data.localCameraPosition.z >= 0)

        filteredOffsetData.sort((a, b) => a.intersectionCount - b.intersectionCount)

        const bestOffset = filteredOffsetData[0].offset

        const bestWorldLineEndPosition = new Vector3(bestOffset.x, bestOffset.y, bestOffset.z).applyQuaternion(quaternion)
            .add(center)
        const combinedBox = new Box3().setFromPoints([boundingBoxSelected.min, boundingBoxSelected.max, center,])
        const combinedBoxCenter = new Vector3()
        combinedBox.getCenter(combinedBoxCenter)

        const combinedBoxSize = new Vector3()
        combinedBox.getSize(combinedBoxSize)
        const longestSide = Math.max(combinedBoxSize.x, combinedBoxSize.y, combinedBoxSize.z)
        const initalCamDistance = cam.distance
        cam.setPosition(bestOffset.x, bestOffset.y, bestOffset.z)
        cam.setLookAt(
            bestWorldLineEndPosition.x,
            bestWorldLineEndPosition.y,
            bestWorldLineEndPosition.z,
            combinedBoxCenter.x,
            combinedBoxCenter.y,
            combinedBoxCenter.z,
            true
        )
        const newDistance = longestSide * 6
        if (initalCamDistance < newDistance) {
            cam.distance = newDistance
        } else {
            cam.distance = initalCamDistance
        }
    }

    const saveCameraState = (cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        if (cam) {
            const cameraState = cam.saveState()
            return cameraState
        }
    }

    const resetCameraState = (cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        cam?.reset(true)
    }

    const enableMousePan = (pan: boolean, cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        if (cam) {
            if (pan) {
                cam.touches.one = CAMERA_ACTIONS.TOUCH_OFFSET
                cam.mouseButtons.left = CAMERA_ACTIONS.OFFSET
            } else {
                cam.touches.one = CAMERA_ACTIONS.TOUCH_ROTATE
                cam.mouseButtons.left = CAMERA_ACTIONS.ROTATE
            }
        }
    }
    const cameraPanZoomRotateStates = (panOrRotate: "pan" | "rotate" | "neither", zoom: boolean, cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        if (cam) {
            if (panOrRotate === "pan") {
                cam.touches.one = CAMERA_ACTIONS.TOUCH_OFFSET
                cam.mouseButtons.left = CAMERA_ACTIONS.OFFSET
            }
            if (panOrRotate === "rotate") {
                cam.touches.one = CAMERA_ACTIONS.TOUCH_ROTATE
                cam.mouseButtons.left = CAMERA_ACTIONS.ROTATE
            }
            if (panOrRotate === "neither") {
                cam.touches.one = CAMERA_ACTIONS.NONE
                cam.mouseButtons.left = CAMERA_ACTIONS.NONE
            }
            if (zoom) {
                cam.touches.three = CAMERA_ACTIONS.TOUCH_ZOOM
            }
            if (!zoom) {
                cam.touches.three = CAMERA_ACTIONS.NONE
            }
        }
    }

    const findEmptyPositionForBox = (scene: Scene, targetBox: Box3, designBoundingBox: Box3, debugMode = false) => {

        const scaledBox = designBoundingBox.clone().expandByScalar(0.09)
        const validPoints: Vector3[] = []
        const maxAttempts = 20 // Maximum number of attempts to expand the box
        let attempts = 0

        while (validPoints.length < 10 && attempts < maxAttempts) {
            for (let j = 0; j < 10; j++) {
                const randomPoint = getRandomPointOutsideBox(scaledBox, targetBox, designBoundingBox, scene)
                if (randomPoint) {
                    validPoints.push(randomPoint)
                }
            }

            if (validPoints.length < 5) {
                // Expand the scaledBox if not enough valid points were found
                scaledBox.expandByScalar(0.5)
                attempts++
            }
        }

        if (validPoints.length === 0) {
            console.warn("couldn't find a valid point to put the object")
            return null
        }

        // Find the point closest to the target box
        const targetCenter = new Vector3()
        targetBox.getCenter(targetCenter)
        const closestPoint = validPoints.reduce((closest, point) => {
            return point.distanceTo(targetCenter) < closest.distanceTo(targetCenter) ? point : closest
        })

        const worldClosestPoint = closestPoint.clone().applyMatrix4(scene.matrixWorld)

        if (debugMode) {
            drawVector3Point(worldClosestPoint, scene, 0x0000ff, 0.003)
            const debugBox = new Box3().copy(targetBox)
            debugBox.setFromCenterAndSize(worldClosestPoint, targetBox.getSize(new Vector3()))
            const helper = new Box3Helper(debugBox, new Color(0x0000ff))
            scene.add(helper)

            // Optionally, visualize all valid points
            validPoints.forEach(point => {
                const worldPoint = point.clone().applyMatrix4(scene.matrixWorld)
                drawVector3Point(worldPoint, scene, 0x00ff00, 0.002)
            })
        }
        return worldClosestPoint
    }

    const getRandomPointOutsideBox = (containerBox: Box3, targetBox: Box3, designBoundingBox: Box3, scene: Scene): Vector3 | null => {
        const size = containerBox.getSize(new Vector3())
        const center = containerBox.getCenter(new Vector3())

        let randomPoint: Vector3
        let attempts = 0
        const maxAttempts = 20 // Increased max attempts

        // Create a padded design bounding box
        const paddedDesignBoundingBox = designBoundingBox.clone().expandByScalar(0.06)

        // Helper function to visualize boxes (for debugging)
        const addBoxHelper = (box: Box3, color: number) => {
            const helper = new Box3Helper(box, new Color(color))
            scene.add(helper)
        }

        // Visualize boxes
        //addBoxHelper(paddedDesignBoundingBox, 0x000000) // Black for padded design box
        //addBoxHelper(designBoundingBox, 0x800080)
        //addBoxHelper(containerBox, 0x0000ff) // Blue for container box

        const debugBox = new Box3().copy(targetBox)
        const targetSize = targetBox.getSize(new Vector3())

        while (attempts < maxAttempts) {
            randomPoint = new Vector3(
                center.x + (Math.random() - 0.5) * size.x,
                center.y + (Math.random() - 0.5) * size.y,
                center.z + (Math.random() - 0.5) * size.z
            )

            debugBox.setFromCenterAndSize(randomPoint, targetSize)

            const intersects = paddedDesignBoundingBox.intersectsBox(debugBox)
            const contains = paddedDesignBoundingBox.containsBox(debugBox)

            //console.log(`Attempt ${attempts + 1}:`,
            //            `Point: (${randomPoint.x.toFixed(2)}, ${randomPoint.y.toFixed(2)}, ${randomPoint.z.toFixed(2)})`,
            //            `Intersects: ${intersects}`,
            //            `Contains: ${contains}`)

            if (!intersects && !contains) {
                // Visualize the chosen point and its box
                //drawVector3Point(randomPoint, scene, 0x00ff00, 0.005) // Green for success
                //addBoxHelper(debugBox, 0xff0000) // Red for successful box
                return randomPoint
            }

            attempts++
        }
        return null
    }
    const fitBox = (box: Box3, cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        box.expandByScalar(0.1)
        const sp = new Sphere()
        box.getBoundingSphere(sp)
        cam?.fitToSphere(sp, true)
    }

    const fitAsync = async (mesh: Mesh, matrixWorld: Matrix4, cameraControls?: CameraControls) => {
        const cam = context ? context.getRef().current : cameraControls
        mesh.geometry.computeBoundingBox()
        const box = new Box3()
        box
            .copy(mesh.geometry.boundingBox!)
            .applyMatrix4(matrixWorld)
        const sp = new Sphere()
        box.getBoundingSphere(sp)
        await cam?.fitToSphere(sp, false)
    }

    const getRef = () => {
        return context?.getRef()
    }

    const getCamera = () => {
        return context?.getRef().current?.camera
    }

    const getInAutofocusMode = () => {
        return context?.getInAutofocusMode()
    }

    const setInAutofocusMode = (value: boolean) => {
        return context?.setInAutofocusMode(value)
    }

    const fitCameraToObject = (cam: PerspectiveCamera, object: Mesh) => {

        const boundingBox = new Box3()
        object.geometry.computeBoundingBox()
        boundingBox.setFromObject(object)
        const boundingSphere = new Sphere()
        boundingBox.getBoundingSphere(boundingSphere)

        const center = boundingSphere.center
        const radius = boundingSphere.radius


        cam.lookAt(center)

        const distance = center.distanceTo(cam.position) - radius
        const realHeight = Math.abs(boundingBox.max.y - boundingBox.min.y)

        const correctForDepth = 3

        const fov = 2 * Math.atan(realHeight * correctForDepth / (2 * distance)) * (180 / Math.PI)

        cam.fov = fov
        cam.updateProjectionMatrix()
    }

    const fitCameraToPart = (cam: PerspectiveCamera, object: Mesh, scene: Scene) => {

        const boundingBox = new Box3()
        boundingBox.setFromObject(object)

        const boundingSphere = new Sphere()
        boundingBox.getBoundingSphere(boundingSphere)

        cam.lookAt(boundingSphere.center)
        cam.updateProjectionMatrix()

        const direction = new Vector3()
        cam.getWorldDirection(direction)

        const dist = cam.position.distanceTo(boundingSphere.center)

        cam.position.add(direction.multiplyScalar(dist - (boundingSphere.radius * 12)))

        cam.updateProjectionMatrix()
    }

    const fitCameraToBox = (cam: PerspectiveCamera, box: Box3, scene: Scene, padding?: number) => {

        const boundingBox = box
        const paddingValue = box.getSize(new Vector3()).length() * 6

        const boundingSphere = new Sphere()
        boundingBox.getBoundingSphere(boundingSphere)

        cam.lookAt(boundingSphere.center)
        cam.updateProjectionMatrix()

        const direction = new Vector3()
        cam.getWorldDirection(direction)

        const dist = cam.position.distanceTo(boundingSphere.center)

        cam.position.add(direction.multiplyScalar(dist - boundingSphere.radius - paddingValue))

        cam.updateProjectionMatrix()
    }

    const addMeshToSceneBounds = () => {
        context?.addMeshToSceneBounds()
    }

    const disableCamera = (cam?: CameraControls) => {
        const cameraControls = cam || context?.getRef().current
        if (cameraControls) {
            cameraControls.enabled = false
        }
    }

    const enableCamera = (cam?: CameraControls) => {
        const cameraControls = cam || context?.getRef().current
        if (cameraControls) {
            cameraControls.enabled = true
        }
    }

    const resetCameraRotation = (cameraControls: CameraControls) => {
        cameraControls.rotateTo(0.78, 0.95, true)
        cameraControls.camera.up = new Vector3(0, 1, 0)
        cameraControls.updateCameraUp()
    }

    return {
        fitBox,
        fitAndLookAtBox,
        fit,
        fitAsync,
        getRef,
        getCamera,
        fitCameraToObject,
        horizontalRotation45Deg,
        verticalRotation45Deg,
        getInAutofocusMode,
        setInAutofocusMode,
        addMeshToSceneBounds,
        fitCameraToPart,
        fitCameraToBox,
        disableCamera,
        drawVector3Point,
        enableCamera,
        saveCameraState,
        resetCameraState,
        enableMousePan,
        cameraPanZoomRotateStates,
        findEmptyPositionForBox,
        resetCameraRotation,
        setShowCameraControls,
        getBoundingBoxForConnector,
    }
}

export default useCamera