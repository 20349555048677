import { Euler, Group, Object3D, Quaternion, Vector3 } from "three"
import { DragPoint, FreePositions } from "../../../../../../../../../providers/moveProvider/types"
import { getMeasurement, getDistances } from "./MeasurementHelpers"
import { SegmentedTubeMarkers } from "../../../segmentedTube/types/types"
import { MeshStrategy } from "../../../../../../../../../providers/moveProvider/meshHelpers"
interface UseMeasurementsProps {
    center: Vector3;
    pointsArray: FreePositions[];
    strategy: MeshStrategy;
    surfaceStartLength: number;
    surfaceEndLength: number;
    targetMiddleLength: number;
    slideSide: SegmentedTubeMarkers;
}

const useMeasurements = (props: UseMeasurementsProps) => {
    const {
        center,
        pointsArray,
        strategy,
        surfaceStartLength,
        surfaceEndLength,
        slideSide,
    } = props

    const firstSlideSide = slideSide[Object.keys(slideSide)[0]]
    const outerMarker = firstSlideSide.outer
    const plusMarker = firstSlideSide.plus

    const worldQuaternion = new Quaternion()
    outerMarker?.getWorldQuaternion(worldQuaternion)

    const isSingleMesh = !outerMarker?.userData.boundary && strategy === MeshStrategy.Middle

    const group = new Group()

    if (plusMarker) {
        const plusMarkerQuaternion = new Quaternion()
        plusMarker?.getWorldQuaternion(plusMarkerQuaternion)
        group.setRotationFromQuaternion(plusMarkerQuaternion)
    } else {
        group.setRotationFromQuaternion(worldQuaternion)
    }

    group.position.copy(center)

    const measurement = getMeasurement(
        pointsArray,
        center,
        surfaceStartLength,
        surfaceEndLength,
        group,
        strategy,
    )

    const distances = getDistances(center, measurement)

    return { distances, group, isSingleMesh, }
}

export default useMeasurements