import React, { useCallback, useEffect, useRef } from "react"
import { useState } from "react"
import {
    LeftContent,
    LeftRightContainer,
    ButtonContainer,
    RightContent,
    SliderContainer
} from "./ActionPanelStyles"
import Button from "../../../../../../../../../common/components/Button"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import DirectionSelector from "./DirectionSelector"
import NumberSlider from "../../sliders/Slider"
import { convertInToCm } from "../../../../../utils/UnitUtils"

interface Props {
    value: number;
    min: number;
    max: number;
    validMin: number;
    validMax: number;
    step: number;
    unit: string;
    updateUnit: (unit: string) => void;
    onMouseUp: (value: number) => void;
    onChange: (value: number) => void;
    onMouseDown: (value: number) => void;
    onFinishEditing: () => void;
    setDirection: (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => void;
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>;
}

const SegmentedTubeLengthSlider = (props: Props) => {
    const [length, setLength,] = useState(props.value)
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)


    const updateValue = useCallback((newValue: number) => {
        setLength(newValue)
        props.onChange(newValue)
    }, [props,])


    const debouncedUpdate = useCallback((newValue: number) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current)
        }
        //I put 1 second because people complaining that it updates too faster
        //added the enter pressing to account for it
        debounceTimeoutRef.current = setTimeout(() => {
            updateValue(newValue)
        }, 1000)
    }, [updateValue,])

    const handleOnChange = useCallback((value: number, debounce = false) => {

        let validMinAdjusted = props.validMin
        let validMaxAdjusted = props.validMax

        if (props.unit === "cm") {
            validMinAdjusted = convertInToCm(validMinAdjusted)
            validMaxAdjusted = convertInToCm(validMaxAdjusted)
        }

        if (value >= validMaxAdjusted) {
            if (debounce) {
                debouncedUpdate(validMaxAdjusted)
            } else {
                updateValue(validMaxAdjusted)
            }
        } else if (value <= validMinAdjusted) {
            if (debounce) {
                debouncedUpdate(validMinAdjusted)
            } else {
                updateValue(validMinAdjusted)
            }
        } else if (debounce) {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
                debounceTimeoutRef.current = null
            }
            debouncedUpdate(value)
        } else {
            updateValue(value)
        }
    }, [debouncedUpdate, updateValue, props.validMax, props.validMin,])


    return <SliderContainer>
        <LeftRightContainer>
            <LeftContent>
                <NumberSlider
                    unit={props.unit}
                    updateUnit={props.updateUnit}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    handleMouseUp={props.onMouseUp}
                    onChange={handleOnChange}
                    getValue={() => length}
                    inputNumber={true}
                    handleMouseDown={props.onMouseDown}
                    disabledTyping={false}
                />
            </LeftContent>
            <RightContent>
                <DirectionSelector direction={props.direction} setDirection={props.setDirection} />
            </RightContent>
        </LeftRightContainer>
    </SliderContainer>
}

export default SegmentedTubeLengthSlider