/* eslint-disable max-statements */
import { Intersection, Object3D, Quaternion, Raycaster, Scene, Vector3 } from "three"
import { isParallel, metersToInch } from "../components/main/DesignScreen/utils/utilsThree"
import { MarkerType } from "./Types"

const APROXIMATELLY_COLLINEAR_MARGIN = 0.5 / metersToInch

const copyWorldPosition = (mesh: Object3D) => {
    const position = new Vector3()
    mesh.getWorldPosition(position)
    return position
}

const copyWorldQuaternion = (mesh: Object3D) => {
    const quaternion = new Quaternion()
    mesh.getWorldQuaternion(quaternion)
    return quaternion
}

const copyWorldDirection = (mesh: Object3D) => {
    const direction = new Vector3()
    mesh.getWorldDirection(direction)
    return direction
}

const getMarkerMeshesWithoutMiddleSegments = (scene: Scene) => {
    const meshes: Object3D[] = []
    scene.traverse(object => {
        if(object.userData.type === MarkerType.COLLISION && !object.userData.middleSection) {
            meshes.push(object)
        }
    })
    return meshes
}

const checkDistanceToCenter = (intersection: Intersection, center: Vector3) => {
    const distanceToCenter = intersection.point.distanceTo(center)
    return distanceToCenter <= APROXIMATELLY_COLLINEAR_MARGIN
}

const getOriginPositionMoved = (origin: Vector3, direction: Vector3, distanceToMove: number) => {
    const newPos = new Vector3()
    const auxDir = new Vector3(direction.x, direction.y, direction.z)
    newPos.addVectors(
        origin,
        auxDir.normalize().multiplyScalar(distanceToMove / metersToInch)
    )
    return newPos
}

const checkAproxCollinearToDirection = (
    aPos: Vector3,
    aDir: Vector3,
    meshB: Object3D,
    backward: boolean,
) => {
    const movedOrigin = getOriginPositionMoved(aPos, aDir, backward ? 0.2 : -0.2)
    const dir = backward ? aDir.multiplyScalar(-1) : aDir
    const ray = new Raycaster(movedOrigin, dir)
    const intersection = ray.intersectObject(meshB)
    return intersection[0]
}

const areApproximatelyCollinear = (
    meshA: Object3D,
    meshB: Object3D,
) => {
    const aDir = copyWorldDirection(meshA)
    const bDir = copyWorldDirection(meshB)
    if(isParallel(aDir, bDir)) {
        const aPos = copyWorldPosition(meshA)
        const bPos = copyWorldPosition(meshB)

        const intersectionForward = checkAproxCollinearToDirection(aPos, aDir, meshB, false)
        if(intersectionForward) {
            return checkDistanceToCenter(intersectionForward, bPos)
        } else {
            const intersectionBackward = checkAproxCollinearToDirection(aPos, aDir, meshB, true)
            if(intersectionBackward) {
                return checkDistanceToCenter(intersectionBackward, bPos)
            }
        }
    }
    return false
}

export const MeshUtils = {
    copyWorldPosition,
    copyWorldQuaternion,
    copyWorldDirection,
    getMarkerMeshesWithoutMiddleSegments,
    areApproximatelyCollinear,
    checkAproxCollinearToDirection,
    getOriginPositionMoved,
}