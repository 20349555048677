import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { Icosahedron, Text } from "@react-three/drei"
import {
    ArrowHelper, BackSide, Box3, Box3Helper, BoxHelper, BufferGeometry, Color, Group, Line,
    LineBasicMaterial, Mesh, Object3D, Raycaster, Scene, Vector3
} from "three"
import { useFrame, useThree } from "@react-three/fiber"
import { MarkerType, PartTypeEnum } from "../../../../../../../../utils/Types"
import { SectionType, SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { addPartModal, isItemSelected } from "../../../../../../../../state/atoms"
import { useSetRecoilState } from "recoil"
import { ConnectionOfPart } from "../../../../../../../../state/scene/types"

export type SectionedMarkerData = {
    section: SectionType, key: string, position?: string,
}

type NewPartButtonProps = {
    info: MutableRefObject<SegmentedTubeInfo>,
    marker: SegmentedTubeMarker,
    partId: string,
    setIsSelected: (selected: boolean) => void,
    isMiddleSection?: boolean,
    scene?: Scene,
    partConnectionsValue: ConnectionOfPart[],
}


const NewPartButton = (props: NewPartButtonProps) => {
    const setNewConnectionData = useSetRecoilState(addPartModal)

    const { camera, } = useThree()
    const icoRef = useRef<Mesh>(null)
    const [hovered, hover,] = useState(false)

    const targetMarker = props.marker.plus || props.marker.outer!

    const { worldOuterPosition, worldOuterQuaternion, } = useMemo(() => {
        const worldOuterPosition = MeshUtils.copyWorldPosition(targetMarker)
        const worldOuterDirection = MeshUtils.copyWorldDirection(targetMarker)
        worldOuterPosition.add(worldOuterDirection.multiplyScalar(0.02))
        const worldOuterQuaternion = MeshUtils.copyWorldQuaternion(targetMarker)
        return { worldOuterPosition, worldOuterDirection, worldOuterQuaternion, }
    }, [props.marker, isItemSelected, props.partConnectionsValue,])

    const [markerPosition, setMarkerPosition,] = useState(new Vector3())

    const [offset, setOffset,] = useState(0)

    const idealPosition: Vector3 = useMemo(() => {
        if (props.marker.mesh) {
            const worldOuterWorking = MeshUtils.copyWorldPosition(targetMarker)
            const worldOuterDirectionWorking = MeshUtils.copyWorldDirection(targetMarker)
            const worldOuterQuaternionWorking = MeshUtils.copyWorldQuaternion(targetMarker)

            props.marker.mesh.geometry.computeBoundingBox()

            let meshWidth = 0
            let meshHeight = 0

            if (props.marker.mesh.geometry.boundingBox) {
                meshWidth = props.marker.mesh.geometry.boundingBox.max.x
                    - props.marker.mesh.geometry.boundingBox.min.x
                meshHeight = props.marker.mesh.geometry.boundingBox.max.y
                    - props.marker.mesh.geometry.boundingBox.min.y
            }

            const longSide = Math.max(meshWidth, meshHeight) / 2 - 0.0125

            const increments = Array
                .from({ length: Math.ceil(longSide / 0.0125), }, (_, i) => (i + 1) * 0.0125)
            const directions = [0, ...increments, ...increments.map(inc => -inc),]
            for (let i = 0; i < directions.length; i++) {

                const localX = new Vector3(directions[i], 0, 0)
                localX.applyQuaternion(worldOuterQuaternionWorking)
                worldOuterWorking.add(localX)

                const raycaster = new Raycaster(worldOuterWorking, worldOuterDirectionWorking)
                const newIntersections = raycaster.intersectObjects(props.scene?.children || [])
                const filteredIntersections = newIntersections.filter(intersect =>
                    intersect.object.userData.partId !== props.partId
                    && intersect.object.userData.type !== MarkerType.PLUS_BUTTON
                    && intersect.object.type !== "ArrowHelper"
                    && intersect.object.parent?.type !== "ArrowHelper"
                    && intersect.object.parent?.name !== "Plus Button"
                    && intersect.object.type !== "Line"
                    && !intersect.object.name.includes("aligment")
                    && !intersect.object.name.includes("ignore")
                )
                //console.log("filteredIntersections", filteredIntersections)
                //console.log("newIntersections", newIntersections)
                //const raycasterHelper = new
                //ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 0.1, 0xff0000)
                //props.scene?.add(raycasterHelper)

                if (!filteredIntersections.some(intersect => intersect.distance < 0.01)) {
                    setOffset(directions[i])
                    const position = new Vector3()
                    const plusOffset = new Vector3(0, 0, 0.02)
                    plusOffset.applyQuaternion(worldOuterQuaternionWorking)

                    position.copy(worldOuterWorking)
                    position.add(plusOffset)
                    setMarkerPosition(position)
                    return position
                }
                worldOuterWorking.copy(MeshUtils.copyWorldPosition(targetMarker))
                worldOuterDirectionWorking.copy(MeshUtils.copyWorldDirection(targetMarker))
            }
        }
        return new Vector3()
    }, [props.marker, props.partConnectionsValue, isItemSelected,])


    const cameraVector = new Vector3()
    useFrame((state) => {
        if (icoRef.current) {
            camera.getWorldPosition(cameraVector)
            icoRef.current.lookAt(cameraVector)
        }
    })

    const onHover = () => {
        hover(true)
    }

    const onHoverOut = () => {
        hover(false)
    }

    useEffect(() => {
        if (hovered) {
            document.body.style.cursor = "pointer"
        } else {
            document.body.style.cursor = "default"
        }

        return () => {
            document.body.style.cursor = "default"
        }
    }, [hovered,])

    const handleNewPart = () => {

        props.setIsSelected(false)
        document.body.style.cursor = "default"

        const outerPos = MeshUtils.copyWorldPosition(props.marker.outer!)
        const outerRot = MeshUtils.copyWorldQuaternion(props.marker.outer!)

        if (props.marker.mesh && !markerPosition.equals(new Vector3())) {
            const offsetVector = new Vector3(offset, 0, 0)
            offsetVector.applyQuaternion(worldOuterQuaternion)
            outerPos.add(offsetVector)
        }

        const innerPos = MeshUtils.copyWorldPosition(props.marker.inner!)
        const innerRot = MeshUtils.copyWorldQuaternion(props.marker.inner!)


        if (props.marker.mesh && !markerPosition.equals(new Vector3(0, 0, 0))) {
            const offsetVector = new Vector3(offset, 0, 0)
            offsetVector.applyQuaternion(worldOuterQuaternion)
            innerPos.add(offsetVector)
        }

        setNewConnectionData({
            step1: {
                source: {
                    partId: props.partId,
                    markerId: props.marker.outer!.userData.id,
                    markerName: props.marker.outer!.name,
                    posAndRot: {
                        inner: {
                            pos: innerPos,
                            rot: innerRot,
                        },
                        outer: {
                            pos: outerPos,
                            rot: outerRot,
                        },
                    },
                    connectionLength: props.marker.outer!.userData.iELength,
                    sizeId: props.marker.outer!.userData.sizeId,
                    expandReduceToFitInfo: { isApplicable: false, },
                    type: PartTypeEnum.segmentedTube,
                    canSlide: props.isMiddleSection,
                },
            },
        })
    }

    const materialColor = useMemo(() => {
        return new Color(hovered ? "#43a7ff" : "#0088ff").convertSRGBToLinear()
    }, [hovered,])

    return <Icosahedron
        userData={{
            type: MarkerType.PLUS_BUTTON,
        }}
        ref={icoRef}
        name={"Plus Button"}
        args={[0.02 / 2, 3,]}
        position={markerPosition.equals(new Vector3()) ? worldOuterPosition : markerPosition}
        quaternion={worldOuterQuaternion}
        onPointerLeave={onHoverOut}
        onPointerEnter={onHover}
        onClick={handleNewPart}
    >
        <meshBasicMaterial color={materialColor} side={BackSide} />
        <Text fontSize={0.02} color={"#fff"}>
            +
        </Text>
    </Icosahedron>
}

export default NewPartButton