/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from "react"
import styled, { css } from "styled-components"
import Loading from "./Loading"
import isMobile from "ismobilejs"

interface Props {
  $ifMobile?: JSX.Element;
  color?: string;
  $loadingColor?: string;
  action?: "primary" | "secondary";
  $full?: boolean;
  children: (JSX.Element | string)[] | JSX.Element | string;
  onClick?: (e: any) => void;
  $borderColor?: string;
  $labelColor?: string;
  disabled?: boolean;
  type?: "button" | "submit" | undefined;
  loading?: boolean;
  style?: React.CSSProperties;
}

const Button = (props: Props) => {

  const getChildren = () => {
    if (props.$ifMobile && isMobile(window.navigator).phone) {
      return props.$ifMobile
    } else {
      return props.children
    }
  }
  const { loading, ...restProps } = props
  return (
    <ButtonStyle
      type={props.type ? props.type : "button"}
      disabled={props.disabled}
      {...restProps}
      onClick={props.onClick}
    >
      <React.Fragment>
        {loading && (
          <Loading
            color={props.$loadingColor ? props.$loadingColor : props.color}
          />
        )}
        {getChildren()}
      </React.Fragment>
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<Props>`
  background-color: ${(props) =>
    (props.action === "primary" && props.color ? props.color : "white")};
  border: ${(props) =>
  (props.$borderColor || props.action === "secondary"
    ? `1px solid ${props.$borderColor}`
    : "none")};
  transition: all 0.16s ease-in-out;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 6px;
  padding: 8px;
  color: ${(props) =>
    (props.$labelColor ? props.$labelColor : props.action ? "white" : "black")};
  width: ${(props) => (props.$full ? "100%" : "auto")};
  font-weight: ${(props) => (props.action ? "bold" : "normal")};
  display: flex;
  justify-content: center;
  align-items: center;
  float:right;
  gap: 8px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  ${(props) => {
    if (!props.disabled) {
      return css`
        :hover {
          background-color: ${props.action && props.color
          ? props.color
          : "#eaeaea"};
          opacity: ${props.action ? 0.8 : 1};
        }
      `
    }
  }}
`

export default Button
