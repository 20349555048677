import { PartTypeAPIRaw } from "./Types"
import { EnvHelper } from "../utils/EnvHelper"
import algoliasearch from "algoliasearch/lite"

const { appId, apiKey, indexName, } = EnvHelper.algoliaConfig

const index = algoliasearch(appId, apiKey).initIndex(indexName)

const searchAlgolia = async (query: string, page: number) => {
    return (await index.search<PartTypeAPIRaw>(query, {page, hitsPerPage: 1000,}))
}

export const searchCompatibleParts = async (query: string, userQuery: string) => {
    let page = 0
    let concatedQuery = ""
    if(userQuery) {
        concatedQuery = `${userQuery} ${query}`
    } else {
        concatedQuery = query
    }
    let res = await searchAlgolia(concatedQuery, page)
    //console.log(res)
    let hits = res.hits
        .map(hit => ({ ...hit, id: hit.objectID, }))
    while (res.page < res.nbPages - 1) {
        page++
        res = await searchAlgolia(concatedQuery, page)
        //console.log(res)
        hits = hits.concat(res.hits.map(hit => ({ ...hit, id: hit.objectID, })))
    }
    return hits
}

