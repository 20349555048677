import { PartsListType } from
    "../../builder/components/main/DesignScreen/mainLayout/PartsModal/PartsModal"
import { PartTypeAPI } from "../api/Types"
import { getRecentPartsBase, setRecentPartsBase } from "./LocalPersistence"

export const getRecentParts = () => {
    const recentParts = getRecentPartsBase()
    if (recentParts) {
        return recentParts.map((part) => {
            try {
                return JSON.parse(part)
            } catch (e) {
                return part
            }
        })
    }
    return recentParts || []
}

export const setRecentParts = (part: PartTypeAPI) => {
    const newPart = {
        id: part.id,
        materialId: part.materialId,
        subclassId: part.subclassId,
        name: part.name,
        categories: part.categories,
    }
    let recentParts = getRecentParts()
    if (!Array.isArray(recentParts)) {
        recentParts = []
    }

    recentParts.unshift(newPart)
    const recentPartsStringified = recentParts.map(e => JSON.stringify(e))
    const uniqueRecentPartsSet = new Set(recentPartsStringified)
    const uniqueRecentParts = Array.from(uniqueRecentPartsSet).map(e => JSON.parse(e))

    while (uniqueRecentParts.length > 10) {
        uniqueRecentParts.pop()
    }

    setRecentPartsBase(uniqueRecentParts)
}

function sortArrayByIndices(
    array: { key: string, values: any[], }[],
    indices: (string | string[])[],
    preferSingleParts = false
) {
    return array.sort((a, b) => {
        let aIndex, bIndex
        if (Array.isArray(indices[0])) {
            aIndex = indices.findIndex(ids => (ids as string[]).includes(a.key))
            bIndex = indices.findIndex(ids => (ids as string[]).includes(b.key))
        } else {
            aIndex = (indices as string[]).indexOf(a.key)
            bIndex = (indices as string[]).indexOf(b.key)
        }
        if (aIndex === -1 && bIndex === -1) { return 0 } // neither element is in indices
        if (aIndex === -1) { return 1 } // a is not in indices, but b is
        if (bIndex === -1) { return -1 } // b is not in indices, but a is
        if (preferSingleParts && aIndex === bIndex) { // both elements have the same index
            if (a.values.length === 1 && b.values.length !== 1) {
                return -1 // a comes first if it has only one value
            }
            if (b.values.length === 1 && a.values.length !== 1) {
                return 1 // b comes first if it has only one value
            }
        }
        return aIndex - bIndex // both elements are in indices, sort by index
    })
}

function sortPartsByRecent(parts: PartsListType[],
    recentPartsIds: string[],
    preferSingleParts = false) {
    return sortArrayByIndices(parts, recentPartsIds, preferSingleParts)
}

export { sortPartsByRecent, sortArrayByIndices, }