/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react"
import { Color, Group, InstancedMesh, Matrix4, Mesh, Texture } from "three"
import { instancedMeshContext } from "./InstancedMeshProvider"

const useInstancedMesh = (
    mesh: Mesh,
    id: string,
    referenceName: string,
    onClick: (mesh: InstancedMesh, matrix: Matrix4) => void,
    withMaterial?: string,
    woodTexture?: Texture) => {
    const context = useContext(instancedMeshContext)
    const ref = useRef<Group>()
    const instancedMesh = useRef<InstancedMesh>()
    const index = useRef<number>()
    const lastMatrixWorld = useRef<Matrix4>()

    const updateTransforms = () => {
        if (instancedMesh.current && ref.current) {
            ref.current.updateWorldMatrix(true, true)
            lastMatrixWorld.current = ref.current.matrixWorld
            instancedMesh.current.setMatrixAt(index.current!, lastMatrixWorld.current)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const updateInstance = (newIndex: number) => {
        index.current = newIndex
        updateTransforms()
        instancedMesh.current?.computeBoundingSphere()
    }

    const deleteMesh = () => {
        if (context && instancedMesh.current) {
            context.deleteMesh(getReferenceName(), id)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const updateColor = (color: string) => {
        if (instancedMesh.current) {
            instancedMesh.current.setColorAt(index.current!, new Color(color))
            instancedMesh.current.instanceColor!.needsUpdate = true
        }
    }

    const internalCallback = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current!, matrix)
            onClick(instancedMesh.current, matrix)
        }
    }

    const getMatrix = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current!, matrix)
            return matrix
        }
    }

    const getReferenceName = () => {
        if (withMaterial) {
            return `${referenceName}-mat-${withMaterial}`
        }

        return referenceName
    }

    useEffect(() => {
        if (context) {
            const instance = context.createMesh(
                id, getReferenceName(), mesh, internalCallback, updateInstance, woodTexture
            )
            instancedMesh.current = instance.instancedMesh
            index.current = instance.index
        }
    }, [])

    return {
        ref,
        updateTransforms,
        deleteMesh,
        instancedMesh,
        updateColor,
        internalCallback,
        getMatrix,
    }
}

export default useInstancedMesh