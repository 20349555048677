import React from "react"
import {
    Vector3,
} from "three"
import Measurement from "./Measurement"
import { DragPoint, FreePositions } from "../../../../../../../../../providers/moveProvider/types"
import useMeasurements from "./useMeasurements"
import { SegmentedTubeMarkers } from "../../../segmentedTube/types/types"
import { MeshStrategy } from "../../../../../../../../../providers/moveProvider/meshHelpers"

interface DragMeasurementsProps {
    center: Vector3;
    pointsArray: FreePositions[];
    strategy: MeshStrategy;
    surfaceStartLength: number;
    surfaceEndLength: number;
    targetMiddleLength: number;
    slideSide: SegmentedTubeMarkers;
}

const DragMeasurements = (props: DragMeasurementsProps) => {
    const {
        center,
        pointsArray,
        strategy,
        surfaceStartLength,
        surfaceEndLength,
        targetMiddleLength,
        slideSide,
    } = props
    const measurementProps = {
        center: center,
        pointsArray: pointsArray,
        strategy: strategy,
        surfaceStartLength: surfaceStartLength,
        surfaceEndLength: surfaceEndLength,
        targetMiddleLength: targetMiddleLength,
        slideSide: slideSide,
    }
    const { distances, group, isSingleMesh, } = useMeasurements(measurementProps)

    return <>
        <group rotation={group.rotation} position={group.position}>
            {!isSingleMesh && distances.up && <Measurement
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(0, distances.up, 0)}
            />}
            {!isSingleMesh && distances.down && <Measurement
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(0, -distances.down, 0)}
            />}
            {distances.right && <Measurement
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(distances.right, 0, 0)}
            />}
            {distances.left && <Measurement
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(-distances.left, 0, 0)}
            />}
        </group>
    </>
}

export default DragMeasurements
