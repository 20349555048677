import { ButtonLink } from "../components/ButtonLinks/ButtonLinks"
import { ReactComponent as AddIcon } from "../assets/icons/AddSmall.svg"
import { ReactComponent as MailIcon } from "../assets/icons/Mail.svg"
import { ReactComponent as TiktokIcon } from "../assets/icons/tiktok.svg"
import { ReactComponent as QuestionIcon } from "../assets/icons/Questions.svg"
import { ReactComponent as PrivacyIcon } from "../assets/icons/Privacy.svg"
import { ReactComponent as DocIcon } from "../assets/icons/Doc.svg"
import { ReactComponent as YTIcon } from "../assets/icons/YT.svg"
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg"


const helpLinks: ButtonLink[] = [
    {
        href: "mailto:navid@craftyamigo.com",
        label: "Email Us",
        target: "_blank",
        icon: MailIcon,
    },
    {
        href: "mailto:parts@craftyamigo.com",
        label: "Missing a Part I Need!",
        target: "_blank",
        icon: AddIcon,
    },
    {
        href: "mailto:orders@craftyamigo.com",
        label: "Ordering Questions",
        target: "_blank",
        icon: QuestionIcon,
    },
    {
        href: "https://www.craftyamigo.com/privacy-policy",
        label: "Privacy Policy",
        target: "_blank",
        icon: PrivacyIcon,
    },
    {
        href: "https://www.craftyamigo.com/terms-of-use",
        label: "Terms of Use",
        target: "_blank",
        separator: true,
        icon: DocIcon,
    },
    {
        href: "https://www.tiktok.com/@yourcraftyamigo",
        label: "Tiktok",
        target: "_blank",
        icon: TiktokIcon,
    },
    {
        href: "https://www.youtube.com/@craftyamigo",
        label: "Youtube",
        target: "_blank",
        icon: YTIcon,
    },
    {
        href: "https://twitter.com/yourcraftyamigo",
        label: "Twitter",
        target: "_blank",
        icon: TwitterIcon,
    },
]

export default helpLinks