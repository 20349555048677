import React, { createContext, useRef } from "react"
import {
    SegmentedTubeMarkers
} from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"

interface Props {
    children: React.ReactNode;
}

export type SlidePartType = {
    id: string,
    sides: { [side: string]: SegmentedTubeMarkers, },
    startLength: number,
    endLength: number,
    segmentLength: number,
}

type SlideContextType = {
    registerSegmentedTube: (part: SlidePartType) => void,
    removeSegmentedTube: (partId: string) => void,
    updateSlider: (id: string, sides: { [side: string]: SegmentedTubeMarkers, }) => void,
    getSlidePart: (id: string) => SlidePartType | undefined,
} | undefined

export const SlideContext = createContext<SlideContextType>(undefined)

const SlideProvider: React.FC<Props> = ({ children, }) => {
    const segmentedTubesList = useRef<SlidePartType[]>([])

    const getSlidePart = (id: string) => {

        return segmentedTubesList.current.find(st => st.id === id)
    }

    const registerSegmentedTube = (part: SlidePartType) => {
        segmentedTubesList.current.push(part)
    }

    const removeSegmentedTube = (partId: string) => {
        const index = segmentedTubesList.current.findIndex(st => st.id === partId)
        segmentedTubesList.current.splice(index, 1)
    }

    const updateSlider = (id: string, sides: { [side: string]: SegmentedTubeMarkers, }) => {
        const segmentedTube = getSlidePart(id)
        segmentedTube!.sides = sides
    }

    const init = () => {
        return {
            registerSegmentedTube,
            removeSegmentedTube,
            updateSlider,
            getSlidePart,
        }
    }

    return <SlideContext.Provider value={init()} children={children} />
}

export default SlideProvider