/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-len */
import React, { useMemo, useRef } from "react"
import Stage from "./stage/Stage"
import SceneCanvas from "./PaperCanvas"
import { useRecoilValue } from "recoil"
import { Stats } from "@react-three/drei"
import InstancedMeshProvider from "../../../../providers/instancedMesh/InstancedMeshProvider"
import ConnectionsHandler from "../ConnectionModal/ConnectionsHandler"
import CollisionsProvider from "../../../../providers/collisionProvider/CollisionsProvider"
// import ModelTest from "../ModelTest"
import SelectorProvider from "../../../../providers/mouseProvider/SelectorProvider"
import { v4 as uuidv4 } from "uuid"
import { addPartModal, renderConnectionsData } from "../../../../state/atoms"
import { useDeletePart } from "../../../../state/scene/setters"
import { partsIdsSelector } from "../../../../state/scene/atoms"
import Part from "./part/Part"
import { PartTypeEnum } from "../../../../utils/Types"
import ModelTest from "../debug/ModelTest"
import useGetDebugVariables from "../utils/useGetDebugVariables"
import Footer from "./Footer"
import { CameraControls } from "../../../../providers/cameraProvider/CameraControls"
import CameraProvider from "../../../../providers/cameraProvider/CameraProvider"
import SaveHandler from "./SaveHandler"
import { useParams } from "react-router-dom"
import { useRouterContexts } from "../../../../../common/utils/RouterBridge"
import ModalProvider from "../../../../../common/providers/modalProvider/modalProvider"
import CloseingTabLogic from "./ClosingTab"
import MultipleMovementProvider from "../../../../providers/multipleMovementProvider/MultipleMovementProvider"
import GLTFExportManager from "./GLTFExportManager"

import GLTFLoaderProvider from "../../../../providers/GLTFLoaderProvider/GLTFLoaderProvider"
import InstancedMeshSegmentedTubesProvider from "../../../../providers/instancedMeshSegmentedTubesProvider/InstancedMeshSegmentedTubesProvider"
import SlideProvider from "../../../../providers/slideProvider/SlideProvider"
import CloseMarkersProvider from "../../../../providers/closeMarkersProvider/CloseMarkersProvider"
import MultiSelectProvider from "../../../../providers/multiselectProvider/MultiSelectProvider"
import { useLevaControls } from "../../../../providers/debugProvider/useLevaControls"


const Scene = () => {
    const partIdsList = useRecoilValue(partsIdsSelector)
    const renderConnections = useRecoilValue(renderConnectionsData)
    const partModal = useRecoilValue(addPartModal)
    const deletePart = useDeletePart()
    const { getVariables, } = useGetDebugVariables()
    const cameraControls = useRef<CameraControls | null>(null)
    const { userId, designId, } = useParams()
    const { showStats, } = useLevaControls()

    const routerContext = useRouterContexts()

    const handleDeletePart = (id: string) => {
        deletePart(id)
    }

    const getParts = () => {
        return partIdsList.map(part => {
            return <Part
                id={part.id}
                type={part.type}
                handleDeletePart={handleDeletePart}
                key={part.id}
            />
        })
    }
    // Funcion to test performance
    const stressarray = useMemo(() => {
        const count = getVariables().partCount
            && Number(getVariables().partCount)
            && Number(getVariables().partCount) < 200
            ? Number(getVariables().partCount) : 200

        const array = []

        for (let i = 0; i < count; i++) {
            array.push({
                id: uuidv4(),
                type: PartTypeEnum.connector,
            })
        }

        return array
    }, [])


    const stressTest = () => {
        return stressarray.map((s) => {
            return <ModelTest key={s.id} id={s.id} />
        })
    }

    return (
        <React.Fragment>
            <SceneCanvas />
            <Stage>
                <CloseMarkersProvider>
                    <MultipleMovementProvider>
                        <SlideProvider>
                            <CollisionsProvider
                                debug={
                                    !!(getVariables().colliderDisplay
                                        && (getVariables().colliderDisplay === "TRUE"))
                                }>
                                <InstancedMeshProvider>
                                    <ModalProvider>
                                        <MultiSelectProvider cameraControls={cameraControls} designId={designId}>
                                            <CameraProvider cameraControls={cameraControls} partCount={partIdsList.length} designId={designId}>
                                                <CameraControls ref={cameraControls} />
                                                <GLTFLoaderProvider>
                                                    <InstancedMeshSegmentedTubesProvider>
                                                        <SelectorProvider>
                                                            {
                                                                getVariables().partCount
                                                                && Number(getVariables().partCount) > 0 && stressTest()
                                                            }
                                                            {getParts()}
                                                            <GLTFExportManager />
                                                        </SelectorProvider>
                                                        <>
                                                            {renderConnections && <ConnectionsHandler
                                                                partToAdd={renderConnections.partToAdd!}
                                                                compatibleConnectionsIds={renderConnections.compatibleConnectionsIds}
                                                                sizeId={renderConnections.sizeId}
                                                                menuRef={renderConnections.menuRef}
                                                                ignoreSizeCompatibility={renderConnections.ignoreSizeCompatibility}
                                                                connectionData={{
                                                                    posAndRot: renderConnections.connectionData.posAndRot,
                                                                    partId: renderConnections.connectionData.partId,
                                                                    markerName: renderConnections.connectionData.markerName,
                                                                    length: partModal?.step1.source?.connectionLength,
                                                                    swap: renderConnections.connectionData.swap,
                                                                    type: renderConnections.connectionData.type,
                                                                    canSlide: renderConnections.connectionData.canSlide,
                                                                }}
                                                            />}
                                                        </>
                                                    </InstancedMeshSegmentedTubesProvider>
                                                </GLTFLoaderProvider>
                                            </CameraProvider>
                                        </MultiSelectProvider>
                                    </ModalProvider>
                                </InstancedMeshProvider>
                            </CollisionsProvider>
                        </SlideProvider>
                    </MultipleMovementProvider>
                </CloseMarkersProvider>
                <CloseingTabLogic />
                <SaveHandler router={routerContext} userId={userId} designId={designId} />
            </Stage>
            {/* FPS / MS / MB */}
            {
                showStats
                && <>
                    <Stats className="stats" />
                </>
            }
            <Footer cameraControls={cameraControls} />
        </React.Fragment >
    )
}

export default Scene