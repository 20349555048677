import React, { useMemo } from "react"
import { DrawPointsProps } from "./types"
import * as THREE from "three"

function DrawPoints({ points, ignoreDepth = false, }: DrawPointsProps) {
    const geometry = useMemo(() => {
        const positions = new Float32Array(points.length * 3)
        points.forEach((point, i) => {
            positions[i * 3] = point.position.x
            positions[i * 3 + 1] = point.position.y
            positions[i * 3 + 2] = point.position.z
        })
        const geometry = new THREE.BufferGeometry()
        geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3))
        return geometry
    }, [points,])

    const material = useMemo(() => {
        return new THREE.PointsMaterial({
            size: 5,
            sizeAttenuation: false,
            depthTest: !ignoreDepth,
        })
    }, [ignoreDepth,])

    return <points geometry={geometry} material={material} />
}

export default DrawPoints