/* eslint-disable no-negated-condition */
import React, { ReactNode, Suspense } from "react"
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from "recoil"
import DrawCalls from "./DrawCalls"
import { Canvas } from "@react-three/fiber"
import styled from "styled-components"
import Loading from "../../../../../../common/components/Loading"
import { LinearToneMapping } from "three"
import { useLevaControls } from "../../../../../providers/debugProvider/useLevaControls"

interface Props {
    children?: ReactNode;
}

// El numero por el cual dividimos un metro para que cada cuadrado pequeño mida 1 inch
// const GRID_SIZE = 100
// const GRID_DIVISION = 39.37 * GRID_SIZE

const Stage: React.FC<Props> = (props) => {
    // const renderConnections = useRecoilValue(renderConnectionsData)
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
    const { showGlobalAxes, } = useLevaControls()
    return (
        <Suspense fallback={<LoadingScreen><Loading /> Loading Part</LoadingScreen>}>
            <Canvas
                camera={{
                    position: [1, 1, 1,],
                    fov: 10,
                    far: 200,
                    // far: 1000,
                    // near: 0.4,
                }}
                gl={{
                    toneMapping: LinearToneMapping,
                }}
                legacy={true}
                dpr={window.devicePixelRatio}
            >
                {showGlobalAxes && <axesHelper args={[5,]} />}
                <RecoilBridge>
                    <DrawCalls />
                    {/* Turn off grid for now */}
                    {/* <fog attach="fog" color="#ffffff" near={1} far={40} /> */}
                    {/* {!renderConnections
                        && <gridHelper
                            position={[0, 0, 0,]}
                            args={[GRID_SIZE, GRID_DIVISION, "#848484", "#d0d0d0",]} />
                    } */}
                    {props.children}
                </RecoilBridge>
            </Canvas>
        </Suspense>
    )
}

const LoadingScreen = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export default Stage