import { PartTypeAPI } from "../../../../../common/api/Types"
import { ModalType } from "../../../../../common/providers/modalProvider/modalProvider"
import React from "react"
import AluminumOnboardingModal from
  "../../../../../common/components/Onboarding/AluminumOnboardingContent"
import WoodOnboardingModal from "../../../../../common/components/Onboarding/WoodOnboardingContent"
import useModal from "../../../../../common/providers/modalProvider/useModal"
import { materialsSelector } from "../../../../state/initialDataSelectors"
import { useRecoilValue } from "recoil"
import { getAluminumOnboarding, setAluminumOnboarding, getWoodOnboarding, setWoodOnboarding, }
  from "../../../../../common/utils/LocalPersistence"

export const useOnboardingModal = () => {
  const { showModal, onConfirm, } = useModal()
  const materials = useRecoilValue(materialsSelector)

  const showAluminumOnboarding = () => {
    showOnboarding(<AluminumOnboardingModal onConfirm={onConfirm} />)
  }

  const showWoodOnboarding = () => {
    showOnboarding(<WoodOnboardingModal onConfirm={onConfirm} />)
  }

  const showOnboarding = (content: JSX.Element) => {
    showModal({
      title: "",
      subtitle: "",
      onConfirm: () => { },
      type: ModalType.content,
      content: content,
      animate: true,
    })
  }

  const shouldShowAluminumOnboarding = (part: PartTypeAPI): boolean => {
    const shouldShow = shouldShowOnboarding(part, getAluminumOnboarding, "Aluminum")
    return !!shouldShow
  }

  const shouldShowWoodOnboarding = (part: PartTypeAPI): boolean => {
    const shouldShow = shouldShowOnboarding(part, getWoodOnboarding, "Wood")
    return !!shouldShow
  }

  const shouldShowOnboarding = (
    part: PartTypeAPI,
    getOnboarding: Function,
    materialName: string
  ): boolean => {
    const partMaterial = materials.find(
      material => material.id === part.materialId
    )
    const hasSeenOnboarding = getOnboarding()
    const isMaterial = partMaterial?.materialName === materialName
    const urlParams = new URLSearchParams(window.location.search)
    const forceOnboardingParam = urlParams.get("forceOnboarding")
    return isMaterial && (!!forceOnboardingParam || !hasSeenOnboarding)
  }


  const reportPart = (part: PartTypeAPI) => {
    if (shouldShowAluminumOnboarding(part)) {
      showAluminumOnboarding()
      setAluminumOnboarding(true)
    } else if (shouldShowWoodOnboarding(part)) {
      showWoodOnboarding()
      setWoodOnboarding(true)
    }
  }

  return { reportPart, }
}
