import React, { MutableRefObject, useEffect } from "react"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { PartTypeAPI, SegmentedTubeSectionType } from "../../../../../common/api/Types"
import { addPartModal, renderConnectionsData } from "../../../../state/atoms"
import { connectionTypesSelector } from "../../../../state/initialDataSelectors"
import { useNewPart, useSwapPart } from "../../../../state/scene/setters"
import { metersToInch } from "../utils/utilsThree"
import {
    IsExpandAplicableInfo,
    showAlertAndExecuteExpandReduce,
} from "../scene/part/parts/tube/utils/ReduceExpandToFit"
import RenderConnections from "./RenderConnections"
import { isItATube } from "../../../../state/scene/selectors"
import { EnvHelper } from "../../../../../common/utils/EnvHelper"
import { PosAndRotType, RenderSwapType } from "../../../../state/types"
import { PartTypeEnum } from "../../../../utils/Types"
import useModal from "../../../../../common/providers/modalProvider/useModal"
import { areSameConnection } from "../scene/part/parts/connector/utils/ConnectorUtils"
import { SoundHelper } from "../utils/SoundHelper"
import { roundLength } from "../../../../utils/utils"
import { getPlaceholderIdWithoutPosition } from "../../../../utils/MarkerUtil"
import { breadcrumb } from "../../../../../common/utils/sentrySetup"

type Props = {
    partToAdd: PartTypeAPI,
    compatibleConnectionsIds: string[],
    ignoreSizeCompatibility?: boolean,
    sizeId: string,
    menuRef: MutableRefObject<HTMLDivElement | null>,
    connectionData: {
        posAndRot: PosAndRotType,
        partId?: string,
        markerName?: string,
        length?: number,
        swap?: RenderSwapType,
        type?: PartTypeEnum,
        canSlide?: boolean,
    },
}

// eslint-disable-next-line max-lines-per-function
const ConnectionsHandler = (props: Props) => {

    const { showModal, } = useModal()

    const connectionTypes = useRecoilValue(connectionTypesSelector)

    //console.log("ignore size?",props.ignoreSizeCompatibility)

    let partsToRender = props.partToAdd.connections.filter(c =>
        props.compatibleConnectionsIds.includes(c.connectorId)
        && (props.ignoreSizeCompatibility || c.size.id === props.sizeId)
    )

    const partsAsNumbers
        = partsToRender.map(p => parseInt(p.placeholderId.charAt(p.placeholderId.length - 1), 10))

    partsToRender = partsToRender.filter(p => {
        const sameConfigurationNumbers
            = p.sameConfigurationAs.filter(
                n => partsAsNumbers.some(p => n === p)
            )
        const placeholderNumber = parseInt(p.placeholderId.charAt(p.placeholderId.length - 1), 10)
        if (p.position
            && (p.position === SegmentedTubeSectionType.END
                || p.position === SegmentedTubeSectionType.START)
            && sameConfigurationNumbers.length > 0
        ) {
            if (sameConfigurationNumbers.some(
                num => {
                    const sameConfigurationPart
                        = partsToRender.find(part => part.placeholderId.includes(num.toString()))
                    return sameConfigurationPart?.position === SegmentedTubeSectionType.END
                        || sameConfigurationPart?.position === SegmentedTubeSectionType.START
                }
            )) {
                return p.position === SegmentedTubeSectionType.START
            }
        }
        return sameConfigurationNumbers.every(n => n > placeholderNumber)
    }).map(p => {
        if (p.position === SegmentedTubeSectionType.MIDDLE) {
            return {
                ...p,
                placeholderId: `${p.placeholderId}_${Math.ceil(
                    props.partToAdd.defaultLength / 2 - 1)}`,
            }
        }
        return p
    })

    const createPart = useNewPart()
    const swapPart = useSwapPart()
    const resetConnectionModal = useResetRecoilState(renderConnectionsData)
    const resetPartModal = useResetRecoilState(addPartModal)
    const addPartState = useRecoilValue(addPartModal)

    const setPartModal = useSetRecoilState(addPartModal)

    const isOriginATube = useRecoilValue(
        isItATube({
            id: addPartState?.step1.source?.expandReduceToFitInfo.isApplicable
                ? addPartState?.step1.source?.expandReduceToFitInfo.originConnection.partId : "",
        })
    )
    const isOppositeATube = useRecoilValue(
        isItATube({
            id: addPartState?.step1.source?.expandReduceToFitInfo.isApplicable
                ? addPartState?.step1.source?.expandReduceToFitInfo.oppositeConnection.partId : "",
        })
    )

    const clearModal = () => {
        setPartModal(null)
        resetConnectionModal()
    }

    const handleSwap = (placeholderId: string, swap: RenderSwapType) => {
        swap.makeVisible()
        swapPart(
            props.partToAdd,
            placeholderId,
            props.connectionData,
            undefined,
            props.connectionData.length,
        )
        resetConnectionModal()
        resetPartModal()
    }

    const isSelectedOptionApplicableWithExpand = (placeholderId: string) => {
        if (addPartState && addPartState.step1.source
            && addPartState.step1.source.expandReduceToFitInfo.isApplicable
            && (addPartState.step1.source.expandReduceToFitInfo.someTube
                || props.partToAdd.tube)
        ) {
            const oppositePlaceholderId = props.partToAdd.connections.find(
                con => con.placeholderId === placeholderId
            )?.oppositeOf
            const oppositeConnection = props.partToAdd.connections.find(
                con => con.placeholderId.includes(oppositePlaceholderId!)
            )
            const oppositeConnectionType = connectionTypes[
                addPartState.step1.source.expandReduceToFitInfo.oppositeConnection.typeId
            ]
            return oppositeConnectionType.compatibleWithMulti.includes(
                oppositeConnection?.connectorId!
            )
        } else {
            return false
        }
    }

    const maxDistanceExpandReduce = EnvHelper.maxDistanceExpandReduceInInches

    const getCommonArgs = () => {
        const connection = props.connectionData.partId ? {
            partId: props.connectionData.partId,
            markerName: props.connectionData.markerName!,
            connectionLength: props.connectionData.length!,
        } : undefined

        return {
            part: props.partToAdd,
            connectedPart: connection,
        }
    }

    const getExpandTubeLength = (placeholderId: string) => {
        const expandReduceToFitInfo = addPartState?.step1?.source?.expandReduceToFitInfo
        const { fullLeg, iELength, oppositeOf, } = props.partToAdd.connections.find(
            con => areSameConnection(con.placeholderId, placeholderId))!
        const oppositePlaceholder = props.partToAdd.connections.find(
            con => con.placeholderId.includes(oppositeOf!))
        if (expandReduceToFitInfo?.isApplicable && oppositePlaceholder) {
            const distanceToExpandReduce
                = roundLength(
                    (expandReduceToFitInfo.distance * metersToInch
                        - (fullLeg - (iELength + oppositePlaceholder.iELength)))
                )

            if (Math.abs(distanceToExpandReduce)
                <= maxDistanceExpandReduce
            ) {
                return distanceToExpandReduce
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    }

    const getExpandableCreatePartsProps = (
        placeholderId: string,
        commonArgs: {
            part: PartTypeAPI,
            connectedPart: {
                partId: string,
                markerName: string,
                connectionLength: number,
            } | undefined,
        },
        expandReduceToFitInfo: Extract<IsExpandAplicableInfo, { isApplicable: true, }>,
        lengthToExpandReduce: number,
    ) => {
        const oppositeConnectedPartValues = {
            partId: expandReduceToFitInfo.oppositeConnection.partId,
            markerName: expandReduceToFitInfo.oppositeConnection.markerName,
        }
        const oppositePlaceholderId = props.partToAdd.connections.find(
            con => con.placeholderId === getPlaceholderIdWithoutPosition(placeholderId)
        )?.oppositeOf

        const oppositePart = {
            oppositeMarkerName: `inner${oppositePlaceholderId}`,
            oppositeConnectedPart: oppositeConnectedPartValues,
        }

        if (isOppositeATube) {
            return {
                ...commonArgs,
                posAndRot: props.connectionData.posAndRot,
                placeholderId: placeholderId,
                oppositePart,
                expandReduce: {
                    partId: expandReduceToFitInfo.oppositeConnection.partId,
                    length: lengthToExpandReduce,
                },
            }
        } else if (isOriginATube) {
            const oppositeConnection = props.partToAdd.connections.find(
                con => con.placeholderId.includes(oppositePlaceholderId!)
            )
            oppositePart.oppositeMarkerName = placeholderId
            oppositePart.oppositeConnectedPart.partId
                = expandReduceToFitInfo.originConnection.partId
            oppositePart.oppositeConnectedPart.markerName
                = expandReduceToFitInfo.originConnection.markerName
            commonArgs.connectedPart!.partId = expandReduceToFitInfo.oppositeConnection.partId
            commonArgs.connectedPart!.markerName
                = expandReduceToFitInfo.oppositeConnection.markerName
            return {
                ...commonArgs,
                posAndRot: {
                    inner: {
                        pos: expandReduceToFitInfo.oppositeConnection.posAndRot.inner.pos,
                        rot: expandReduceToFitInfo.oppositeConnection.posAndRot.inner.rot,
                    },
                    outer: {
                        pos: expandReduceToFitInfo.oppositeConnection.posAndRot.outer.pos,
                        rot: expandReduceToFitInfo.oppositeConnection.posAndRot.outer.rot,
                    },
                }
                ,
                placeholderId: oppositeConnection?.placeholderId,
                oppositePart,
                expandReduce: {
                    partId: expandReduceToFitInfo.originConnection.partId,
                    length: lengthToExpandReduce,
                },
            }

        }
        return {
            ...commonArgs,
            posAndRot: props.connectionData.posAndRot,
            placeholderId: placeholderId,
        }
    }

    const handleNewPart = (placeholderId: string, degree?: number) => {
        breadcrumb({
            message: "Select how to connect part",
            level: "info",
            data: {
                id: placeholderId,
            },
        })
        const commonArgs = getCommonArgs()

        if (props.connectionData.swap) {
            handleSwap(getPlaceholderIdWithoutPosition(placeholderId), props.connectionData.swap)
        } else {
            const expandReduceToFitInfo = addPartState?.step1?.source?.expandReduceToFitInfo
            const distanceToExpandReduce
                = getExpandTubeLength(getPlaceholderIdWithoutPosition(placeholderId))

            if (distanceToExpandReduce
                && expandReduceToFitInfo?.isApplicable
                && isSelectedOptionApplicableWithExpand(
                    getPlaceholderIdWithoutPosition(placeholderId))
            ) {
                const createOrEditTube = (distanceToExpandReduce?: number) => {
                    createPart(getExpandableCreatePartsProps(
                        placeholderId,
                        commonArgs,
                        expandReduceToFitInfo,
                        distanceToExpandReduce!,
                    ))

                    breadcrumb({
                        message: "after create part to expand or reduce",
                        level: "info",
                    })
                    SoundHelper.playUnsnap()
                    resetConnectionModal()
                    resetPartModal()
                }

                const addPart = () => {
                    createPart({
                        ...commonArgs,
                        placeholderId: placeholderId,
                        posAndRot: props.connectionData.posAndRot,
                        userRotation: degree,
                    })
                    resetConnectionModal()
                    resetPartModal()
                }

                showAlertAndExecuteExpandReduce({
                    showModal,
                    createOrEditTube,
                    addPart,
                    distanceToExpandReduce: distanceToExpandReduce,
                    reduceExpandCase: distanceToExpandReduce < 0 ? "Reduce" : "Expand",
                    closeModal: clearModal,
                })
            } else {
                createPart({
                    ...commonArgs,
                    posAndRot: props.connectionData.posAndRot,
                    placeholderId: placeholderId,
                    userRotation: degree,
                })
                resetConnectionModal()
                resetPartModal()
            }
        }
    }

    useEffect(() => {
        if (partsToRender.length === 1) {
            let placeHolderId = partsToRender[0].placeholderId
            if (props.partToAdd.segmentedTube) {
                placeHolderId = partsToRender.find(
                    part => part.position === SegmentedTubeSectionType.MIDDLE)?.placeholderId
                    || partsToRender[0].placeholderId
                const connectionToPlaceFrom
                    = props.partToAdd.connections.find(
                        c => c.placeholderId === placeHolderId)
                if (connectionToPlaceFrom?.position === SegmentedTubeSectionType.MIDDLE) {
                    placeHolderId
                        = `${placeHolderId}_1`
                }
            }
            handleNewPart(placeHolderId)
        }
    }, [])

    return (
        <>{
            partsToRender.length === 1
                ? null
                : <RenderConnections
                    partToAdd={props.partToAdd!}
                    partsToRender={partsToRender}
                    menuRef={props.menuRef}
                    connectionPosition={props.connectionData.posAndRot.outer.pos}
                    connectionRotation={props.connectionData.posAndRot.outer.rot}
                    newPartHandler={handleNewPart}
                    makeVisible={props.connectionData.swap?.makeVisible}
                    connectionLength={props.connectionData.length!}
                    posAndRot={props.connectionData.posAndRot}
                    actualPartType={props.connectionData.type}
                    canSlide={props.connectionData.canSlide}
                />
        }</>
    )
}

export default ConnectionsHandler