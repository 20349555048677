import React from "react"
import AnimationData from "../../../animations/t-rail.json"
import GenericOnboardingContent from "./GenericOnboardingContent"

type Props = {
    onConfirm: () => void,
}

const AluminumOnboardingContent: React.FC<Props> = (props: Props) => {
    return (
        <GenericOnboardingContent
            onConfirm={props.onConfirm}
            animationData={AnimationData}
        />
    )
}

export default AluminumOnboardingContent