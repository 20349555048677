/* eslint-disable max-lines-per-function */
import React, { MutableRefObject, useEffect, useState } from "react"
import { Html } from "@react-three/drei"
import { TubeMarkerEnum, TubeValues } from "../../../../../../../../utils/Types"
import { TubeInternalsType, TUBE_UI } from "../types/types"
import InputSlider from "./LengthSlider"
import ReactDOM from "react-dom"
import { AnimatePresence } from "framer-motion"
import {
    ActionsContainer
} from "../../ActionPanelStyles"
import {
    useMultipleMovement
} from "../../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import {
    getOppositeTubeMarker,
    MAX_POSSIBLE_LENGTH_REDUCTION,
} from "../../../../../../../../utils/MarkerUtil"
import { checkMaxLength, convertFromMeterToInchFunction } from "../utils/TubeUtils"
import { MathUtils, Vector3 } from "three"
import { PartConnectionType, UnitType } from "../../../../../../../../state/scene/types"
import { useThree } from "@react-three/fiber"
import { useRecoilValue } from "recoil"
import { connectionTypesSelector } from "../../../../../../../../state/initialDataSelectors"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import useUnitConversion from "../../../../../utils/UnitUtils"
import ActionsUI from "./ActionsUI"
import hotkeys from "hotkeys-js"

interface Props {
    tubeUI: TUBE_UI;
    setTubeUI: (ui: TUBE_UI) => void;
    handleTubeLength: (
        e: number,
        handleMovement: (
            partsIds: string[],
            onDrag: (distance: number) => void,
            onSnap: (newConnection: PartConnectionType) => void,
            direction: Vector3,
        ) => void,
        partsToMove?: string[],
        ignoreGuidelines?: boolean,
    ) => void;
    getDrag: () => { drag: boolean, dragValue: number, };
    getSnap: () => { maxPossibleLength: number, isSnapped: boolean, hasCollied: boolean, };
    handleTubeState: (value: number, ignoreGuidelines?: boolean) => void;
    handleTubeDelete: () => void;
    handleTubeSwap: () => void;
    handleMouseDown: (e: number, partsToMove?: string[]) => void;
    tube: TubeValues;
    getTubeInternals: () => React.MutableRefObject<TubeInternalsType>;
    handleInvert: (newOriginMarker: string) => void;
    buildCollider: () => void;
    updateCollider: () => void;
    setMaxLengthAsCurrentLength: () => void;
    setInternals: (newTubeInternals: TubeInternalsType) => void;
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[], mode: "translate" | "rotate") => void;
    transformMode: string;
}

const action_variants = {
    "visible": {
        y: "0%",
    },
    "hidden": {
        y: "130%",
    },
}

const transition = {
    duration: 0.5,
    ease: [0.54, 0.01, 0.61, 1,],
}

const TubeUI = (props: Props) => {
    const { scene, } = useThree()
    const actions_child = document.getElementById("actions-container")!
    const {
        canEditLength,
        attachToMove,
        detachMarkers,
        handleLengthMovement,
        saveLengthChanges,
        checkMaxLength: checkMaxConnectedLength,
    } = useMultipleMovement(props.tube.id)
    const [lastValue, setLastValue,] = useState(props.tube.length)
    const [multipleMove, setMultipleMove,] = useState<{
        partsToMove: string[],
        markerToMove: TubeMarkerEnum,
        newConnections: PartConnectionType[],
    } | undefined>(undefined)
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const { unit, updateUnit, } = useUnitConversion()

    const removeModal = () => {
        props.setTubeUI(TUBE_UI.NONE)
    }

    const handleMultipleMovementEnd = (
        internalRefs: MutableRefObject<TubeInternalsType>
    ) => {
        if (multipleMove?.partsToMove) {
            const newConnections = multipleMove.newConnections
            if (internalRefs.current.mmSnapState.newConnection) {
                newConnections.push(internalRefs.current.mmSnapState.newConnection)
            }
            const newMovableMarkerName
                = props.getTubeInternals().current.movableMarkerMesh?.name === TubeMarkerEnum.BOTTOM
                    ? TubeMarkerEnum.BOTTOM
                    : TubeMarkerEnum.TOP

            const originMarkerName = getOppositeTubeMarker(newMovableMarkerName)

            const originMarker = originMarkerName === TubeMarkerEnum.BOTTOM
                ? internalRefs.current.markerBottomMesh!
                : internalRefs.current.markerTopMesh!

            originMarker!.rotateY(MathUtils.degToRad(-180))
            const pos = MeshUtils.copyWorldPosition(originMarker)
            const rot = MeshUtils.copyWorldQuaternion(originMarker)
            originMarker!.rotateY(MathUtils.degToRad(180))

            saveLengthChanges(
                {
                    length: internalRefs.current.dragState.drag
                        ? internalRefs.current.dragState.dragValue : lastValue,
                    originMarkerName,
                    pos,
                    rot,
                },
                multipleMove.partsToMove,
                newConnections,
            )
            setMultipleMove(undefined)
            props.buildCollider()
            props.updateCollider()
        }
    }

    const handleMaxConnectedLength = (partsToMove: string[]) => {
        const tubeInternalsRef = props.getTubeInternals()
        const direction = MeshUtils.copyWorldDirection(tubeInternalsRef.current.movableMarkerMesh!)
        const maxLength = checkMaxConnectedLength(partsToMove, direction)
        if (maxLength) {
            props.setInternals({
                ...tubeInternalsRef.current,
                mmSnapState: {
                    ...tubeInternalsRef.current.mmSnapState,
                    maxLengthSetted: true,
                    maxPossibleLength: convertFromMeterToInchFunction(maxLength) as number
                        + props.tube.length as number - MAX_POSSIBLE_LENGTH_REDUCTION,
                },
            })
        } else {
            props.setInternals({
                ...tubeInternalsRef.current,
                mmSnapState: {
                    ...tubeInternalsRef.current.mmSnapState,
                    maxLengthSetted: false,
                    maxPossibleLength: props.tube.maxLength,
                },
            })
        }
    }

    useEffect(() => {
        const internalRefs = props.getTubeInternals()
        if (props.tubeUI === TUBE_UI.SLIDER) {
            const canEdit = canEditLength()
            if (canEdit && canEdit.partsToMove && canEdit.partsToMove.length > 0) {
                handleMaxConnectedLength(canEdit.partsToMove)
                if (internalRefs.current.movableMarkerMesh?.name !== canEdit.markerToMove) {
                    props.handleInvert(getOppositeTubeMarker(canEdit.markerToMove))
                }
                setMultipleMove({
                    partsToMove: canEdit.partsToMove,
                    markerToMove: canEdit.markerToMove,
                    newConnections: [],
                })
            } else {
                checkMaxLength(
                    scene,
                    props.tube.length,
                    props.tube,
                    props.getTubeInternals(),
                    props.setInternals,
                    connectionTypes
                )
                if (multipleMove?.partsToMove) {
                    setMultipleMove(undefined)
                }
                if (!canEdit) {
                    //props.setMaxLengthAsCurrentLength()
                }
            }
        } else {
            handleMultipleMovementEnd(internalRefs)
        }
    }, [props.tubeUI,])

    useEffect(() => {
        hotkeys("delete,backspace", (event, handler) => {
          event.preventDefault()
          props.handleTubeDelete()
        })

        // Cleanup function
        return () => {
          hotkeys.unbind("delete,backspace")
        }
      }, [])

    const handleTubeLength = (value: number, ignoreGuidelines?: boolean) => {
        props.handleTubeLength(
            value,
            ((
                partsIds: string[],
                onDrag: (distance: number) => void,
                onSnap: (newConnection: PartConnectionType) => void,
                direction: Vector3,
            ) => {
                handleLengthMovement(
                    partsIds,
                    onDrag,
                    onSnap,
                    direction,
                    value - lastValue
                )
            }),
            multipleMove?.partsToMove,
            ignoreGuidelines,
        )
        setLastValue(value)
    }

    const handleMouseDown = (e: number) => {
        const internalRefs = props.getTubeInternals()
        if (multipleMove?.partsToMove) {
            attachToMove(multipleMove.partsToMove,
                multipleMove?.markerToMove === TubeMarkerEnum.BOTTOM
                    ? internalRefs.current.markerBottomMesh!
                    : internalRefs.current.markerTopMesh!)
        }
        props.handleMouseDown(e, multipleMove?.partsToMove)
    }

    const handleTubeState = (e: number, ignoreGuidelines?: boolean) => {
        props.handleTubeState(e, ignoreGuidelines)
        if (multipleMove?.partsToMove && multipleMove?.partsToMove.length > 0) {
            const internalRefs = props.getTubeInternals()
            const markerRef = multipleMove?.markerToMove === TubeMarkerEnum.BOTTOM
                ? internalRefs.current.markerBottomMesh!
                : internalRefs.current.markerTopMesh!

            const newConnections = detachMarkers(
                multipleMove.partsToMove,
                markerRef,
                true,
                true,
                true
            )
            setMultipleMove({ ...multipleMove, newConnections, })
        }
    }

    const handleSwap = () => {
        breadcrumb({
            message: "Click on swap",
            level: "info",
            data: {
                partId: props.tube.id,
                partName: props.tube.name,
            },
        })
        props.handleTubeSwap()
    }

    if (props.tubeUI === TUBE_UI.NONE) {
        return null
    }

    return (
        <Html wrapperClass={"neutralHTML"}>
            {
                ReactDOM.createPortal(
                    <AnimatePresence>
                        <ActionsContainer transition={transition}
                            variants={action_variants}
                            initial={"hidden"}
                            animate={"visible"}
                            exit={"hidden"}>
                            <ActionsUI
                                tubeUI={props.tubeUI}
                                setTubeUI={props.setTubeUI}
                                onRemove={props.handleTubeDelete}
                                onSwap={handleSwap}
                                onDuplicate={props.onDuplicate}
                                partId={props.tube.id}
                                setIdsAsHighlightedAndTurnOnControl
                                ={props.setIdsAsHighlightedAndTurnOnControl}
                                transformMode={props.transformMode}
                            />
                            <InputSlider
                                getTubeInternals={props.getTubeInternals}
                                onFinishEditing={removeModal}
                                userUnit={unit}
                                updateUnit={newUnit => updateUnit(newUnit as UnitType)}
                                setTubeLength={handleTubeLength}
                                getDragState={props.getDrag}
                                getSnapState={props.getSnap}
                                setTubeState={handleTubeState}
                                handleMouseDown={handleMouseDown}
                                tube={props.tube}
                            />
                        </ActionsContainer>
                    </AnimatePresence>,
                    actions_child
                )
            }
        </Html>
    )
}

export default TubeUI